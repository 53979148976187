import { Box, Typography, TextField, useTheme } from "@mui/material"
import { useContext, useMemo, useRef, useState } from "react"
import { UserContext } from "../../../contexts/user"
import { post } from "../../../utils/requests"
import { PrimaryButton } from "../Buttons"

export interface IReportDialogProps {
  abuser: string
  onSubmit: () => void
}

export const ReportDialog = (props: IReportDialogProps): JSX.Element => {
  const { context } = useContext(UserContext)
  const textField = useRef<HTMLInputElement>(null)
  const [isValid, setIsValid] = useState<boolean>(false)
  const theme = useTheme()

  const abuser = useMemo(() => props.abuser, [props.abuser])
  const currentProfileName = useMemo(
    () => context.profileName,
    [context.profileName]
  )

  const handleReportSubmit = (): void => {
    const data = {
      category: "chat",
      comment: textField.current?.value,
      reporter: currentProfileName,
      abuser: abuser,
    }
    post("/abuse/report/", data)
      .then(() => {
        props.onSubmit()
      })
      .catch((error) => {
        console.error("Error sending report", error)
      })
  }

  return (
    <Box sx={{ padding: "16px" }}>
      <Typography sx={{ color: theme.palette.primary.main }} variant="body1">
        Report {abuser}
      </Typography>

      <TextField
        inputRef={textField}
        inputProps={{ maxlength: 255 }}
        sx={{ margin: "16px 0" }}
        fullWidth={true}
        multiline
        rows={6}
        placeholder={`Let us know how ${abuser} is violating the Rules of Conduct`}
        onChange={() => {
          if (textField.current) {
            setIsValid(textField.current.value.length > 0)
          }
        }}
      />

      <PrimaryButton
        size="large"
        fullWidth={true}
        onClick={() => {
          handleReportSubmit()
        }}
        disabled={!isValid}
      >
        Submit Report
      </PrimaryButton>
    </Box>
  )
}
