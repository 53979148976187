import styled from "@emotion/styled"
import { Typography, Rating as MUIRating } from "@mui/material"
import { Colors } from "../../styles/Colors"
import { useTheme } from "@material-ui/core/styles"
import React from "react"
import { Star } from "@mui/icons-material"

export interface IRatingProps {
  rating: number
  numberOfReviews: number
  lightText?: boolean
  onClick?: () => void
  classes?: string[]
  precision?: boolean
}

export const Rating = ({
  rating,
  numberOfReviews,
  lightText = false,
  onClick,
  classes = [],
  precision,
}: IRatingProps): JSX.Element => {
  const theme = useTheme()
  return (
    <StyledRating onClick={onClick} className={classes.join(" ")}>
      {numberOfReviews !== 0 && !precision && (
        <Star
          sx={{
            color: "secondary.dark",
            fontSize: "18px",
          }}
        />
      )}
      {numberOfReviews !== 0 && precision && (
        <MUIRating
          name={"half-rating-read"}
          defaultValue={rating}
          precision={0.1}
          sx={{
            color: "secondary.dark",
            fontSize: "18px",
          }}
          readOnly
        />
      )}
      <Typography
        variant={"subtitle2"}
        color={lightText ? Colors.white : theme.palette.text.primary}
      >
        {numberOfReviews === 0
          ? "New Creator"
          : Math.round(rating * 10) / 10 + ` (${numberOfReviews})`}
      </Typography>
    </StyledRating>
  )
}

const StyledRating = styled.div<{ onClick?: () => void }>`
  display: flex;
  align-items: center;
  width: fit-content;

  cursor: ${(props) => (props.onClick !== undefined ? "pointer" : "default")};
`
