import React, { Dispatch } from "react"
import { ActionMap } from "../utils/types"
import { IErrorDisplay } from "../pages/promo/[uuid]"

type ErrorType = {
  errorMessages: IErrorDisplay[]
}

export const initialErrorState: ErrorType = {
  errorMessages: [],
}

export enum ErrorActionTypes {
  Enque = "ENQUE",
  Deque = "DEQUE",
}

type ErrorPayload = {
  [ErrorActionTypes.Enque]: IErrorDisplay
  [ErrorActionTypes.Deque]: undefined
}

export type ErrorActions =
  ActionMap<ErrorPayload>[keyof ActionMap<ErrorPayload>]

export function errorReducer(
  state = initialErrorState,
  action: ErrorActions
): ErrorType {
  let newState
  switch (action.type) {
    case ErrorActionTypes.Enque: {
      newState = {
        ...state,
        errorMessages: [...state.errorMessages, action.payload],
      }
      break
    }
    case ErrorActionTypes.Deque: {
      newState = {
        ...state,
        errorMessages: [...state.errorMessages.slice(1)],
      }
      break
    }
    default:
      newState = state
      break
  }
  return newState
}

export const ErrorContext = React.createContext<{
  errorContext: ErrorType
  errorDispatch: Dispatch<ErrorActions>
}>({
  errorContext: initialErrorState,
  errorDispatch: () => null,
})
