import styled from "@emotion/styled"
import { useMediaQuery, useTheme } from "@mui/material"
import { Theme } from "@mui/system"
import Image, { StaticImageData } from "next/image"
import { customLoader } from "../../utils/imageLoader"

interface IBlossmImageProps {
  src?: string | StaticImageData
  alt: string
  layout?: "fill" | "fixed" | "responsive"
  circular?: boolean
  width?: number
  height?: number
  unoptimized?: boolean
  priority?: boolean
  borderRadius?: number
  greyedOut?: boolean
  scaleDown?: boolean
}

export const BlossmImage = ({
  src,
  alt,
  layout = "fill",
  circular = false,
  width,
  height,
  unoptimized = true,
  priority = true,
  borderRadius,
  greyedOut = false,
  scaleDown = false,
}: IBlossmImageProps): JSX.Element => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  return (
    <>
      {src && src !== "" ? (
        <>
          <StyledBlossmImage
            isMobile={isMobile}
            theme={theme}
            rounded={circular}
            width={width}
            height={height}
            borderRadius={borderRadius}
            greyedOut={greyedOut}
          >
            <Image
              objectFit={scaleDown ? "scale-down" : "initial"}
              src={src}
              alt={alt}
              loader={customLoader}
              layout={layout}
              unoptimized={unoptimized}
              priority={priority}
            />
          </StyledBlossmImage>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

const StyledBlossmImage = styled.div<{
  isMobile: boolean
  theme: Theme
  rounded: boolean
  width?: number
  height?: number
  borderRadius?: number
  greyedOut: boolean
}>`
  opacity: ${(props) => (props.greyedOut ? 0.4 : 1)};
  overflow: hidden;
  border-radius: ${(props) =>
    props.rounded || props.borderRadius
      ? props.borderRadius
        ? props.borderRadius + "px"
        : "50%"
      : 0};
  position: relative;
  width: ${(props) => (props.width ? props.width + "px" : "100%")};
  height: ${(props) => (props.height ? props.height + "px" : "100%")};
`
