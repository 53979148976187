export const trackLoginEvent = (): void => {
  gtag("event", "login")
  fbq("trackCustom", "Login")
}

export const trackSignUpEvent = (): void => {
  gtag("event", "sign_up")
  fbq("track", "CompleteRegistration")
}

export const trackSearchEvent = (
  searchTerm: string,
  searchLocation: string
): void => {
  gtag("event", "search", {
    "search_term": searchTerm,
    "search_location": searchLocation,
  })
  fbq("track", "Search", {
    "search_string": searchTerm,
    "search_location": searchLocation,
  })
}

export const trackClickProfileEvent = (
  contentType: string,
  contentId: string,
  clickLocation: string
): void => {
  gtag("event", "select_content", {
    "content_type": contentType,
    "content_id": contentId,
    "click_location": clickLocation,
  })
  fbq("track", "ViewContent", {
    "content_type": contentType,
    "content_name": contentId,
    "click_location": clickLocation,
  })
}

export const trackOrderPromoEvent = (
  platform?: string,
  price?: number
): void => {
  const params = {
    "platform": platform,
    "price": price,
  }
  gtag("event", "order_promo", params)
  fbq("trackCustom", "order_promo", params)
}

export const trackInitiateIdVerifyEvent = (): void => {
  gtag("event", "initiate_id_verify")
  fbq("trackCustom", "InitiateIdVerify")
}
