import { Typography } from "@mui/material"
import styled from "@emotion/styled"
import { Colors } from "../../styles/Colors"
import { Avatar, IAvatarProps } from "../Atoms/Avatar"
import { Verified } from "../Atoms/Verified"
import React, { useContext, useMemo } from "react"
import { ChatActionTypes, ChatContext } from "../../contexts/chat"
import { StaffBadge } from "../Atoms/StaffBadge"

export interface IMessagePreviewProps {
  avatar: IAvatarProps
  username: string
  verified: boolean
  isCustomerSupport: boolean
  isUnread: boolean
  isIgnored: boolean // not used
  timestamp: string
  lastMessage: string
  lastMessageFromUser: string
  otherUserUuid: string // not used
  onClick?: () => void
  mediaCount: number
}

export const avatarWidth = "50px"

export const MessagePreview = (props: IMessagePreviewProps): JSX.Element => {
  const { dispatch: chatDispatch } = useContext(ChatContext)

  const avatar = useMemo(() => props.avatar, [props.avatar])
  const username = useMemo(() => props.username, [props.username])
  const isVerified = useMemo(() => props.verified, [props.verified])
  const isCustomerSupport = useMemo(
    () => props.isCustomerSupport,
    [props.isCustomerSupport]
  )
  const isUnread = useMemo(() => props.isUnread, [props.isUnread])

  const timestamp = useMemo(() => props.timestamp, [props.timestamp])
  const lastMessage = useMemo(() => props.lastMessage, [props.lastMessage])
  const lastMessageFromUser = useMemo(
    () => props.lastMessageFromUser,
    [props.lastMessageFromUser]
  )

  const mediaCount = useMemo(() => props.mediaCount, [props.mediaCount])

  return (
    <StyledMessagePreview
      onClick={() => {
        if (props.onClick !== undefined) {
          props.onClick()
        }
        chatDispatch({
          type: ChatActionTypes.ChatWithUser,
          payload: username,
        })
      }}
    >
      <div className="avatar-wrapper">
        <Avatar
          online={avatar.online}
          profileImage={avatar.profileImage}
          size={"Large"}
        />
      </div>

      <div className="header-text">
        <div className="upper">
          <Typography variant={"subtitle2"} noWrap>
            {username}
          </Typography>
          {isVerified && <Verified size="Small" sx={{ top: "3px" }} />}
          {isCustomerSupport && (
            <StaffBadge
              expand={true}
              sx={{
                top: "0px",
                height: "16px",
                marginTop: "auto",
                marginBottom: "auto",
              }}
            />
          )}
          {/* <Chip label={props.status} size="small" color="primary" className="status" /> */}
        </div>

        {/* <Typography variant={"caption"} noWrap className="promo-type">
          {props.promoType}
        </Typography> */}

        <div className="lower">
          <Typography
            variant={"body2"}
            noWrap
            className={`last-message unread-${isUnread}`}
          >
            {lastMessageFromUser !== username ? "You: " : ""}
            <i>
              {mediaCount
                ? `[${mediaCount} file${mediaCount > 1 ? "s" : ""}] `
                : ""}
            </i>
            {lastMessage}
          </Typography>
          <Typography variant={"subtitle2"} className="timestamp">
            {timestamp}
          </Typography>
        </div>
      </div>
    </StyledMessagePreview>
  )
}

const gap = "8px"
const StyledMessagePreview = styled.div`
  display: flex;
  gap: ${gap};
  background: ${Colors.white};
  padding: 8px 12px 20px 12px;
  align-items: center;
  cursor: pointer;

  .avatar-wrapper {
    width: fit-content;
    min-width: fit-content;
  }

  .header-text {
    display: flex;
    flex-direction: column;
    width: calc(100% - ${avatarWidth} - ${gap});

    .upper {
      display: flex;
      gap: 5px;
      position: relative;
      top: 4px;

      .status {
        color: ${Colors.white};
        margin-left: auto;
      }
    }

    .promo-type {
      color: ${Colors.grays.light};
    }

    .lower {
      display: flex;

      .last-message {
        color: ${Colors.shade.light};
        margin-right: 16px;

        &.unread-true {
          color: ${Colors.black};
          font-style: italic;
        }
      }

      .timestamp {
        color: ${Colors.black};
        margin-left: auto;
        position: relative;
        top: -2px;
        white-space: nowrap;
      }
    }
  }
`
