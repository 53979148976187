import * as React from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import MoreIcon from "@mui/icons-material/MoreVert"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import { styled as MUIStyled, useTheme } from "@mui/material/styles"
import {
  CircularProgress,
  Menu,
  MenuItem,
  Switch,
  Divider,
  useMediaQuery,
} from "@mui/material"
import { SelectProfileRadioButtons } from "../Organisms/SelectProfileRadioButtons"
import { IMessagePreviewProps, MessagePreview } from "./MessagePreview"
import { UserContext } from "../../contexts/user"
import { useContext, useEffect, useMemo, useState } from "react"
import { get, post } from "../../utils/requests"
import { ArgJSONMap } from "../../utils/argjsonmap"
import { parseTimestampForLocaleDateString } from "../../utils/parsers"
import { getWindowDimensions } from "../../utils/windowDimensions"
import { MessageActionTypes, MessageContext } from "../../contexts/websocket"
import { PopUp } from "../Atoms/PopUp"
import styled from "@emotion/styled"
import { WhiteTextButton } from "../Atoms/Buttons"
import { useRouter } from "next/router"

export interface IMessagesProps {
  headerOffset?: number
}

const headerheight = 72

const Header = MUIStyled(Box)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  padding: "16px 16px 8px 16px",
  width: "100%",
}))

const MessagePreviewsContainer = MUIStyled(Box)(() => ({
  width: "100%",
  height: "100%",
  overflowY: "auto",
}))

const parseMessagesResponse = (response: object[]): IMessagePreviewProps[] => {
  return response.map((msg) => {
    const data = ArgJSONMap.fromParsedJson(msg)
    const timestamp = data.getNumber("created_at")
    return {
      avatar: {
        profileImage: data.getString("other_user_avatar_thumbnail"),
        online: data.getBoolean("other_user_online"),
      },
      username: data.getString("other_user_profile_name"),
      verified: data.getBoolean("other_user_verified"),
      isUnread: data.getNumber("num_unread") > 0,
      timestamp: parseTimestampForLocaleDateString(
        data.getNumber("created_at"),
        new Date(timestamp * 1000),
        true
      ),
      lastMessage: data.getString("m"),
      lastMessageFromUser: data.getString("from_user"),
      otherUserUuid: data.getString("other_user_uuid"),
      isIgnored: !!data.getNumber("ignored"),
      mediaCount: data.getNumber("media_count") || 0,
      isCustomerSupport: data.getBoolean("customer_support"),
    }
  })
}

export const Messages = (props: IMessagesProps): JSX.Element => {
  const { context } = useContext(UserContext)
  const theme = useTheme()
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)
  const [messagePreviews, setMessagePreviews] = useState<
    IMessagePreviewProps[]
  >([])
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  const [viewIgnored, setViewIgnored] = useState<boolean>(false)
  const { messageContext, messageDispatch } = useContext(MessageContext)
  const router = useRouter()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const previews: IMessagePreviewProps[] = useMemo(
    () => messagePreviews,
    [messagePreviews]
  )

  const currentUuid = useMemo(() => context.uuid, [context.uuid])

  const currentProfileName = useMemo(
    () => context.profileName,
    [context.profileName]
  )
  const isReconnecting = useMemo(
    () => messageContext.reconnecting,
    [messageContext.reconnecting]
  )

  const ignoredMessageCount = useMemo(
    () =>
      previews.filter((x: IMessagePreviewProps) => x.isIgnored === true).length,
    [previews]
  )

  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setMenuAnchor(event.currentTarget)
  }

  const handleMenuClose = (): void => {
    setMenuAnchor(null)
  }

  const requestMessagePreviews = (): void => {
    get(`/get_pm_users/?profile_uuid=${currentUuid}`)
      .then((response) => {
        setMessagePreviews(parseMessagesResponse(response.data))
        const unreadMessageCount = response.data.reduce(
          (total: number, message: { num_unread: number }) =>
            total + message.num_unread,
          0
        )
        messageDispatch({
          type: MessageActionTypes.UpdateUnreadMessageCount,
          payload: {
            unreadMessageCount: unreadMessageCount,
          },
        })
      })
      .catch((error) => {
        console.warn("Failed to fetch messages", error)
      })
  }

  const markAllAsRead = (): void => {
    post("/update_all_messages_as_read/", { profile_id: currentUuid })
      .then(() => {
        requestMessagePreviews()
        handleMenuClose()
      })
      .catch((error) => {
        console.warn("Failed to mark all messages as read", error)
      })
  }

  const toggleIgnored = (): void => {
    setViewIgnored(!viewIgnored)
    setTimeout(handleMenuClose, 500)
  }

  useEffect(() => {
    requestMessagePreviews()
  }, [drawerOpen, messageContext.unreadMessageCount, currentUuid])

  return (
    <StyledMessages>
      <Header sx={{ top: props.headerOffset || 0 }} theme={theme}>
        <Typography
          variant="h6"
          noWrap
          sx={{
            flexGrow: 1,
            display: "inline",
            color: "inherit",
            cursor: "pointer",
          }}
          onClick={() => {
            setDrawerOpen(true)
          }}
        >
          @{currentProfileName}
          {drawerOpen ? (
            <ArrowDropUpIcon sx={{ position: "relative", top: "6px" }} />
          ) : (
            <ArrowDropDownIcon sx={{ position: "relative", top: "5px" }} />
          )}
        </Typography>

        <WhiteTextButton
          size="medium"
          color="inherit"
          sx={{ float: "right", position: "relative", top: "-8px" }}
          onClick={handleMenuClick}
        >
          <MoreIcon />
        </WhiteTextButton>
      </Header>

      {!isReconnecting && (
        <>
          {previews.length > 0 &&
          (ignoredMessageCount < previews.length ||
            (viewIgnored && ignoredMessageCount > 0)) ? (
            <MessagePreviewsContainer
              sx={{
                top: headerheight + (props.headerOffset || 0),
                bottom:
                  getWindowDimensions().width < theme.breakpoints.values.sm
                    ? 56
                    : 0,
              }}
            >
              {previews.map((messagePreview: IMessagePreviewProps) => {
                return viewIgnored === messagePreview.isIgnored ? (
                  <MessagePreview
                    {...messagePreview}
                    key={messagePreview.otherUserUuid}
                    onClick={async () => {
                      const postData = {
                        other_profile_id: messagePreview.otherUserUuid,
                        profile_id: currentUuid,
                      }
                      post("/update_messages_as_read/", postData)
                        .then((response) => {
                          const data = ArgJSONMap.fromParsedJson(response.data)
                          if (isMobile) {
                            router.push(`/messages/${messagePreview.username}`)
                          }
                          messageDispatch({
                            type: MessageActionTypes.UpdateUnreadMessageCount,
                            payload: {
                              unreadMessageCount:
                                data.getNumber("total_unread"),
                            },
                          })
                          requestMessagePreviews()
                        })
                        .catch((errors) => console.error(errors))
                    }}
                  />
                ) : (
                  <></>
                )
              })}
            </MessagePreviewsContainer>
          ) : (
            <Typography
              variant="subtitle2"
              sx={{
                color: theme.palette.text.secondary,
                padding: "100px 24px",
                position: "relative",
                textAlign: "center",
                top: headerheight + (props.headerOffset || 0),
              }}
            >
              {viewIgnored && ignoredMessageCount === 0 ? (
                <>There are no conversations with ignored users to display.</>
              ) : (
                <>
                  Message other Blossm users directly from their profile. All
                  conversations will appear here.
                </>
              )}
            </Typography>
          )}
        </>
      )}

      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={markAllAsRead}>Mark All as Read</MenuItem>
        <Divider />
        <MenuItem onClick={toggleIgnored}>
          View Ignored <Switch checked={viewIgnored} />
        </MenuItem>
      </Menu>

      <PopUp
        open={drawerOpen}
        handleToggle={() => {
          setDrawerOpen(false)
        }}
      >
        <SelectProfileRadioButtons setDrawerOpen={setDrawerOpen} />
      </PopUp>
      {isReconnecting && (
        <MessagePreviewsContainer
          sx={{
            top: 112 + (props.headerOffset || 0),
            bottom:
              getWindowDimensions().width < theme.breakpoints.values.sm
                ? 56
                : 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </MessagePreviewsContainer>
      )}
    </StyledMessages>
  )
}

const StyledMessages = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`
