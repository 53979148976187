import React, { ErrorInfo, ReactNode } from "react"
import { Button, Typography } from "@mui/material"
import { LoginGrid } from "../components/Organisms/LoginGrid"
import styled from "@emotion/styled"
import { NextRouter } from "next/dist/shared/lib/router/router"
import { logError, SubSystemType } from "../utils/debug"

interface IErrorBoundaryState {
  hasError: boolean
}

interface IErrorBoundaryProps {
  children: JSX.Element | JSX.Element[]
  router: NextRouter
}

class ErrorBoundary extends React.Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props: IErrorBoundaryProps) {
    super(props)

    // Define a state variable to track whether is an error or not
    this.state = {
      hasError: false,
    }

    if (sessionStorage.getItem("reloadCount") === null) {
      sessionStorage.setItem("reloadCount", Number(0).toString())
    }
  }
  static getDerivedStateFromError(): { hasError: boolean } {
    // Update state so the next render will show the fallback UI

    return { hasError: false }
  }
  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    const rawReloadCount = window.sessionStorage.getItem("reloadCount")
    const rawLastReload = window.sessionStorage.getItem("lastReload")
    if (rawReloadCount !== null) {
      let reloadCount = parseInt(rawReloadCount)
      if (rawLastReload !== null) {
        const lastReload = parseInt(rawLastReload)
        if (lastReload + 5000 < Date.now()) {
          window.sessionStorage.setItem("reloadCount", Number(0).toString())
          reloadCount = 0
        }
      }
      if (reloadCount === 0) {
        this.setState({ hasError: false })
        window.sessionStorage.setItem(
          "reloadCount",
          (reloadCount + 1).toString()
        )
        window.sessionStorage.setItem("lastReload", Date.now().toString())
        this.props.router.reload()
      } else {
        this.setState({ hasError: true })
      }
    } else {
      this.setState({ hasError: true })
      window.sessionStorage.setItem("reloadCount", Number(0).toString())
    }

    // log to NewRelic
    let errorMessage: string
    try {
      errorMessage = JSON.stringify(errorInfo)
    } catch (e) {
      errorMessage = `${error.stack}`
    }
    const message = [
      `"Message: " ${error.message}`,
      `"URL: "  ${this.props.router.route}`,
      `"Stack: " ${error.stack}`,
      `"Line: " ${1}`,
      `"Column: " ${1}`,
      `"Error object: " ${errorMessage}`,
    ].join("\n")
    logError(message, { line: 1, column: 1 }, SubSystemType.Default)
  }

  render(): ReactNode {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <LoginGrid>
          <StyledDiv>
            <Typography
              variant={"h5"}
              className={"error-boundary-header"}
              align={"center"}
            >
              Blossm needs to refresh...
            </Typography>
            <Button
              variant={"contained"}
              onClick={() => {
                this.setState({ hasError: false })
              }}
            >
              Refresh
            </Button>
          </StyledDiv>
        </LoginGrid>
      )
    }
    // Return children components in case of no error
    return this.props.children
  }
}

const StyledDiv = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items-center;
  justify-content: center;
  
  .error-boundary-header {
    margin-bottom: 40px;
  }
`

export default ErrorBoundary
