import styled from "@emotion/styled"
import {
  Divider,
  Drawer,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import {
  AddBoxOutlined,
  AlternateEmail,
  ArrowDropDown,
  AttachMoney,
  Check,
  ExploreOutlined,
  HelpOutline,
  Inventory2Outlined,
  Logout,
  MailOutlined,
  NotificationsOutlined,
  Person,
  RocketLaunchOutlined,
  SettingsOutlined,
  ShoppingCartOutlined,
  Star,
} from "@mui/icons-material"
import React, { useContext, useMemo, useState } from "react"
import Link from "next/link"
import Badge from "@mui/material/Badge"
import { Avatar } from "./Avatar"
import { ModalLogout } from "../Molecules/ModalLogout/ModalLogout"
import { ActionTypes, UserContext } from "../../contexts/user"
import { Colors } from "../../styles/Colors"
import { useRouter } from "next/router"
import {
  ActionTypes as SiteNotificationActionTypes,
  SiteNotificationsContext,
} from "../../contexts/siteNotificationContext"
import { logout } from "../../utils/requests"
import { ErrorDialog } from "../Organisms/ErrorDialog"
import DesktopNavarIcon from "../assets/BlossmLogoLarge.svg"
import instagram from "../assets/Icons/Black/Instagram.svg"
import twitter from "../assets/Icons/Black/Twitter.svg"
import tiktok from "../assets/Icons/Black/TikTok.svg"
import telegram from "../assets/Icons/Black/Telegram.svg"
import { ContactBox } from "../Organisms/DesktopNavbar"
import { SelectProfileRadioButtons } from "../Organisms/SelectProfileRadioButtons"
import { PopUp } from "./PopUp"
import { SiteActionRequiredContext } from "../../contexts/siteActionRequiredContext"
import { MessageContext } from "../../contexts/websocket"
import { BalanceContext } from "../../contexts/balance"
import {
  WafflesContext,
  ActionTypes as WaffleActionTypes,
  initialState as waffleInitialState,
} from "../../contexts/waffles"

export interface ITabletPopOutMenuProps {
  handleClose: () => void
}

export const TabletPopOutMenu = ({
  handleClose,
}: ITabletPopOutMenuProps): JSX.Element => {
  const [logoutOpen, setLogoutOpen] = useState(false)
  const [loggedIn, setLoggedIn] = useState<boolean>(true)
  const { context, dispatch } = useContext(UserContext)
  const { siteNotificationsContext, siteNotificationsDispatch } = useContext(
    SiteNotificationsContext
  )
  const theme = useTheme()
  const router = useRouter()
  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false)
  const [errorTitle, setErrorTitle] = useState<string>("")
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"))
  const [openProfileSelection, setOpenProfileSelection] = useState(false)
  const { siteActionRequiredContext } = useContext(SiteActionRequiredContext)
  const { messageContext } = useContext(MessageContext)
  const { wafflesContext, wafflesDispatch } = useContext(WafflesContext)
  const { balanceContext } = useContext(BalanceContext)

  const logOutModal = (): void => {
    logout()
      .then(() => {
        dispatch({
          type: ActionTypes.Logout,
          payload: {
            isAuthenticated: false,
          },
        })
        siteNotificationsDispatch({
          type: SiteNotificationActionTypes.ResetSiteNotification,
          payload: {
            siteNotification: null,
          },
        })
        wafflesDispatch({
          type: WaffleActionTypes.DropWaffles,
          payload: waffleInitialState,
        })
        setLogoutOpen(false)
        router.push("/")
      })
      .catch(() => {
        setLogoutOpen(false)
        setErrorDialogOpen(true)
        setErrorTitle("Failed to log out")
        setErrorMessages(["Failed to log out. Please try again."])
      })
  }

  useMemo(() => {
    if (context.username !== "") {
      setLoggedIn(true)
    } else {
      setLoggedIn(false)
    }
  }, [context.username])

  return (
    <StyledTabletPopOutMenu theme={theme}>
      <Drawer open={true} className={"drawer"} onClose={handleClose}>
        <Link href={"/"}>
          <img src={DesktopNavarIcon.src} className={"desktop-navbar-icon"} />
        </Link>
        <Link href={"/marketplace"}>
          <StyledNavItem theme={theme} selected={router.pathname === "/"}>
            <ExploreOutlined sx={{ color: "inherit" }} />
            <Typography color={"inherit"} variant={"body1"}>
              Marketplace
            </Typography>
          </StyledNavItem>
        </Link>
        {loggedIn ? (
          <>
            <Link href={"/notifications"}>
              <StyledNavItem
                theme={theme}
                selected={router.pathname === "/notifications"}
              >
                <Badge
                  badgeContent={
                    siteNotificationsContext.newSiteNotificationCount
                  }
                  color="primary"
                >
                  <NotificationsOutlined sx={{ color: "inherit" }} />
                </Badge>
                <Typography color={"inherit"} variant={"body1"}>
                  Notifications
                </Typography>
              </StyledNavItem>
            </Link>
            <Link href={"/orders"}>
              <StyledNavItem
                theme={theme}
                selected={router.pathname === "/orders"}
              >
                <Badge
                  badgeContent={
                    siteActionRequiredContext.actionRequiredNotifications
                      ? siteActionRequiredContext.actionRequiredNotifications
                      : 0
                  }
                  color="primary"
                >
                  <Inventory2Outlined sx={{ color: "inherit" }} />
                </Badge>
                <Typography color={"inherit"} variant={"body1"}>
                  My Orders
                </Typography>
              </StyledNavItem>
            </Link>
            {wafflesContext.type !== "" &&
              wafflesContext.flags.indexOf("show_traffic_coming_soon") > -1 && ( // Waffle Check
                <Link href={"/traffic"}>
                  <StyledNavItem
                    theme={theme}
                    selected={router.pathname === "/traffic"}
                  >
                    <RocketLaunchOutlined sx={{ color: "inherit" }} />
                    <Typography color={"inherit"} variant={"body1"}>
                      Buy Traffic
                    </Typography>
                  </StyledNavItem>
                </Link>
              )}
          </>
        ) : (
          <Link href={"/login"}>
            <StyledNavItem theme={theme}>
              <Person sx={{ color: "inherit" }} />
              <Typography color={"inherit"} variant={"body1"}>
                Log In
              </Typography>
            </StyledNavItem>
          </Link>
        )}
        <Divider sx={{ width: "100%" }} />
        {loggedIn && (
          <>
            <StyledNavItem
              theme={theme}
              selected={
                router.pathname === "/[name]" &&
                router.query.name === context.profileName
              }
            >
              <Link href={"/" + context.profileName}>
                <Avatar
                  online={false}
                  profileImage={context.avatarUrl}
                  size={"Small"}
                />
              </Link>
              <StyledSwitchProfileItem>
                <Link href={"/" + context.profileName}>
                  <Typography
                    noWrap
                    color={"inherit"}
                    variant={"body1"}
                    sx={{ width: "100%" }}
                  >
                    @{context.profileName}
                  </Typography>
                </Link>
                <ArrowDropDown
                  onClick={() => setOpenProfileSelection(true)}
                  sx={{ cursor: "pointer" }}
                />
              </StyledSwitchProfileItem>
            </StyledNavItem>
            {!context.userIdVerified && (
              <Link href={"/id-verification/verify?source=navclick"}>
                <StyledNavItem
                  theme={theme}
                  selected={router.pathname === "/id-verification/verify"}
                >
                  <StyledCheck theme={theme}>
                    <Check sx={{ color: Colors.white }} fontSize={"small"} />
                  </StyledCheck>
                  <Typography color={"inherit"} variant={"body1"}>
                    Verify ID
                  </Typography>
                  <div className={"verify-dot"} />
                </StyledNavItem>
              </Link>
            )}
            <Link href={"/create"}>
              <StyledNavItem
                theme={theme}
                selected={router.pathname === "/create"}
              >
                <AddBoxOutlined sx={{ color: "inherit" }} />
                <Typography color={"inherit"} variant={"body1"}>
                  Create
                </Typography>
              </StyledNavItem>
            </Link>
            <Link href={`/add-accounts?profileName=${context.profileName}`}>
              <StyledNavItem
                theme={theme}
                selected={router.pathname === "/add-accounts"}
              >
                <AlternateEmail sx={{ color: "inherit" }} />
                <Typography color={"inherit"} variant={"body1"}>
                  Socials
                </Typography>
              </StyledNavItem>
            </Link>
            <Link href={"/activity"}>
              <StyledNavItem
                theme={theme}
                selected={router.pathname === "/activity"}
              >
                <ShoppingCartOutlined sx={{ color: "inherit" }} />
                <Typography color={"inherit"} variant={"body1"}>
                  Activity
                </Typography>
              </StyledNavItem>
            </Link>
            <Link href={"/payout"}>
              <StyledNavItem
                theme={theme}
                selected={router.pathname === "/payout"}
              >
                <AttachMoney sx={{ color: "inherit" }} />
                <div>
                  <Typography color={"inherit"} variant={"body1"}>
                    Balance
                  </Typography>
                  <Typography color={"inherit"} variant={"body2"}>
                    $
                    {balanceContext.balance &&
                      balanceContext.balance >= 0 &&
                      balanceContext.balance.toFixed(2)}
                  </Typography>
                </div>
              </StyledNavItem>
            </Link>
            <Link href={"/settings/account"}>
              <StyledNavItem
                theme={theme}
                selected={router.pathname === "/settings/account"}
              >
                <SettingsOutlined sx={{ color: "inherit" }} />
                <Typography color={"inherit"} variant={"body1"}>
                  Settings
                </Typography>
              </StyledNavItem>
            </Link>
            {isMobile ||
              (isTablet && (
                <Link href={"/messages"}>
                  <StyledNavItem
                    theme={theme}
                    selected={router.pathname === "/messages"}
                  >
                    <Badge
                      badgeContent={messageContext.unreadMessageCount}
                      color="primary"
                    >
                      <MailOutlined sx={{ color: "inherit" }} />
                    </Badge>
                    <Typography color={"inherit"} variant={"body1"}>
                      Messages
                    </Typography>
                  </StyledNavItem>
                </Link>
              ))}
          </>
        )}
        <a target="_blank" href={"https://blossm.zendesk.com/hc/en-us/"}>
          <StyledNavItem theme={theme}>
            <HelpOutline sx={{ color: "inherit" }} />
            <Typography color={"inherit"} variant={"body1"}>
              Need Help?
            </Typography>
          </StyledNavItem>
        </a>
        {loggedIn && (
          <Link href={"/refer/" + context.profileName}>
            <StyledNavItem
              theme={theme}
              selected={router.pathname === "/refer/[name]"}
            >
              <Star sx={{ color: "inherit" }} />
              <Typography
                color={theme.palette.text.secondary}
                variant={"body1"}
              >
                Refer and Earn
              </Typography>
            </StyledNavItem>
          </Link>
        )}
        {loggedIn && (
          <StyledNavItem theme={theme} onClick={() => setLogoutOpen(true)}>
            <Logout sx={{ color: "inherit" }} />
            <Typography color={theme.palette.text.secondary} variant={"body1"}>
              Log Out
            </Typography>
          </StyledNavItem>
        )}
        <Divider sx={{ width: "100%" }} />
        <InfoLinks>
          <Link
            href={"/terms"}
            passHref
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography
              variant={"body2"}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              Terms of Service
            </Typography>
          </Link>
          <Link
            href={"/privacy"}
            passHref
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography
              variant={"body2"}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              Privacy
            </Typography>
          </Link>
          <Link
            href={"/guidelines"}
            passHref
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography
              variant={"body2"}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              Guidelines
            </Typography>
          </Link>
          {/*<Link href={"/billing"}>*/}
          {/*    <Typography variant={"body2"} sx={{cursor: "pointer", "&:hover": {*/}
          {/*            textDecoration: "underline",*/}
          {/*        }}}>Billing</Typography>*/}
          {/*</Link>*/}
          <a href={"https://blossm.zendesk.com/hc/en-us/"} target="_blank">
            <Typography
              variant={"body2"}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              Feedback
            </Typography>
          </a>
          <a href={"https://blossmblog.com/"} target="_blank">
            <Typography
              variant={"body2"}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              Blossm Blog
            </Typography>
          </a>
          <Link
            href={"/media-kit"}
            passHref
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography
              variant={"body2"}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              Media Kit
            </Typography>
          </Link>
          <ContactBox>
            <a href={"https://www.instagram.com/blossmhq/"} target="_blank">
              <img src={instagram.src} width={24} height={24} />
            </a>
            <a href={"https://twitter.com/blossmhq/"} target="_blank">
              <img src={twitter.src} width={24} height={24} />
            </a>
            <a href={"https://www.tiktok.com/@blossmhq"} target="_blank">
              <img src={tiktok.src} width={24} height={24} />
            </a>
            <a href={"https://t.me/blossmhq"} target="_blank">
              <img src={telegram.src} width={24} height={24} />
            </a>
          </ContactBox>
        </InfoLinks>
      </Drawer>
      <ModalLogout
        open={logoutOpen}
        title="Log Out?"
        text="Are you sure you want to log out?"
        onHandle={logOutModal}
        onClose={() => setLogoutOpen(false)}
      />
      <PopUp open={openProfileSelection} handleToggle={setOpenProfileSelection}>
        <SelectProfileRadioButtons setDrawerOpen={setOpenProfileSelection} />
      </PopUp>
      <ErrorDialog
        errorTitle={errorTitle}
        errorDialogOpen={errorDialogOpen}
        setErrorDialogOpen={setErrorDialogOpen}
      >
        {errorMessages.map((error: string) => {
          return <div>{error}</div>
        })}
      </ErrorDialog>
    </StyledTabletPopOutMenu>
  )
}

const InfoLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 16px;
`

const StyledTabletPopOutMenu = styled.span<{ theme: Theme }>`
  .desktop-navbar-icon {
    width: 152px;
    height: 36px;
    margin-bottom: 24px;
    padding-left: 8px;
    cursor: pointer;
  }

  .drawer {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 5px;
  }
`

const StyledCheck = styled.div<{ theme: Theme }>`
  display: flex;
  width: 24px;
  height: 24px;
  background: ${(props) => props.theme.palette.info.light};
  border-radius: 50%;
  align-items: center;
`

const StyledNavItem = styled.div<{ theme: Theme; selected?: boolean }>`
  display: flex;
  gap: 5px;
  background: ${(props) =>
    props.selected ? Colors.blues.shadyBlue : Colors.white};
  ${(props) =>
    props.selected &&
    `
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 2px;
    padding-right: 2px;
  `}
  color: ${(props) =>
    props.selected
      ? props.theme.palette.primary.main
      : props.theme.palette.text.secondary};
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  transition: padding 0.3s;
  padding: 16px;

  .verify-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${(props) => props.theme.palette.warning.light};
    margin-left: auto;
    margin-right: 16px;
  }
`
const StyledSwitchProfileItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  justify-content: space-between;
`
