import React, { Dispatch } from "react"
import { ActionMap } from "../utils/types"

export const initialState: FansMetricConfig = {
  client_id: "",
  isFansMetricConnected: false,
}

export type FansMetricConfig = {
  client_id: string
  isFansMetricConnected: boolean
}

export enum FansMetricConfigTypes {
  SetClientId = "Set Client ID",
  SetIsFansMetricConnected = "Set Is Fans Metric Connected",
}

type FansMetricConfigPayload = {
  [FansMetricConfigTypes.SetClientId]: {
    client_id: string
  }
  [FansMetricConfigTypes.SetIsFansMetricConnected]: {
    isFansMetricConnected: boolean
  }
}

export type FansMetricConfigActions =
  ActionMap<FansMetricConfigPayload>[keyof ActionMap<FansMetricConfigPayload>]

export function reducer(
  state: FansMetricConfig = initialState,
  action: FansMetricConfigActions
): FansMetricConfig {
  let newState
  switch (action.type) {
    case FansMetricConfigTypes.SetClientId:
      newState = {
        ...state,
        client_id: action.payload.client_id,
      }
      break
    case FansMetricConfigTypes.SetIsFansMetricConnected:
      newState = {
        ...state,
        isFansMetricConnected: action.payload.isFansMetricConnected,
      }
      break
    default:
      newState = {
        ...state,
      }
      break
  }
  return newState
}

export const FansMetricConfigContext = React.createContext<{
  fansMetricConfigContext: FansMetricConfig
  fansMetricConfigDispatch: Dispatch<FansMetricConfigActions>
}>({
  fansMetricConfigContext: initialState,
  fansMetricConfigDispatch: () => null,
})
