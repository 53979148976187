import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogActions from "@mui/material/DialogActions"
import Dialog from "@mui/material/Dialog"
import { Dispatch, SetStateAction } from "react"
import { ErrorTextButton, PrimaryButton } from "../Atoms/Buttons"
import { ErrorOutlineOutlined } from "@mui/icons-material"
import { theme } from "../../../styles/Theme"
import styled from "@emotion/styled"
import { Typography } from "@mui/material"

export interface IErrorDialogProps {
  errorTitle?: string
  subHeader?: string
  children?: JSX.Element[] | JSX.Element
  errorDialogOpen: boolean
  setErrorDialogOpen: Dispatch<SetStateAction<boolean>>
  affirmativeButtonText?: string
  affirmativeOnClick?: () => void
  negativeOnClick?: () => void
  negativeButtonText?: string
  onClose?: () => void
}

/**
 * Use this component to display error messages from the backend (returned 400-599 error codes caught in the .catch()
 * of request Promises.
 * @constructor
 */
export const ErrorDialog = ({
  children,
  errorDialogOpen,
  setErrorDialogOpen,
  errorTitle,
  affirmativeOnClick,
  negativeOnClick,
  negativeButtonText,
  affirmativeButtonText = "Ok",
  onClose,
  subHeader,
}: IErrorDialogProps): JSX.Element => {
  const handleErrorDialogClose = (): void => {
    setErrorDialogOpen(false)
    if (onClose) {
      onClose()
    }
  }

  return (
    <Dialog
      open={errorDialogOpen}
      onClose={handleErrorDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <StyledModalCustom>
        <DialogTitle id="alert-dialog-title">
          <StyledTitleDialog>
            <ErrorOutlineOutlined sx={{ color: theme.palette.error.main }} />
            {errorTitle}
          </StyledTitleDialog>
        </DialogTitle>

        <DialogContent>
          <StyledSubHeader>
            <Typography variant={"body1"} color={theme.palette.text.primary}>
              <strong>{subHeader}</strong>
            </Typography>
          </StyledSubHeader>
          <DialogContentText id="alert-dialog-description">
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {negativeButtonText && (
            <ErrorTextButton
              onClick={() => {
                if (negativeOnClick !== undefined) {
                  negativeOnClick()
                }
                handleErrorDialogClose()
              }}
            >
              {negativeButtonText}
            </ErrorTextButton>
          )}
          <PrimaryButton
            style={{ width: "100%" }}
            onClick={() => {
              if (affirmativeOnClick !== undefined) {
                affirmativeOnClick()
              }
              handleErrorDialogClose()
            }}
          >
            {affirmativeButtonText}
          </PrimaryButton>
        </DialogActions>
      </StyledModalCustom>
    </Dialog>
  )
}

const StyledModalCustom = styled.div`
  max-width: 400px;
`

const StyledTitleDialog = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`
const StyledSubHeader = styled.div`
  margin-bottom: 16px;
`
