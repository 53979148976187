export function GetCookie(key: string): string {
  const cookieElement = document.cookie
    .split("; ")
    .find((crumb) => crumb.trim().startsWith(key))
  if (!cookieElement) {
    return ""
  }
  return cookieElement.split("=")[1]
}

export function SetCookie(
  name: string,
  value: string,
  days: number,
  path = "/"
): void {
  const date = new Date()
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
  document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=${path}`
}
