import styled from "@emotion/styled"
import { ArrowBack, Photo, RemoveCircleOutlined } from "@mui/icons-material"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import {
  Alert,
  Divider,
  FormLabel,
  ImageList,
  ImageListItem,
  Portal,
  Radio,
  Snackbar,
  Theme,
  Typography,
  useTheme,
} from "@mui/material"
import { styled as MuiStyled } from "@mui/system"
import { useContext, useEffect, useRef, useState } from "react"
import { AddGreyedOut, EditGrayedOut } from "../Icons/GreyedOutIcons"
import { Colors } from "../../../styles/Colors"
import { get, post } from "../../../utils/requests"
import { UserContext } from "../../../contexts/user"
import { IPhoto } from "../../../utils/photos"
import { PopUp } from "../PopUp"
import { ImageViewer } from "../ImageViewer"
import { parsePhotos } from "../../../utils/parsers"
import useWindowDimensions, {
  getWindowDimensions,
} from "../../../utils/windowDimensions"
import { MediaThumbnails } from "./MediaThumbnails"
import { InverseButton, PrimaryButton } from "../Buttons"
import { LoadingSpinner } from "../LoadingSpinner"
import {
  CHAT_MEDIA_MAX_FILES,
  FILE_UPLOAD_SIZE_ERROR,
} from "../../../utils/constants"
import {
  modalDisplayErrorHandler,
  ProfileUpdate,
} from "../../../utils/imageErrorModalHandler"
import { RequestErrorModal } from "../../Molecules/RequestErrorModal"
import { ErrorContext } from "../../../contexts/photoUploadErrors"
import { MediaContentType, MediaRequirement } from "./PromoRequirements"
import { BlossmImage } from "../BlossmImage"
import react from "react"

export interface IAddMediaProps {
  media?: IOrderPromoPhoto[]
  displayOnly?: boolean
  setModalOpen?: (open: boolean) => void
  set?: (photos: IOrderPromoPhoto[]) => void
  headless?: boolean
  openModal?: boolean
  uploadLocation?: string
  isPopUpOpen?: boolean
  hideIcon?: boolean
  hideMediaCount?: boolean
  maxMediaAmount?: number
  mediaRequirement?: MediaRequirement
  mediaContentType?: MediaContentType
}

export interface IOrderPromoPhoto extends IPhoto {
  selected: boolean
}

export const AddMedia = ({
  media,
  displayOnly = false,
  setModalOpen,
  set,
  headless = false,
  openModal = false,
  uploadLocation,
  isPopUpOpen = false,
  hideIcon = false,
  hideMediaCount = false,
  maxMediaAmount = CHAT_MEDIA_MAX_FILES,
  mediaRequirement,
  mediaContentType,
}: IAddMediaProps): react.JSX.Element => {
  const theme = useTheme()
  const { context } = useContext(UserContext)
  const [open, setOpen] = useState(isPopUpOpen)
  const [blossmPhotos, setBlossmPhotos] = useState<IOrderPromoPhoto[]>([])
  const [selectedPhotos, setSelectedPhotos] = useState<IOrderPromoPhoto[]>([])
  const [showBlossmMediaSelector, setShowBlossmMediaSelector] = useState(false)
  const photoInputFile = useRef<HTMLInputElement>(null)
  const [imageViewerOpen, setImageViewerOpen] = useState(false)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [warningAlertOpen, setWarningAlertOpen] = useState<boolean>(false)
  const [warningAlertMessage, setWarningAlertMessage] = useState<string[]>([])
  const [imgCols, setImgCols] = useState(3)
  const [placeholderCount, setPlaceholderCount] = useState<number>(0)
  const { errorDispatch } = useContext(ErrorContext)

  function getPhotos(): void {
    get(
      `/photo_video/photo/list/${context.profileName}/?include_recent_uploads=1`
    ).then((response) => {
      const newPhotos = parsePhotos(response.data)
      newPhotos.forEach((photo) => {
        const found = blossmPhotos.find((media) => media.id === photo.id)

        if (found && found?.selected) {
          photo.selected = true
        }
      })
      setBlossmPhotos(newPhotos)
    })
  }

  useEffect(() => {
    setSelectedPhotos(blossmPhotos.filter((photo) => photo.selected))
  }, [blossmPhotos])

  const gotPhotos = useRef(false)
  useEffect(() => {
    if (open && !gotPhotos.current) {
      getPhotos()
      gotPhotos.current = true
    }

    if (!open && openModal) {
      setOpen(true)
    }
  }, [open, openModal])

  useEffect(() => {
    if (headless && !media) {
      setOpen(true)
    }

    if (media) {
      media.forEach((file) => {
        const found = blossmPhotos.find((media) => media.id === file.id)

        if (found === undefined) {
          setBlossmPhotos((prevState) => [...prevState, file])
        }
      })
    }
  }, [media, headless])

  useEffect(() => {
    if (set && selectedPhotos.length > 0) {
      set(selectedPhotos)
    }
  }, [selectedPhotos])

  useEffect(() => {
    const { width } = getWindowDimensions()
    if (width > 640) {
      setImgCols(5)
    } else if (width > 480) {
      setImgCols(4)
    } else {
      setImgCols(3)
    }
  }, [useWindowDimensions()])

  function handleSave(): void {
    setOpen(false)
    if (set) {
      set(selectedPhotos)
    }

    if (setModalOpen) {
      setModalOpen(false)
    }
  }

  function openBlossmMediaSelector(): void {
    setShowBlossmMediaSelector(true)
  }

  function closeBlossmMediaSelector(): void {
    setShowBlossmMediaSelector(false)
  }

  function openMediaUpload(): void {
    if (selectedPhotos.length === maxMediaAmount) {
      setWarningAlertMessage([
        "Maximum of " + maxMediaAmount + " photos/videos can be selected!",
      ])
      setWarningAlertOpen(true)
    } else if (photoInputFile.current !== null) {
      photoInputFile.current.click()
    }
  }

  function togglePhotoSelected(photo: IOrderPromoPhoto): void {
    if (
      photo.selected ||
      (!photo.selected && selectedPhotos.length < maxMediaAmount)
    ) {
      setBlossmPhotos((prevState) => {
        return prevState.map((p) => {
          if (p === photo) {
            return { ...p, selected: !p.selected }
          }
          return p
        })
      })
    } else {
      setWarningAlertMessage([
        "Maximum of " + maxMediaAmount + " photos/videos can be selected!",
      ])
      setWarningAlertOpen(true)
    }
  }

  function handleImageSelection(index: number): void {
    setCurrentImageIndex(index)
    setImageViewerOpen(true)
  }

  function fileCheck(files: FileList): boolean {
    let fileCount = false
    if (
      files !== null &&
      files.length + selectedPhotos.length > maxMediaAmount
    ) {
      fileCount = true
    }

    const fileGreaterThan200MB = Array.from(files).some((file) => {
      return file.size > 200 * 1024 * 1024 // Check if file is greater than 200 MB
    })

    if (fileGreaterThan200MB || fileCount) {
      const errorMessage = []
      if (fileGreaterThan200MB) {
        errorMessage.push(FILE_UPLOAD_SIZE_ERROR)
      }
      if (fileCount) {
        errorMessage.push(
          "Maximum of " + maxMediaAmount + " photos/videos can be selected!"
        )
      }
      setOpen(false)
      setWarningAlertMessage(errorMessage)
      setWarningAlertOpen(true)
      return false
    }
    return true
  }

  return (
    <>
      <PopUp
        open={open && !showBlossmMediaSelector}
        handleToggle={(open) => {
          setOpen(open)
          handleSave()
        }}
      >
        <StyledSelection>
          <div className={"package-selector"}>
            <FormLabel
              className={"selector-label"}
            >{`Add Photos, GIFs, and Videos (${selectedPhotos.length}/${maxMediaAmount})`}</FormLabel>
            {(selectedPhotos.length > 0 || placeholderCount > 0) && (
              <ImageList
                cols={imgCols}
                rowHeight={116}
                gap={8}
                sx={{ justifyItems: "center", paddingBottom: "8px" }}
              >
                {Array.from({ length: placeholderCount }, (_, index) => {
                  return <LoadingSpinner size={116} key={index} />
                })}
                {selectedPhotos.map(
                  (photo) =>
                    photo?.originalUrl && (
                      <ImageListItem
                        key={photo.id}
                        sx={{ width: "116px", height: "116px", padding: "4px" }}
                      >
                        <img
                          className={"image-list-item"}
                          src={`${
                            photo.isVideo
                              ? photo.thumbnailUrl
                              : photo.originalUrl
                          }`}
                          style={{
                            width: "112px",
                            height: "112px",
                            borderRadius: "4px",
                            objectFit: "scale-down",
                          }}
                          alt="Media"
                          loading="lazy"
                        />
                        <RemoveCircleOutlined
                          onClick={() => {
                            togglePhotoSelected(photo)
                          }}
                          style={{
                            color: Colors.action.active.dark,
                            position: "absolute",
                            width: "24px",
                            cursor: "pointer",
                            top: "14px",
                            right: "14px",
                          }}
                        />
                        {photo.isVideo && (
                          <PlayArrowIcon
                            sx={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              color: "#fff",
                              backgroundColor: theme.palette.primary.main,
                              borderRadius: "50%",
                              padding: "4px",
                              fontSize: "40px",
                            }}
                          />
                        )}
                      </ImageListItem>
                    )
                )}
              </ImageList>
            )}
            <input
              type="file"
              ref={photoInputFile}
              style={{ display: "none" }}
              accept="image/*, .jpg, .jpeg, .png, .gif, video/mp4,"
              onChange={(event) => {
                event.stopPropagation()
                event.preventDefault()

                if (event.target.files && !fileCheck(event.target.files)) {
                  event.target.value = ""
                  return
                }

                const uploadFile = (i: number): void => {
                  if (event.target.files !== null) {
                    setPlaceholderCount(event.target.files.length)
                    const isVideo = event.target.files[i].type.match("video.*")
                    const formData = new FormData()
                    formData.append(
                      isVideo ? "upload_file" : "image_file",
                      event.target.files[i]
                    )
                    formData.append("profile", context.profileName)
                    formData.append("is_public", "false")
                    if (uploadLocation) {
                      formData.append("uploaded_from", uploadLocation)
                    }
                    const path = isVideo
                      ? "photoset/create/video/"
                      : "photo/upload/"
                    post(`/photo_video/${path}`, formData, { timeout: 300000 })
                      .then(() => {
                        i++
                        if (
                          event.target.files !== null &&
                          i < event.target.files.length
                        ) {
                          uploadFile(i)
                        } else {
                          get(
                            `/photo_video/photo/list/${context.profileName}/?include_recent_uploads=1`
                          ).then((response) => {
                            const parsedPhotos = parsePhotos(response.data)
                            if (event.target.files !== null) {
                              for (
                                let j = event.target.files.length - 1;
                                j >= 0;
                                j--
                              ) {
                                setBlossmPhotos((prevState) => {
                                  return [
                                    { ...parsedPhotos[j], selected: true },
                                    ...prevState,
                                  ]
                                })
                              }
                            }
                            setPlaceholderCount(0)
                          })
                        }
                      })
                      .catch((error) => {
                        modalDisplayErrorHandler(
                          error.response.status,
                          errorDispatch,
                          error,
                          ProfileUpdate.Media,
                          () => setPlaceholderCount(0)
                        )
                      })
                  }
                }
                uploadFile(0)
              }}
              multiple
            />
            <InverseButton variant="outlined" onClick={openMediaUpload}>
              Upload from my device
            </InverseButton>
            <InverseButton onClick={openBlossmMediaSelector}>
              Select from my Blossm Media
            </InverseButton>
            <Divider />
            <PrimaryButton fullWidth onClick={handleSave}>
              Save Selection
            </PrimaryButton>
          </div>
        </StyledSelection>
      </PopUp>
      <PopUp
        open={showBlossmMediaSelector}
        handleToggle={(open) => setShowBlossmMediaSelector(open)}
      >
        <StyledMediaSelector theme={theme}>
          <span className={"back-bar"}>
            <Back onClick={closeBlossmMediaSelector} />
            <Typography color={theme.palette.text.primary} variant={"body1"}>
              Add Media from Blossm
            </Typography>
          </span>
          <Typography
            style={{ margin: "12px" }}
            color={theme.palette.text.secondary}
          >
            Select up to {maxMediaAmount} Images and Videos to add to your promo
          </Typography>
          {blossmPhotos?.length ? (
            <ImageList
              cols={imgCols}
              rowHeight={116}
              gap={8}
              sx={{ justifyItems: "center", overflow: "visible" }}
            >
              {blossmPhotos.map(
                (photo) =>
                  photo.originalUrl && (
                    <ImageListItem
                      key={photo.id}
                      sx={{ width: "116px", height: "116px", padding: "4px" }}
                    >
                      <img
                        className={
                          selectedPhotos.indexOf(photo) > -1
                            ? "image-list-item-selected"
                            : "image-list-item"
                        }
                        src={`${
                          photo.isVideo ? photo.thumbnailUrl : photo.originalUrl
                        }`}
                        alt="Media"
                        style={{
                          width: "112px",
                          height: "112px",
                          borderRadius: "8px",
                          objectFit: "scale-down",
                        }}
                        loading="lazy"
                        onClick={() => togglePhotoSelected(photo)}
                      />
                      <Radio
                        className={"image-radio"}
                        style={
                          selectedPhotos.indexOf(photo) > -1
                            ? { color: theme.palette.primary.main }
                            : { color: Colors.action.active.dark }
                        }
                        checked={selectedPhotos.indexOf(photo) > -1}
                        onClick={() => togglePhotoSelected(photo)}
                      />
                      {photo.isVideo && (
                        <PlayArrowIcon
                          onClick={() => togglePhotoSelected(photo)}
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            color: "#fff",
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: "50%",
                            padding: "4px",
                            fontSize: "40px",
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </ImageListItem>
                  )
              )}
            </ImageList>
          ) : (
            <Typography
              style={{ margin: "12px" }}
              color={theme.palette.text.secondary}
            >
              No media to display
            </Typography>
          )}
        </StyledMediaSelector>
      </PopUp>
      <StyledAddMedia
        theme={theme}
        onClick={() => {
          if (!displayOnly) {
            setOpen(true)
          }
        }}
        displayOnly={displayOnly}
      >
        {selectedPhotos && (
          <ImageViewer
            images={selectedPhotos.map((photo) => {
              return photo
            })}
            open={imageViewerOpen}
            onClose={() => setImageViewerOpen(false)}
            position={currentImageIndex}
            setPosition={setCurrentImageIndex}
            showDownloadBtn
          />
        )}

        {headless ? (
          <MessagePreviewContainer>
            {selectedPhotos.map((image, index) => {
              return (
                <div
                  className={"image-container"}
                  key={image.id}
                  onClick={() => handleImageSelection(index)}
                >
                  <BlossmImage
                    scaleDown={true}
                    alt=""
                    src={`${
                      image.isVideo ? image.thumbnailUrl : image.originalUrl
                    }`}
                    borderRadius={6}
                  />
                  {image.isVideo && (
                    <PlayArrowIcon
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "#fff",
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: "50%",
                        padding: "4px",
                        fontSize: "40px",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </div>
              )
            })}
          </MessagePreviewContainer>
        ) : (
          // <MediaThumbnails media={selectedPhotos} onClick={handleImageSelection} />
          <>
            {!hideIcon && (
              <StyledIconBacking
                selected={selectedPhotos.length > 0}
                theme={theme}
              >
                <Photo sx={{ color: "inherit" }} fontSize={"small"} />
              </StyledIconBacking>
            )}
            <div className={"text-stack"}>
              {selectedPhotos?.length ? (
                <MediaThumbnails
                  media={selectedPhotos}
                  onClick={handleImageSelection}
                  displayOnly={displayOnly}
                />
              ) : (
                <Typography variant={"body1"}>{`Add ${
                  mediaContentType === MediaContentType.NonNude
                    ? "Non-Nude"
                    : ""
                } Photos/Videos`}</Typography>
              )}

              {!hideMediaCount && (
                <>
                  <Typography
                    color={theme.palette.text.secondary}
                    variant={"body2"}
                  >
                    {selectedPhotos
                      ? selectedPhotos.length + " Attached Photos/Videos"
                      : "Media that you want the Seller to use"}
                  </Typography>
                  {selectedPhotos.length === 0 &&
                    mediaRequirement === MediaRequirement.Required && (
                      <Typography
                        color={theme.palette.error.main}
                        variant={"body2"}
                      >
                        (Required)
                      </Typography>
                    )}
                </>
              )}
            </div>
            {!displayOnly && (
              <div className={"add-icon"}>
                {selectedPhotos.length > 0 ? (
                  <EditGrayedOut />
                ) : (
                  <AddGreyedOut />
                )}
              </div>
            )}
          </>
        )}
      </StyledAddMedia>
      <Portal>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={warningAlertOpen}
          autoHideDuration={10000}
          onClose={() => setWarningAlertOpen(false)}
        >
          <Alert severity="warning">
            {warningAlertMessage.map((message) => (
              <div key={message.split(" ")[0]}>{message}</div>
            ))}
          </Alert>
        </Snackbar>
      </Portal>
      <RequestErrorModal />
    </>
  )
}

const MessagePreviewContainer = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
  overflow-x: auto;

  .image-container {
    border-radius: 6px;
    width: 100px;
    min-width: 100px;
    height: 100px;
    position: relative;
    cursor: pointer;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  }
`

const Back = MuiStyled(ArrowBack)({
  color: Colors.action.active.light,
  cursor: "pointer",
})

const StyledSelection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .package-selector {
    background: white;
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 100%;
    gap: 10px;
    border-radius: 4px;

    .selector-label {
      font-size: 14px;
      font-weight: 500;
    }

    .selection-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .blossm-media-selector {
    z-index: 100;
    background: white;
    width: 100%;
    height: 100%;
  }
`

const StyledMediaSelector = styled.div<{ theme: Theme }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .back-bar {
    display: flex;
    flex-direction: row;
    height: 80px;
    margin-left: 10px;
    margin-top: -15px;
    align-items: center;
  }

  .image-list-item {
    width: 112px;
    height: 112px;
    border-radius: 8px;
    cursor: pointer;
  }

  .image-list-item:hover {
    box-shadow: 0px 0px 5px 1px;
  }

  .image-list-item-selected {
    width: 112px;
    height: 112px;
    border: ${(props) => props.theme.palette.primary.main};
    border-radius: 4px;
    cursor: pointer;
  }

  .image-list-item-selected:hover {
    box-shadow: 0px 0px 5px 1px;
  }

  .image-radio {
    position: absolute;
    pointer-events: none;
    left: 0px;
    top: 0px;
  }

  .package-selector {
    background: white;
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 100%;
    gap: 110px;
    border-radius: 4px;

    .selector-label {
      font-size: 14px;
      font-weight: 500;
    }

    .selection-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
`

export const StyledIconBacking = styled.div<{
  selected: boolean
  theme: Theme
}>`
  background: ${(props) =>
    props.selected ? props.theme.palette.primary.main : "none"};
  color: ${(props) =>
    !props.selected ? props.theme.palette.text.secondary : Colors.white};
  border-radius: 50%;
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  padding: 10px;
`

const StyledAddMedia = styled.div<{ theme: Theme; displayOnly: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  cursor: ${(props) => (props.displayOnly ? "default" : "pointer")};

  .text-stack {
    display: flex;
    flex-direction: column;
  }

  .add-icon {
    margin-left: auto;
    cursor: pointer;
  }
`
