import React, { Dispatch } from "react"
import { ActionMap } from "../utils/types"
import { Brands } from "../components/Atoms/Profile/Header/Logo"

export enum TimeFrame {
  "All Time",
  "Last 30 Days",
  "Last 60 Days",
  "Last 90 Days",
  "`2023`",
}

export const initialCurrentDraft: IDraft = {
  link: "",
  name: "",
  rate: 1,
  landScapeImageChangedFromOriginal: false,
  bannerImageChangedFromOriginal: false,
}

export const initialState: CampaignStateType = {
  campaigns: [],
  drafts: [],
  currentDraft: undefined,
  timeFrame: TimeFrame[1],
  addFundsOpen: false,
  clickBidOptions: [],
  trafficProfile: "",
  adDestinationInfo: {
    platform: "",
    banner: {
      uuid: "",
      height: 0,
      width: 0,
    },
    landScape: {
      uuid: "",
      height: 0,
      width: 0,
    },
  },
}

export interface IDraft {
  id?: string
  link?: string
  type?: "Camsite" | "Directory"
  keywords?: number[]
  budget?: number
  rate?: number
  selectedBidRate?: IBidOption
  funds?: number
  name?: string
  lastEdited?: Date
  platform?: Brands
  gender?: number
  bannerImage?: string
  bannerImageFile?: File
  bannerImageChangedFromOriginal?: boolean
  landscapeImage?: string
  landscapeImageFile?: File
  landScapeImageChangedFromOriginal?: boolean
}

export interface IBidOption {
  bidPerClick: number
  description: string
  name: string
  numberValue: number
  uuid: string
}

export interface ICampaignRun {
  id: string
  campaignId?: string
  timeframe: {
    start: Date
    end?: Date
  }
  status: "active" | "stopped" | "complete" | "error"
  impressions: number
  clicks: number
  spending: {
    amount: number
    total: number
  }
}

export interface ICampaign {
  //todo figure this out
  status: "active" | "stopped" | "complete" | "error"
  id: string
  link: string
  type: "Camsite" | "Directory"
  keywords: number[]
  budget: number
  rate: number
  name: string
  lastEdited: Date
  platform: Brands
  runs: ICampaignRun[]
}

export interface IAdImage {
  height: number
  width: number
  uuid: string
}

export interface IAdInfo {
  platform: string
  banner: IAdImage
  landScape: IAdImage
}

export type CampaignStateType = {
  timeFrame: string
  campaigns: ICampaign[]
  drafts: IDraft[]
  currentDraft: IDraft | undefined
  addFundsOpen: boolean
  clickBidOptions: IBidOption[]
  trafficProfile: string
  adDestinationInfo: IAdInfo
}

export enum ActionTypes {
  CreateDraft = "Create Draft",
  LoadDrafts = "Load Drafts",
  LoadCampaigns = "LoadCampaigns",
  UpdateTimeFrame = "Update Timeframe",
  DeleteDraft = "Delete Draft",
  SetDraftType = "Set Draft Type",
  SetDraftLink = "Set Draft Link",
  SetDraftKeywords = "Set Draft Keywords",
  SetDraftBudget = "Set Draft Budget",
  SetDraftRate = "Set Draft Rate",
  SetDraftFunds = "Set Draft Funds",
  SetDraftName = "Set Draft Name",
  SetDraftGender = "Set Draft Gender",
  RemoveDraftImage = "Remove Draft Image",
  SetCurrentDraft = "Set Current Draft",
  OpenAddFunds = "Open Add Funds",
  CloseAddFunds = "Close Add Funds",
  StartCampaign = "Start Campaign",
  StopCampaign = "Stop Campaign",
  DeleteCampaign = "Delete Campaign",
  SetBidRate = "Set Bid Rate",
  ClearDraft = "Clear Draft",
  SetUpDraftEdit = "Set Up Draft Edit",
  SetBannerImage = "Set Banner Image",
  SetBannerImageFile = "Set Banner Image File",
  DeleteBannerImage = "Delete Banner Image",
  SetLandscapeImage = "Set Landscape Image",
  SetLandscapeImageFile = "Set Landscape Image File",
  DeleteLandscapeImage = "Delete Landscape Image",
  LoadBidOptions = "Load Bid Options",
  SetTrafficProfile = "Set Traffic Profile",
  SetAdDestinationInfo = "Set Ad Destination Info",
}

type CampaignPayload = {
  [ActionTypes.LoadCampaigns]: {
    campaigns: ICampaign[]
  }
  [ActionTypes.LoadDrafts]: {
    drafts: IDraft[]
  }
  [ActionTypes.DeleteDraft]: {
    draftID: string
  }
  [ActionTypes.SetDraftLink]: {
    link: string
  }
  [ActionTypes.UpdateTimeFrame]: {
    timeframeIndex: number
  }
  [ActionTypes.SetDraftType]: {
    type: "Camsite" | "Directory"
  }
  [ActionTypes.SetDraftLink]: {
    link: string
  }
  [ActionTypes.SetDraftKeywords]: {
    keywords: number[]
  }
  [ActionTypes.SetDraftBudget]: {
    budget: number
  }
  [ActionTypes.SetDraftRate]: {
    rate: number
  }
  [ActionTypes.SetDraftFunds]: {
    funds: number
  }
  [ActionTypes.SetDraftName]: {
    name: string
  }
  [ActionTypes.SetDraftGender]: {
    gender: number
  }
  [ActionTypes.SetCurrentDraft]: {
    draftId: string
  }
  [ActionTypes.OpenAddFunds]: null
  [ActionTypes.CloseAddFunds]: null
  [ActionTypes.StartCampaign]: {
    campaignId: string
  }
  [ActionTypes.StopCampaign]: {
    campaignId: string
  }
  [ActionTypes.DeleteCampaign]: {
    campaignId: string
  }
  [ActionTypes.RemoveDraftImage]: {
    imageUrl: string
  }
  [ActionTypes.SetBidRate]: {
    selectedBid: IBidOption
  }
  [ActionTypes.CreateDraft]: {
    initialBid: IBidOption
  }
  [ActionTypes.ClearDraft]: null
  [ActionTypes.SetUpDraftEdit]: {
    draft: IDraft
  }
  [ActionTypes.SetBannerImage]: string
  [ActionTypes.SetBannerImageFile]: File
  [ActionTypes.DeleteBannerImage]: null
  [ActionTypes.SetLandscapeImage]: string
  [ActionTypes.SetLandscapeImageFile]: File
  [ActionTypes.DeleteLandscapeImage]: null
  [ActionTypes.LoadBidOptions]: IBidOption[]
  [ActionTypes.SetTrafficProfile]: string
  [ActionTypes.SetAdDestinationInfo]: IAdInfo[]
}

export type CampaignActions =
  ActionMap<CampaignPayload>[keyof ActionMap<CampaignPayload>]

// eslint-disable-next-line complexity
export function reducer(
  state: CampaignStateType = initialState,
  action: CampaignActions
): CampaignStateType {
  let newState
  let currentStateItem
  switch (action.type) {
    case ActionTypes.LoadDrafts:
      console.log(action.payload)
      newState = {
        ...state,
        drafts: action.payload.drafts,
      }
      break
    case ActionTypes.LoadCampaigns:
      newState = {
        ...state,
        campaigns: action.payload.campaigns,
      }
      break
    case ActionTypes.UpdateTimeFrame:
      newState = {
        ...state,
        timeFrame: TimeFrame[action.payload.timeframeIndex],
      }
      break
    case ActionTypes.DeleteDraft:
      currentStateItem = [...state.drafts].filter(
        (d) => d.id !== action.payload.draftID
      )
      newState = {
        ...state,
        drafts: currentStateItem,
      }
      break
    case ActionTypes.SetDraftType:
      currentStateItem = { ...state.currentDraft }
      currentStateItem.type = action.payload.type
      newState = {
        ...state,
        currentDraft: currentStateItem,
      }
      break
    case ActionTypes.SetDraftLink:
      currentStateItem = { ...state.currentDraft }
      currentStateItem.link = action.payload.link
      newState = {
        ...state,
        currentDraft: currentStateItem,
      }
      break
    case ActionTypes.SetDraftKeywords:
      currentStateItem = { ...state.currentDraft }
      currentStateItem.keywords = action.payload.keywords
      newState = {
        ...state,
        currentDraft: currentStateItem,
      }
      break
    case ActionTypes.SetDraftBudget:
      currentStateItem = { ...state.currentDraft }
      currentStateItem.budget = action.payload.budget
      newState = {
        ...state,
        currentDraft: currentStateItem,
      }
      break
    case ActionTypes.SetDraftRate:
      currentStateItem = { ...state.currentDraft }
      currentStateItem.rate = action.payload.rate
      newState = {
        ...state,
        currentDraft: currentStateItem,
      }
      break
    case ActionTypes.SetDraftFunds:
      currentStateItem = { ...state.currentDraft }
      currentStateItem.funds = action.payload.funds
      newState = {
        ...state,
        currentDraft: currentStateItem,
      }
      break
    case ActionTypes.SetDraftName:
      currentStateItem = { ...state.currentDraft }
      currentStateItem.name = action.payload.name
      newState = {
        ...state,
        currentDraft: currentStateItem,
      }
      break
    case ActionTypes.SetDraftGender:
      currentStateItem = { ...state.currentDraft }
      currentStateItem.gender = action.payload.gender
      newState = {
        ...state,
        currentDraft: currentStateItem,
      }
      break
    case ActionTypes.OpenAddFunds:
      newState = {
        ...state,
        addFundsOpen: true,
      }
      break
    case ActionTypes.CloseAddFunds:
      newState = {
        ...state,
        addFundsOpen: false,
      }
      break
    case ActionTypes.StartCampaign:
      currentStateItem = [...state.campaigns]
      currentStateItem[
        currentStateItem.findIndex((c) => c.id === action.payload.campaignId)
      ].status = "active"
      newState = {
        ...state,
        campaigns: currentStateItem,
      }
      break
    case ActionTypes.StopCampaign:
      currentStateItem = [...state.campaigns]
      currentStateItem[
        currentStateItem.findIndex((c) => c.id === action.payload.campaignId)
      ].status = "stopped"
      newState = {
        ...state,
        campaigns: currentStateItem,
      }
      break
    case ActionTypes.DeleteCampaign:
      currentStateItem = [...state.campaigns].filter(
        (d) => d.id !== action.payload.campaignId
      )
      newState = {
        ...state,
        campaigns: currentStateItem,
      }
      break
    case ActionTypes.SetBidRate:
      currentStateItem = { ...state.currentDraft }
      currentStateItem.selectedBidRate = action.payload.selectedBid
      newState = {
        ...state,
        currentDraft: currentStateItem,
      }
      break
    case ActionTypes.CreateDraft:
      newState = {
        ...state,
        currentDraft: {
          selectedBidRate: action.payload.initialBid,
          rate: 1,
        },
      }
      break
    case ActionTypes.ClearDraft:
      newState = {
        ...state,
        currentDraft: undefined,
      }
      break
    case ActionTypes.SetUpDraftEdit:
      newState = {
        ...state,
        currentDraft: action.payload.draft,
      }
      break
    case ActionTypes.DeleteBannerImage:
      currentStateItem = { ...state.currentDraft }
      currentStateItem.bannerImage = undefined
      currentStateItem.bannerImageFile = undefined
      newState = {
        ...state,
        currentDraft: currentStateItem,
      }
      break
    case ActionTypes.SetBannerImage:
      currentStateItem = { ...state.currentDraft }
      currentStateItem.bannerImage = action.payload
      currentStateItem.bannerImageChangedFromOriginal = true
      newState = {
        ...state,
        currentDraft: currentStateItem,
      }
      break
    case ActionTypes.SetBannerImageFile:
      currentStateItem = { ...state.currentDraft }
      currentStateItem.bannerImageFile = action.payload
      newState = {
        ...state,
        currentDraft: currentStateItem,
      }
      break
    case ActionTypes.SetLandscapeImage:
      currentStateItem = { ...state.currentDraft }
      currentStateItem.landscapeImage = action.payload
      currentStateItem.landScapeImageChangedFromOriginal = true
      newState = {
        ...state,
        currentDraft: currentStateItem,
      }
      break
    case ActionTypes.SetLandscapeImageFile:
      currentStateItem = { ...state.currentDraft }
      currentStateItem.landscapeImageFile = action.payload
      newState = {
        ...state,
        currentDraft: currentStateItem,
      }
      break
    case ActionTypes.DeleteLandscapeImage:
      currentStateItem = { ...state.currentDraft }
      currentStateItem.landscapeImage = undefined
      currentStateItem.landscapeImageFile = undefined
      newState = {
        ...state,
        currentDraft: currentStateItem,
      }
      break
    case ActionTypes.LoadBidOptions:
      newState = {
        ...state,
        clickBidOptions: action.payload,
      }
      break
    case ActionTypes.SetTrafficProfile:
      newState = {
        ...state,
        trafficProfile: action.payload,
      }
      break
    case ActionTypes.SetAdDestinationInfo:
      newState = {
        ...state,
        adDestinationInfo: action.payload[0],
      }
      break
    default:
      newState = {
        ...state,
      }
      break
  }
  return newState
}

export function getTimeFrameDates(timeframe: string): {
  start: string
  end: string
} {
  const currentDate = new Date()
  const endDate = new Date()
  switch (timeframe) {
    // "All Time",
    //   "Last 30 Days",
    //       "Last 60 Days",
    //       "Last 90 Days",
    //       "`2023`",
    //       "`2022`"
    case "Last 30 Days":
      return {
        end: new Date(currentDate.toUTCString())
          .toLocaleDateString("UTC", {
            month: "numeric",
            day: "numeric",
            year: "numeric",
          })
          .replaceAll("/", "-"),
        start: new Date(
          new Date(endDate.setDate(endDate.getDate() - 30)).toUTCString()
        )
          .toLocaleDateString("UTC", {
            month: "numeric",
            day: "numeric",
            year: "numeric",
          })
          .replaceAll("/", "-"),
      }
      break
    case "Last 60 Days":
      return {
        end: new Date(currentDate.toUTCString())
          .toLocaleDateString("UTC", {
            month: "numeric",
            day: "numeric",
            year: "numeric",
          })
          .replaceAll("/", "-"),
        start: new Date(
          new Date(endDate.setDate(endDate.getDate() - 30)).toUTCString()
        )
          .toLocaleDateString("UTC", {
            month: "numeric",
            day: "numeric",
            year: "numeric",
          })
          .replaceAll("/", "-"),
      }
      break
    case "Last 90 Days":
      return {
        end: new Date(currentDate.toUTCString())
          .toLocaleDateString("UTC", {
            month: "numeric",
            day: "numeric",
            year: "numeric",
          })
          .replaceAll("/", "-"),
        start: new Date(
          new Date(endDate.setDate(endDate.getDate() - 30)).toUTCString()
        )
          .toLocaleDateString("UTC", {
            month: "numeric",
            day: "numeric",
            year: "numeric",
          })
          .replaceAll("/", "-"),
      }
      break
    case "2023":
    case "All Time":
    default:
      return {
        end: currentDate
          .toLocaleDateString("UTC", {
            month: "numeric",
            day: "numeric",
            year: "numeric",
          })
          .replaceAll("/", "-"),
        start: new Date("1/1/2023")
          .toLocaleDateString("UTC", {
            month: "numeric",
            day: "numeric",
            year: "numeric",
          })
          .replaceAll("/", "-"),
      }
  }
}

export const CampaignContext = React.createContext<{
  campaignContext: CampaignStateType
  campaignDispatch: Dispatch<CampaignActions>
}>({
  campaignContext: initialState,
  campaignDispatch: () => null,
})
