import { Box, useTheme } from "@mui/system"
import { Group, Groups } from "../../Molecules/Promo/GroupsLayout"
import {
  Collapse,
  Fade,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material"
import {
  ImageOutlined,
  LinkOutlined,
  Star,
  Add,
  Close,
} from "@mui/icons-material"
import { PrimaryButton } from "../Buttons"
import { Colors } from "../../../styles/Colors"
import {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import { IPromoTypeInfo } from "../../Molecules/Promo/CreatePromo"

export const enum MediaRequirement {
  Optional = "optional",
  Required = "required",
}

export const enum MediaContentType {
  Nude = "nude",
  NonNude = "non_nude",
}

export const enum LinkRequirement {
  FreeTrial = "free_trial",
  CampaignPaid = "campaign_paid",
  CampaignFree = "campaign_free",
  None = "none",
}

export interface IPromoRequirementsProps {
  promoType: IPromoTypeInfo
  mediaRequirement: MediaRequirement
  setMediaRequirement: React.Dispatch<React.SetStateAction<MediaRequirement>>
  mediaContentType: MediaContentType
  setMediaContentType: React.Dispatch<React.SetStateAction<MediaContentType>>
  linkRequirement: LinkRequirement
  setLinkRequirement: React.Dispatch<React.SetStateAction<LinkRequirement>>
  customRequirement1: string
  setCustomRequirement1: React.Dispatch<React.SetStateAction<string>>
  customRequirement2: string
  setCustomRequirement2: React.Dispatch<React.SetStateAction<string>>
  customRequirement3: string
  setCustomRequirement3: React.Dispatch<React.SetStateAction<string>>
}

export const PromoRequirements = ({
  promoType,
  mediaRequirement,
  setMediaRequirement,
  mediaContentType,
  setMediaContentType,
  linkRequirement,
  setLinkRequirement,
  customRequirement1,
  setCustomRequirement1,
  customRequirement2,
  setCustomRequirement2,
  customRequirement3,
  setCustomRequirement3,
}: IPromoRequirementsProps): JSX.Element => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const [openCustomRequirement1, setOpenCustomRequirement1] = useState<boolean>(
    customRequirement1.length > 0
  )
  const [openCustomRequirement2, setOpenCustomRequirement2] = useState<boolean>(
    customRequirement2.length > 0
  )
  const [openCustomRequirement3, setOpenCustomRequirement3] = useState<boolean>(
    customRequirement3.length > 0
  )

  useEffect(() => {
    if (promoType.title !== "Guaranteed Gains") {
      setLinkRequirement(LinkRequirement.None)
    }
  }, [])

  const customRequirementGroup = (
    customRequirement: string,
    setCustomRequirement: Dispatch<SetStateAction<string>>,
    openCustomRequirement: boolean,
    setOpenCustomRequirement: Dispatch<SetStateAction<boolean>>
  ): JSX.Element => {
    return (
      <Group isMobile={isMobile} vPadding={"20px"} centerContent={false}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "16px",
            alignSelf: "start",
            width: "100%",
            cursor: openCustomRequirement ? "defualt" : "pointer",
            position: "relative",
            top: "8px",
          }}
          onClick={() => {
            if (!openCustomRequirement) {
              setOpenCustomRequirement(true)
            }
          }}
        >
          <Star
            color="primary"
            sx={{
              color: customRequirement.length > 0 ? "" : "#666",
              position: "relative",
              top: "-3px",
            }}
          />
          <Box>
            <Typography variant={"body1"} color={theme.palette.text.primary}>
              {`${
                !openCustomRequirement ? "Add a" : ""
              } Custom Requirement (Maximum 3)`}
            </Typography>
          </Box>
          <Box
            sx={{
              color: "#666",
              position: "relative",
              top: "-3px",
              flexGrow: 1,
              textAlign: "end",
            }}
          >
            {!openCustomRequirement && <Add />}
            {openCustomRequirement && (
              <Close
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpenCustomRequirement(false)
                  setCustomRequirement("")
                }}
              />
            )}
          </Box>
        </Box>
        <Collapse in={openCustomRequirement} sx={{ width: "100%" }}>
          <TextField
            label={
              customRequirement.length <= 0
                ? ""
                : `${customRequirement.length}/80 characters`
            }
            placeholder={'"Provide your own caption" or "No explicit content"'}
            variant="outlined"
            fullWidth={true}
            multiline={true}
            rows={3}
            inputProps={{ maxLength: 80 }}
            onChange={(event) => {
              setCustomRequirement(event.currentTarget.value)
            }}
            value={customRequirement}
            sx={{ marginBottom: "4px" }}
          />
        </Collapse>
      </Group>
    )
  }

  return (
    <Groups useColumnLayout={true} centerContent={false} isMobile={isMobile}>
      <Group isMobile={isMobile} vPadding={"24px"} centerContent={false}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "16px",
            alignSelf: "start",
          }}
        >
          <ImageOutlined
            color="primary"
            sx={{ position: "relative", top: "-3px" }}
          />
          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <Box>
              <Typography variant={"body1"} color={theme.palette.text.primary}>
                Media Requirements
              </Typography>
              <SelectableButton
                text="Optional"
                selected={mediaRequirement === MediaRequirement.Optional}
                onClick={() => {
                  setMediaRequirement(MediaRequirement.Optional)
                }}
              />
              <SelectableButton
                text="Required"
                selected={mediaRequirement === MediaRequirement.Required}
                onClick={() => {
                  setMediaRequirement(MediaRequirement.Required)
                }}
              />
            </Box>
            <Box sx={{ marginTop: "8px" }}>
              <Typography variant={"body1"} color={theme.palette.text.primary}>
                Allowed Content Types
              </Typography>
              <SelectableButton
                text="Nude"
                selected={mediaContentType === MediaContentType.Nude}
                onClick={() => {
                  setMediaContentType(MediaContentType.Nude)
                }}
              />
              <SelectableButton
                text="Non-Nude Only"
                selected={mediaContentType === MediaContentType.NonNude}
                onClick={() => {
                  setMediaContentType(MediaContentType.NonNude)
                }}
              />
            </Box>
          </Box>
        </Box>
      </Group>

      {promoType.title === "Guaranteed Gains" ? (
        <Group isMobile={isMobile} vPadding={"24px"} centerContent={false}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              alignSelf: "start",
            }}
          >
            <LinkOutlined
              color="primary"
              sx={{ position: "relative", top: "-3px" }}
            />
            <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
              <Box>
                <Typography
                  variant={"body1"}
                  color={theme.palette.text.primary}
                >
                  Link Requirements
                </Typography>
                <SelectableButton
                  text="Free Trial"
                  selected={linkRequirement === LinkRequirement.FreeTrial}
                  onClick={() => {
                    setLinkRequirement(LinkRequirement.FreeTrial)
                  }}
                />
                <SelectableButton
                  text="Campaign - Paid"
                  selected={linkRequirement === LinkRequirement.CampaignPaid}
                  onClick={() => {
                    setLinkRequirement(LinkRequirement.CampaignPaid)
                  }}
                />
                <SelectableButton
                  text="Campaign - Free"
                  selected={linkRequirement === LinkRequirement.CampaignFree}
                  onClick={() => {
                    setLinkRequirement(LinkRequirement.CampaignFree)
                  }}
                />
                <SelectableButton
                  text="None"
                  selected={linkRequirement === LinkRequirement.None}
                  onClick={() => {
                    setLinkRequirement(LinkRequirement.None)
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Group>
      ) : (
        <></>
      )}

      {customRequirementGroup(
        customRequirement1,
        setCustomRequirement1,
        openCustomRequirement1,
        setOpenCustomRequirement1
      )}

      {customRequirement2 || customRequirement1 ? (
        <Fade in={Boolean(customRequirement2 || customRequirement1)}>
          <Box>
            {customRequirementGroup(
              customRequirement2,
              setCustomRequirement2,
              openCustomRequirement2,
              setOpenCustomRequirement2
            )}
          </Box>
        </Fade>
      ) : (
        <></>
      )}

      {customRequirement3 || (customRequirement1 && customRequirement2) ? (
        <Fade
          in={Boolean(
            customRequirement3 || (customRequirement1 && customRequirement2)
          )}
        >
          <Box>
            {customRequirementGroup(
              customRequirement3,
              setCustomRequirement3,
              openCustomRequirement3,
              setOpenCustomRequirement3
            )}
          </Box>
        </Fade>
      ) : (
        <></>
      )}
    </Groups>
  )
}
interface ISelectableButtonProps {
  text: string
  selected: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
}

const SelectableButton = ({
  text,
  selected,
  onClick,
}: ISelectableButtonProps): JSX.Element => {
  return (
    <PrimaryButton
      size="small"
      sx={{
        borderRadius: "16px",
        fontSize: "12px",
        margin: "8px 8px 0 0",
        padding: "3px 12px",
        color: selected ? Colors.white : Colors.black,
        background: selected ? "" : "#eee",
      }}
      onClick={onClick}
    >
      {text}
    </PrimaryButton>
  )
}
