import { Dialog, useTheme } from "@mui/material"
import Typography from "@mui/material/Typography"
import styled from "@emotion/styled"
export interface IModalLogoutProps {
  open: boolean
  title: string
  text: string
  handleClose?: () => void
  onClose?: () => void
  onHandle?: () => void
  optionOne?: string
  optionTwo?: string
}

export const Modal = ({
  open,
  title,
  text,
  onHandle,
  onClose,
  optionOne = "Cancel",
  optionTwo = "Delete",
}: IModalLogoutProps): JSX.Element => {
  const theme = useTheme()
  return (
    <Dialog open={open} onClose={onClose}>
      <WrapperInfo>
        <Typography id="modal-modal-title" variant="h6" fontSize={20}>
          {title}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {text}
        </Typography>
        <ContainerActions>
          <Typography
            className="text-button"
            sx={{ mt: 2, marginRight: 3, cursor: "pointer" }}
            onClick={onClose}
          >
            {optionOne}
          </Typography>
          <Typography
            className="text-button"
            sx={{ mt: 2, cursor: "pointer" }}
            onClick={onHandle}
            color={theme.palette.error.dark}
          >
            {optionTwo}
          </Typography>
        </ContainerActions>
      </WrapperInfo>
    </Dialog>
  )
}

const WrapperInfo = styled.div`
  width: 350px;
  height: 170px;
  padding: 10px;
  @media (max-width: 400px) {
    width: auto;
    height: auto;
  }
`

const ContainerActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 18px;
  align-items: flex-end;

  .text-button {
    width: max-content;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
  }

  .text-button:hover {
    background-color: #e4e4e47e;
    border-radius: 3px;
  }
`
