import React, { Dispatch } from "react"
import { PaletteMode } from "@mui/material"
import { ActionMap } from "../utils/types"
import { IServerBlackoutDate } from "../utils/dates"

export const initialState: UserType = {
  email: "",
  isEmailValidated: false,
  userUuid: "",
  username: "",
  phoneNumber: "",
  isPhoneValidated: false,
  isTfaEnabled: false,
  tfaDevice: "",
  showOnboardingBar: false,
  accountAgeInDays: 0,
  avatarUrl: "",
  theme: "light",
  profileName: "",
  profileDisplayName: "",
  id: -1,
  uuid: "",
  isAuthenticated: false,
  authenticationAttempted: false,
  verified: false,
  promoRequestsRemaining: 0,
  userIdVerified: false,
  buyerAgreementAccepted: false,
  sellerAgreementAccepted: false,
  isEndorsed: false,
  isStaff: false,
  isCustomerSupport: false,
  balance: 0,
  userRole: "",
  location: "",
  blackout_dates_iso_list: [],
  telegramUsername: "",
  telegramChatId: "",
  telegramUrl: "",
  tags: [],
}

export enum ActionTypes {
  Login = "LOGIN",
  Theme = "THEME",
  Page = "PAGE",
  AvatarImageUpdate = "AVATARIMAGEUPDATE",
  ProfileNamesUpdate = "PROFILENAMESUPDATE",
  ProfileRoleUpdate = "PROFILEROLEUPDATE",
  ProfileBlackoutDatesUpdate = "PROFILEBLACKOUTDATESUPDATE",
  ProfileEmailUpdate = "PROFILEMAILUPDATE",
  ProfileTelegramUsernameUpdate = "PROFILETELEGRAMUSERNAMEUPDATE",
  ProfileTelegramChatIdUpdate = "PROFILETELEGRAMCHATIDUPDATE",
  ProfileEmailValidated = "PROFILEEMAILVALIDATED",
  ProfilePhoneNumberUpdate = "PROFILEPHONENUMBERUPDATE",
  ProfilePhoneNumberValidated = "PROFILEPHONENUMBERVALIDATED",
  UpdateTags = "UPDATETAGS",
  Logout = "LOGOUT",
  Verified = "VERIFIED",
  PromoRequestsRemainingUpdate = "PROMOREQUESTSREMAININGUPDATE",
  ShowOnboardingBar = "SHOWONBOARDINGBAR",
  buyerAgreementAccepted = "BUYERAGREEMENTACCEPTED",
  sellerAgreementAccepted = "SELLERAGREEMENTACCEPTED",
  Balance = "BALANCE",
  UpdateLocation = "UPDATELOCATION",
  SetCaptcha = "SETCAPTCHA",
}

export type UserType = {
  email: string
  isEmailValidated: boolean
  userUuid: string
  username: string
  phoneNumber: string
  isPhoneValidated: boolean
  isTfaEnabled: boolean
  showOnboardingBar: boolean
  accountAgeInDays: number
  avatarUrl: string
  theme: PaletteMode
  profileName: string
  profileDisplayName: string
  id: number
  uuid: string
  isAuthenticated: boolean
  authenticationAttempted: boolean
  verified: boolean
  promoRequestsRemaining: number
  userIdVerified: boolean
  buyerAgreementAccepted: boolean
  sellerAgreementAccepted: boolean
  isEndorsed: boolean
  isStaff: boolean
  isCustomerSupport: boolean
  balance?: number
  tags: number[]
  userRole: string
  location: string
  blackout_dates_iso_list: IServerBlackoutDate[]
  telegramUsername: string
  telegramChatId: string
  telegramUrl: string
  captcha?: string
  tfaDevice: string
}

type UserPayload = {
  [ActionTypes.Login]: UserType
  [ActionTypes.AvatarImageUpdate]: {
    avatarUrl: string
  }
  [ActionTypes.Theme]: {
    theme: PaletteMode
  }
  [ActionTypes.ProfileNamesUpdate]: {
    profileName: string
    profileDisplayName: string
  }
  [ActionTypes.ProfileBlackoutDatesUpdate]: {
    blackout_dates_iso_list: IServerBlackoutDate[]
  }
  [ActionTypes.ProfileRoleUpdate]: {
    userRole: string
  }
  [ActionTypes.UpdateLocation]: {
    location: string
  }
  [ActionTypes.ProfileEmailUpdate]: {
    email: string
    isEmailValidated: boolean
  }
  [ActionTypes.ProfileTelegramUsernameUpdate]: {
    telegramUsername: string
  }
  [ActionTypes.ProfileTelegramChatIdUpdate]: {
    telegramChatId: string
  }
  [ActionTypes.ProfileEmailValidated]: void
  [ActionTypes.ProfilePhoneNumberUpdate]: {
    phoneNumber: string
  }
  [ActionTypes.ProfilePhoneNumberValidated]: void
  [ActionTypes.Logout]: {
    isAuthenticated: boolean
  }
  [ActionTypes.Verified]: void
  [ActionTypes.ShowOnboardingBar]: {
    showOnboardingBar: boolean
  }
  [ActionTypes.buyerAgreementAccepted]: {
    buyerAgreementAccepted: boolean
  }
  [ActionTypes.sellerAgreementAccepted]: {
    sellerAgreementAccepted: boolean
  }
  [ActionTypes.PromoRequestsRemainingUpdate]: {
    promoRequestsRemaining: number
    userIdVerified: boolean
  }
  [ActionTypes.Balance]: {
    balance: number
  }
  [ActionTypes.UpdateTags]: {
    tags: number[]
  }
  [ActionTypes.SetCaptcha]: {
    captcha: string
  }
}

export type UserActions = ActionMap<UserPayload>[keyof ActionMap<UserPayload>]

// eslint-disable-next-line complexity
export function reducer(
  state: UserType = initialState,
  action: UserActions
): UserType {
  let newState
  switch (action.type) {
    case ActionTypes.Login:
      newState = {
        ...state,
        email: action.payload.email,
        isEmailValidated: action.payload.isEmailValidated,
        showOnboardingBar: action.payload.showOnboardingBar,
        userUuid: action.payload.userUuid,
        username: action.payload.username,
        phoneNumber: action.payload.phoneNumber,
        telegramUsername: action.payload.telegramUsername,
        telegramChatId: action.payload.telegramChatId,
        telegramUrl: action.payload.telegramUrl,
        avatarUrl: action.payload.avatarUrl,
        theme: action.payload.theme,
        profileName: action.payload.profileName,
        profileDisplayName: action.payload.profileDisplayName,
        id: action.payload.id,
        uuid: action.payload.uuid,
        isAuthenticated: action.payload.isAuthenticated,
        authenticationAttempted: true,
        verified: action.payload.verified,
        promoRequestsRemaining: action.payload.promoRequestsRemaining,
        userIdVerified: action.payload.userIdVerified,
        buyerAgreementAccepted: action.payload.buyerAgreementAccepted,
        sellerAgreementAccepted: action.payload.sellerAgreementAccepted,
        isEndorsed: action.payload.isEndorsed,
        isPhoneValidated: action.payload.isPhoneValidated,
        isTfaEnabled: action.payload.isTfaEnabled,
        tfaDevice: action.payload.tfaDevice,
        accountAgeInDays: action.payload.accountAgeInDays,
        isStaff: action.payload.isStaff,
        isCustomerSupport: action.payload.isCustomerSupport,
        balance: action.payload.balance,
        userRole: action.payload.userRole,
        location: action.payload.location,
        tags: action.payload.tags,
        blackout_dates_iso_list: action.payload.blackout_dates_iso_list,
      }
      break
    case ActionTypes.Logout:
      newState = {
        ...initialState,
      }
      break
    case ActionTypes.AvatarImageUpdate:
      newState = {
        ...state,
        avatarUrl: action.payload.avatarUrl,
      }
      break
    case ActionTypes.Theme:
      newState = {
        ...state,
        theme: action.payload.theme,
      }
      break
    case ActionTypes.ProfileNamesUpdate:
      newState = {
        ...state,
        profileName: action.payload.profileName,
        profileDisplayName: action.payload.profileDisplayName,
      }
      break
    case ActionTypes.ProfileEmailUpdate:
      newState = {
        ...state,
        email: action.payload.email,
        isEmailValidated: action.payload.isEmailValidated,
      }
      break
    case ActionTypes.ProfileTelegramUsernameUpdate:
      newState = {
        ...state,
        telegramUsername: action.payload.telegramUsername,
      }
      break
    case ActionTypes.ProfileTelegramChatIdUpdate:
      newState = {
        ...state,
        telegramChatId: action.payload.telegramChatId,
      }
      break
    case ActionTypes.ProfileRoleUpdate:
      newState = {
        ...state,
        userRole: action.payload.userRole,
      }
      break
    case ActionTypes.UpdateLocation:
      newState = {
        ...state,
        location: action.payload.location,
      }
      break
    case ActionTypes.ProfileBlackoutDatesUpdate:
      newState = {
        ...state,
        blackout_dates_iso_list: action.payload.blackout_dates_iso_list,
      }
      break
    case ActionTypes.ProfileEmailValidated:
      newState = {
        ...state,
        isEmailValidated: true,
      }
      break
    case ActionTypes.ProfilePhoneNumberUpdate:
      newState = {
        ...state,
        phoneNumber: action.payload.phoneNumber,
        isPhoneValidated: false,
      }
      break
    case ActionTypes.ProfilePhoneNumberValidated:
      newState = {
        ...state,
        isPhoneValidated: true,
      }
      break
    case ActionTypes.Verified:
      newState = {
        ...state,
        verified: true,
      }
      break
    case ActionTypes.ShowOnboardingBar:
      newState = {
        ...state,
        showOnboardingBar: action.payload.showOnboardingBar,
      }
      break
    case ActionTypes.PromoRequestsRemainingUpdate:
      newState = {
        ...state,
        promoRequestsRemaining: action.payload.promoRequestsRemaining,
        userIdVerified: action.payload.userIdVerified,
      }
      break
    case ActionTypes.buyerAgreementAccepted:
      newState = {
        ...state,
        buyerAgreementAccepted: action.payload.buyerAgreementAccepted,
      }
      break
    case ActionTypes.sellerAgreementAccepted:
      newState = {
        ...state,
        sellerAgreementAccepted: action.payload.sellerAgreementAccepted,
      }
      break
    case ActionTypes.Balance:
      newState = {
        ...state,
        balance: action.payload.balance,
      }
      break
    case ActionTypes.UpdateTags:
      newState = {
        ...state,
        tags: action.payload.tags,
      }
      break
    case ActionTypes.SetCaptcha:
      newState = {
        ...state,
        captcha: action.payload.captcha,
      }
      break
    default:
      newState = {
        ...state,
      }
      break
  }
  return newState
}

export const UserContext = React.createContext<{
  context: UserType
  dispatch: Dispatch<UserActions>
}>({ context: initialState, dispatch: () => null })
