import { oneBillion, oneMillion, oneThousand } from "./constants"

export function truncateNumber(value: number): string {
  if (value < oneThousand) {
    return value.toString()
  }
  let truncatedValue: string
  if (value >= oneThousand && value < oneMillion) {
    truncatedValue = `${value / oneThousand}K+`
  } else if (value >= oneMillion && value < oneBillion) {
    truncatedValue = `${value / oneMillion}M+`
  } else {
    // Values greater or equal to one Billion
    truncatedValue = `${value / oneBillion}B+`
  }
  return truncatedValue
}

export function parseLocaleNumberShort(number: number): string {
  return number.toLocaleString(window.navigator.language, {
    notation: "compact",
    compactDisplay: "short",
    maximumFractionDigits: 2,
  })
}
