import React, { Dispatch } from "react"

import { ActionMap } from "../utils/types"

export const actionRequiredInitialState: SiteActionRequiredType = {
  actionRequiredNotifications: 0,
}

export type SiteActionRequiredType = {
  actionRequiredNotifications?: number
}

export enum ActionTypes {
  LoadNotificationActionRequiredCount = "LOADACTIONREQUIREDNOTIFICATIONCOUNT",
}

type SiteActionRequiredPayload = {
  [ActionTypes.LoadNotificationActionRequiredCount]: {
    actionRequiredNotifications: number
  }
}

export type SiteActionRequiredActions =
  ActionMap<SiteActionRequiredPayload>[keyof ActionMap<SiteActionRequiredPayload>]

export function actionRequiredReducer(
  state: SiteActionRequiredType = actionRequiredInitialState,
  action: SiteActionRequiredActions
): SiteActionRequiredType {
  let newState: SiteActionRequiredType

  switch (action.type) {
    case ActionTypes.LoadNotificationActionRequiredCount:
      newState = {
        ...state,
        actionRequiredNotifications: action.payload.actionRequiredNotifications,
      }
      break

    default:
      newState = {
        ...state,
      }
      break
  }

  return newState
}

export const SiteActionRequiredContext = React.createContext<{
  siteActionRequiredContext: SiteActionRequiredType
  siteActionRequiredDispatch: Dispatch<SiteActionRequiredActions>
}>({
  siteActionRequiredContext: actionRequiredInitialState,
  siteActionRequiredDispatch: () => null,
})
