import styled from "@emotion/styled"
import { Colors } from "../../styles/Colors"
import {
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  Chip,
} from "@mui/material"
import { ProfileVerified } from "../Atoms/ProfileVerified"
import { IRatingProps, Rating } from "../Atoms/Rating"
import { AccountCircle } from "@mui/icons-material"
import SocialBarWithLinks, {
  ISocialBarWithLinksProps,
} from "../Atoms/SocialBarWithLinks"
import { ContainedSecondaryButton } from "../Atoms/Buttons"
import ArrowForwardFilled from "../assets/Icons/NoColor/ArrowForwardFilled.svg"
import {
  IProfileCardStat,
  ProfileCardStatsDisplay,
} from "../Atoms/ProfileCardStatsDisplay"
import { trackClickProfileEvent } from "../../utils/eventTracking"
import Link from "next/link"
import React, { ReactElement, useMemo } from "react"
import { BlossmImage } from "../Atoms/BlossmImage"

export interface IProfileCardProps {
  image?: string
  name: string
  displayName: string
  verified: boolean
  rating: IRatingProps
  accounts: ISocialBarWithLinksProps
  forceMobile?: boolean
  lowestPrice: number
  stats: IProfileCardStat[]
  tags: string[]
}

export const HorizontalProfileCard = ({
  image,
  name,
  displayName,
  verified,
  rating,
  forceMobile = false,
  lowestPrice,
  stats,
  accounts,
  tags,
}: IProfileCardProps): ReactElement => {
  const theme = useTheme()
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"))
  const isDefaultAvatar = image?.match(/\/images\/avatar_[0-9]\.png/g)
  const displayTags: string[] = useMemo((): string[] => {
    if (!tags) {
      return []
    }

    const getRandomPos = (): number => {
      return Math.floor(Math.random() * tags.length)
    }

    let randomPos: number = getRandomPos()

    if (randomPos === -1) {
      randomPos = getRandomPos()
    }

    if (randomPos === tags.length || randomPos + 4 > tags.length) {
      return tags.slice(-4)
    }

    return tags.slice(randomPos, randomPos + 4)
  }, [tags])

  const logProfileClickEvent = (clickLocation: string): void => {
    trackClickProfileEvent("profile", name, clickLocation)
  }

  return (
    <StyledHorizontalProfileCard theme={theme} forceMobile={forceMobile}>
      <Link
        href={`/${name}`}
        className={"profile-image-container"}
        onClick={() => {
          logProfileClickEvent("image")
        }}
      >
        {image && !isDefaultAvatar ? (
          <BlossmImage alt={"profile image"} src={image} />
        ) : (
          <div className={"avatar-backing"}>
            <AccountCircle
              sx={{
                color: Colors.grays.light,
                width: "80%",
                height: "80%",
              }}
            />
          </div>
        )}
      </Link>
      <div className={"profile-text-display"}>
        <div className={"verification-rating-bar"}>
          <Link
            href={`/${name}`}
            onClick={() => {
              logProfileClickEvent("username")
            }}
          >
            <Typography variant={"subtitle1"} className="profile-text-username">
              {displayName}
            </Typography>
          </Link>
          {verified && <ProfileVerified forceMobile={true} />}
        </div>

        {rating && (
          <Link href={`/review/summary/${name}`}>
            <Rating {...rating} classes={["card-rating"]} precision />
          </Link>
        )}
        <Box
          sx={{
            display: "flex",
            p: 0,
            mt: 0.5,
          }}
        >
          {stats.filter((stat) => stat.label).length >= 1 && (
            <ProfileCardStatsDisplay stats={stats} />
          )}
          {stats.filter((stat) => stat.label).length === 0 && (
            <SocialBarWithLinks links={accounts.links} size={"xs"} useBlack />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            mb: 1,
            p: 0,
            height: 50,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 0.5,
              lineClamp: 2,
              textOverflow: "ellipsis",
            }}
          >
            {displayTags.length > 0 &&
              displayTags.map((tag) => {
                return (
                  <Chip
                    key={tag}
                    label={tag}
                    variant="outlined"
                    size={"small"}
                    sx={{
                      fontWeight: "500",
                      borderColor: theme.palette.primary.main,
                      color: theme.palette.primary.main,
                    }}
                  />
                )
              })}
          </Box>
        </Box>
        <div className={"horizontal-profile-card-footer"}>
          <Link
            href={`/${name}`}
            onClick={() => {
              logProfileClickEvent("button")
            }}
          >
            <ContainedSecondaryButton
              variant={"outlined"}
              size="small"
              sx={{
                ":hover": {
                  boxShadow: theme.shadows[2],
                },
                width: "fit-content",
                height: "fit-content",
              }}
            >
              <Box
                component="span"
                display="flex"
                alignItems={"center"}
                gap={3}
                sx={{
                  width: "100wh",
                }}
              >
                {isMobileView ? "F" : "Offers f"}rom{" "}
                {lowestPrice.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 0,
                })}
                <BlossmImage
                  alt={"go to arrow"}
                  src={ArrowForwardFilled}
                  height={12}
                  width={12}
                />
              </Box>
            </ContainedSecondaryButton>
          </Link>
        </div>
      </div>
    </StyledHorizontalProfileCard>
  )
}

const StyledHorizontalProfileCard = styled.div<{
  theme: Theme
  forceMobile: boolean
}>`
  display: flex;
  overflow: hidden;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  background: ${Colors.white};
  width: 100%;
  max-width: 450px;
  min-height: 200px;
  height: fit-content;

  .profile-image-container {
    cursor: pointer;

    position: relative;
    width: 50%;
    min-width: ${(props) => (props.forceMobile ? "100px" : "187px")};

    ${(props) => props.theme.breakpoints.down("md")} {
      min-width: 100px;
    }

    .avatar-backing {
      background: ${(props) => props.theme.palette.primary.light};
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

    .horizontal-profile-card-footer {
      margin-bottom: auto;
    }

  .profile-text-display {
    padding: 12px;
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    justify-content: space-between;
  }

    .profile-text-username {
      cursor: pointer;
    }

    .verification-rating-bar {
      display: flex;
      gap: 4px;
      align-items: flex-start;
      align-items: center;
    }

  }

  .card-rating {
    cursor: pointer;
  }

  .go-to-btn-icon {
    fill: ${(props) => props.theme.palette.buttonTextPrimary.main};
  }
`
