import React, { Dispatch } from "react"

import { ActionMap } from "../utils/types"
import { INotificationProps } from "../components/Molecules/Notification"

export const notificationsInitialState: SiteNotificationType = {
  siteNotifications: [],
  newSiteNotificationCount: 0,
  siteNotifyMessages: [],
}

export type SiteNotificationType = {
  siteNotifications: INotificationProps[]
  newSiteNotificationCount: number
  siteNotifyMessages: string[]
}

export enum ActionTypes {
  ResetSiteNotification = "RESETSITENOTIFICATION",
  AddSiteNotification = "ADDSITENOTIFICATION",
  LoadSiteNotificationHistory = "UPDATESITENOTIFICATIONS",
  UpdateSiteNotifyMessages = "UPDATESITENOTIFYMESSAGES",
  UpdateSiteNotificationCount = "UPDATESITENOTIFICATIONCOUNT",
  IncrementSiteNotificationCount = "INCREMENTSITENOTIFICATIONCOUNT",
  DecrementSiteNotificationCount = "DECREMENTSITENOTIFICATIONCOUNT",
}

type SiteNotificationsPayload = {
  [ActionTypes.ResetSiteNotification]: {
    siteNotification: null
  }
  [ActionTypes.AddSiteNotification]: {
    siteNotification: INotificationProps
  }
  [ActionTypes.LoadSiteNotificationHistory]: {
    siteNotifications: INotificationProps[]
  }
  [ActionTypes.UpdateSiteNotifyMessages]: {
    siteNotifyMessages: string[]
  }
  [ActionTypes.UpdateSiteNotificationCount]: {
    newSiteNotificationCount: number
  }
  [ActionTypes.IncrementSiteNotificationCount]: {
    newSiteNotificationCount: null
  }
  [ActionTypes.DecrementSiteNotificationCount]: {
    newSiteNotificationCount: null
  }
}

export type SiteNotificationsActions =
  ActionMap<SiteNotificationsPayload>[keyof ActionMap<SiteNotificationsPayload>]

export function notificationsReducer(
  state: SiteNotificationType = notificationsInitialState,
  action: SiteNotificationsActions
): SiteNotificationType {
  let newState: SiteNotificationType

  switch (action.type) {
    case ActionTypes.ResetSiteNotification:
      newState = {
        ...notificationsInitialState,
      }
      break

    case ActionTypes.AddSiteNotification:
      newState = {
        ...state,
        siteNotifications: [
          action.payload.siteNotification,
          ...state.siteNotifications,
        ],
      }
      break

    case ActionTypes.LoadSiteNotificationHistory:
      newState = {
        ...state,
        siteNotifications: action.payload.siteNotifications,
      }
      break

    case ActionTypes.UpdateSiteNotifyMessages:
      newState = {
        ...state,
        siteNotifyMessages: action.payload.siteNotifyMessages,
      }
      break

    case ActionTypes.UpdateSiteNotificationCount:
      newState = {
        ...state,
        newSiteNotificationCount: action.payload.newSiteNotificationCount,
      }
      break

    case ActionTypes.IncrementSiteNotificationCount:
      newState = {
        ...state,
        newSiteNotificationCount: state.newSiteNotificationCount + 1,
      }
      break

    case ActionTypes.DecrementSiteNotificationCount:
      newState = {
        ...state,
        newSiteNotificationCount: state.newSiteNotificationCount - 1,
      }
      break
    default:
      newState = {
        ...state,
      }
      break
  }

  return newState
}

export const SiteNotificationsContext = React.createContext<{
  siteNotificationsContext: SiteNotificationType
  siteNotificationsDispatch: Dispatch<SiteNotificationsActions>
}>({
  siteNotificationsContext: notificationsInitialState,
  siteNotificationsDispatch: () => null,
})
