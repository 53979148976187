import {styled } from "@mui/system"
import {
    Add,
    AddBoxOutlined,
    ChevronLeft, ChevronRight, Close,
    AutoAwesomeOutlined, CalendarTodayOutlined,
    DateRange,
    Edit, EditOutlined, ExpandMore, ExploreOutlined, HelpOutlineOutlined, LocalGroceryStoreOutlined,
    Notes, NotificationsNoneOutlined,
    Photo, Search, SettingsOutlined,
    Star, Logout,
} from "@mui/icons-material"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"

export const DateRangeGreyedOut = styled(DateRange)(({theme}) => ({
    color: theme.palette.text.secondary,
}))

export const StarGreyedOut = styled(Star)(({theme}) => ({
    color: theme.palette.text.secondary,
}))

export const PhotoGreyedOut = styled(Photo)(({theme}) => ({
    color: theme.palette.text.secondary,
}))

export const NotesGreyedOut  = styled(Notes)(({theme}) => ({
    color: theme.palette.text.secondary,
}))

export const EditGrayedOut = styled(Edit)(({theme}) => ({
    color: theme.palette.text.secondary,
}))

export const EditOutlinedGrayedOut = styled(EditOutlined)(({theme}) => ({
    color: theme.palette.text.secondary,
}))

export const AddGreyedOut = styled(Add)(({theme}) => ({
    color: theme.palette.text.secondary,
}))

export const AutoAwesomeOutlinedGreyedOut = styled(AutoAwesomeOutlined)(({theme}) => ({
    color: theme.palette.text.secondary,
}))

export const SearchGreyedOut = styled(Search)(({theme}) => ({
    color: theme.palette.text.secondary,
}))

export const LocalGroceryStoreOutlinedGreyedOut = styled(LocalGroceryStoreOutlined)(({theme}) => ({
    color: theme.palette.text.secondary,
}))

export const CloseGreyedOut = styled(Close)(({theme}) => ({
    color: theme.palette.text.secondary,
}))

export const ExpandMoreGreyedOut = styled(ExpandMore)(({theme}) => ({
    color: theme.palette.text.secondary,
}))

export const ExploreOutlinedGreyedOut = styled(ExploreOutlined)(({theme}) => ({
    color: theme.palette.text.secondary,
}))

export const NotificationsNoneOutlinedGreyedOut = styled(NotificationsNoneOutlined)(({theme}) => ({
    color: theme.palette.text.secondary,
}))

export const CalendarTodayOutlinedGreyedOut = styled(CalendarTodayOutlined)(({theme}) => ({
    color: theme.palette.text.secondary,
}))

export const AddBoxOutlinedGreyedOut = styled(AddBoxOutlined)(({theme}) => ({
    color: theme.palette.text.secondary,
}))

export const SettingsOutlinedGreyedOut = styled(SettingsOutlined)(({theme}) => ({
    color: theme.palette.text.secondary,
}))

export const HelpOutlineOutlinedGreyedOut = styled(HelpOutlineOutlined)(({theme}) => ({
    color: theme.palette.text.secondary,
}))

export const ChevronLeftGreyedOut = styled(ChevronLeft)(({theme}) => ({
    color: theme.palette.text.secondary,
}))

export const ChevronRightGreyedOut = styled(ChevronRight)(({theme}) => ({
    color: theme.palette.text.secondary,
}))

export const LogoutGreyedOut = styled(Logout)(({theme}) => ({
    color: theme.palette.text.secondary,
}))

export const DeleteOutlineGreyedOut = styled(DeleteOutlineIcon)(({theme}) => ({
    color: theme.palette.text.secondary,
}))
