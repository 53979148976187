import styled from "@emotion/styled"
import { Brands, getLogoBlack } from "./Profile/Header/Logo"
import { Typography, useTheme, Theme } from "@mui/material"

export interface IProfileCardStat {
  platform: Brands
  label: string
  url: string
}

export interface IProfileCardStatsDisplayProps {
  stats: IProfileCardStat[]
}

interface IStatRow {
  statDetails: IProfileCardStat
  theme: Theme
}
const StatRow = ({ statDetails, theme }: IStatRow): JSX.Element => (
  <a
    href={statDetails.url}
    target="_blank"
    rel="noopener noreferrer"
    className="social-link"
  >
    <StatBar>
      <img width={16} height={16} src={getLogoBlack(statDetails.platform)} />
      <Typography
        variant={"caption"}
        sx={{ color: theme.palette.text.secondary }}
      >
        {statDetails.label}
      </Typography>
    </StatBar>
  </a>
)

export const ProfileCardStatsDisplay = ({
  stats,
}: IProfileCardStatsDisplayProps): JSX.Element => {
  const otherStats = stats.filter((stat) => stat.platform !== "OnlyFans")
  const onlyFansStats = stats.filter((stat) => stat.platform === "OnlyFans")
  const maxStatsLength = 2
  const theme = useTheme()
  return (
    <StyledProfileCardStatsDisplay>
      {onlyFansStats.length > 1 && (
        <>
          <StatRow
            statDetails={onlyFansStats[0]}
            theme={theme}
            key={onlyFansStats[0].url}
          />
          <LowerStatBar>
            <StatRow
              statDetails={onlyFansStats[1]}
              theme={theme}
              key={onlyFansStats[1].url}
            />
          </LowerStatBar>
        </>
      )}
      {onlyFansStats.length === 1 && (
        <>
          <StatRow
            statDetails={onlyFansStats[0]}
            theme={theme}
            key={onlyFansStats[0].url}
          />
          <LowerStatBar>
            {otherStats.splice(0, maxStatsLength).map((stat) => {
              return <StatRow statDetails={stat} theme={theme} key={stat.url} />
            })}
          </LowerStatBar>
        </>
      )}
      {onlyFansStats.length === 0 && otherStats.length > 0 && (
        <>
          <StatBar>
            {otherStats.splice(0, maxStatsLength).map((stat) => {
              return <StatRow statDetails={stat} theme={theme} key={stat.url} />
            })}
          </StatBar>
          <LowerStatBar>
            {otherStats.splice(0, maxStatsLength).map((stat) => {
              return <StatRow statDetails={stat} theme={theme} key={stat.url} />
            })}
          </LowerStatBar>
        </>
      )}
    </StyledProfileCardStatsDisplay>
  )
}

const LowerStatBar = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

const StatBar = styled.div`
  display: flex;
  gap: 6px;
  width: fit-content;
`

const StyledProfileCardStatsDisplay = styled.div`
  display: flex;
  flex-direction: column;

  .social-link {
    width: fit-content;
  }
`
