import { getCookieOrEmptyString } from "../utils/requests"

interface IRequestResult {
  ok: boolean
  status: number
  statusText: string
  json: <T>() => T
  headers: string
}

export interface IMediaUploaderResult {
  setUpload: (url: URL, formData: FormData) => Promise<void>
  abort: () => void
  done: (callback: (resp: IRequestResult) => void) => void
  onProgress: (callback: (percentage: number) => void) => void
}

export default (): IMediaUploaderResult => {
  const xhr = new XMLHttpRequest()

  const doRequest = async (url: URL, formData: FormData): Promise<void> => {
    xhr.open("POST", url, true)
    xhr.withCredentials = true
    xhr.responseType = "json"
    xhr.setRequestHeader("X-CSRFToken", getCookieOrEmptyString("csrftoken"))
    // eslint-disable-next-line
    xhr.upload.addEventListener("progress", () => {})
    xhr.send(formData)
  }

  const abort = (): void => {
    xhr.abort()
  }

  const parseXHRResult = (ok: boolean): IRequestResult => {
    return {
      ok: ok,
      status: xhr.status,
      statusText: xhr.statusText,
      headers: xhr.getAllResponseHeaders(),
      json: <T>() => xhr.response as T,
    }
  }

  const done = (callback: (resp: IRequestResult) => void): void => {
    xhr.onload = (): void => {
      callback(parseXHRResult(xhr.status >= 200 && xhr.status < 300))
    }

    xhr.onerror = (): void => {
      callback(parseXHRResult(false))
    }
  }

  const onProgress = (callback: (percentage: number) => void): void => {
    xhr.upload.addEventListener("progress", (event) => {
      if (event.lengthComputable) {
        callback(Math.floor((event.loaded / event.total) * 100))
      }
    })
  }

  const setUpload = async (url: URL, formData: FormData): Promise<void> => {
    if (!url && !formData) {
      return
    }

    await doRequest(url, formData)
  }

  return {
    setUpload,
    abort,
    done,
    onProgress,
  }
}
