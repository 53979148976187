import { Dialog } from "@mui/material"
import Typography from "@mui/material/Typography"
import styled from "@emotion/styled"
import { ErrorTextButton, InverseButton } from "../../Atoms/Buttons"
export interface IModalLogoutProps {
  open: boolean
  title: string
  text: string
  handleClose?: () => void
  onClose?: () => void
  onHandle?: () => void
}

export const ModalLogout = ({
  open,
  title,
  text,
  onHandle,
  onClose,
  handleClose,
}: IModalLogoutProps): JSX.Element => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <WrapperInfo>
        <Typography id="modal-modal-title" variant="h6" fontSize={20}>
          {title}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {text}
        </Typography>
        <ContainerActions>
          <InverseButton
            variant={"text"}
            sx={{ mt: 2, mr: 1 }}
            onClick={onClose}
          >
            Cancel
          </InverseButton>
          <ErrorTextButton variant={"text"} sx={{ mt: 2 }} onClick={onHandle}>
            Log Out
          </ErrorTextButton>
        </ContainerActions>
      </WrapperInfo>
    </Dialog>
  )
}

const WrapperInfo = styled.div`
  width: 328px;
  height: 145px;
  padding: 10px;
  @media (max-width: 400px) {
    width: auto;
    height: auto;
  }
`

const ContainerActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 18px;
  align-items: flex-end;

  .text-button {
    width: 63px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-button:hover {
    background-color: #e4e4e47e;
    border-radius: 3px;
  }
`
