import { PhotoOutlined } from "@mui/icons-material"
import { Box, Link, useMediaQuery, useTheme } from "@mui/material"
import { Dispatch, SetStateAction, useEffect, useMemo } from "react"
import {
  ACCEPTED_IMAGE_FORMATS,
  ACCEPTED_VIDEO_FORMATS,
  CHAT_MEDIA_MAX_FILES,
} from "../../../utils/constants"
import { DropZone } from "../DropZone"
import { LoadingSpinner } from "../LoadingSpinner"
import { AddMedia, IOrderPromoPhoto } from "../Promo/AddMedia"

export interface IChatMediaProps {
  open: boolean
  setMedia: (mediaIds: number[]) => void
  openModal: boolean
  setModalOpen: (open: boolean) => void
  setOpenAddMedia: (open: boolean) => void
  onAddMediaIconClicked: () => void
  media: number[]
  selectedMedia: IOrderPromoPhoto[]
  setSelectedMedia: Dispatch<SetStateAction<IOrderPromoPhoto[]>>
  isUploading: boolean
  setIsUploading: Dispatch<SetStateAction<boolean>>
  uploadMedia: (media: File[]) => Promise<void>
}

export const ChatMedia = (props: IChatMediaProps): JSX.Element => {
  const theme = useTheme()
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"))

  const media = useMemo(() => props.media, [props.media])
  const selectedMedia = useMemo(
    () => props.selectedMedia,
    [props.selectedMedia]
  )
  const openModal = useMemo(() => props.openModal, [props.openModal])
  const isOpen = useMemo(() => props.open, [props.open])
  const isUploading = useMemo(() => props.isUploading, [props.isUploading])

  useEffect(() => {
    if (media?.length === 0) {
      props.setSelectedMedia([])
    }
  }, [media])

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        boxShadow: "0 -2px 2px 0 rgb(0, 0, 0, 0.1)",
        height: "fit-content",
        display: "block",
      }}
    >
      {media?.length !== CHAT_MEDIA_MAX_FILES && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isUploading && <LoadingSpinner size={isMobileView ? 75 : 116} />}
          {!isMobileView && !isUploading && (
            <DropZone
              setMedia={props.uploadMedia}
              maxFiles={CHAT_MEDIA_MAX_FILES - media.length}
              accept={Object.assign(
                {},
                ACCEPTED_VIDEO_FORMATS,
                ACCEPTED_IMAGE_FORMATS
              )}
              useDefaultBehavior={false}
            >
              <Link
                onClick={props.onAddMediaIconClicked}
                alignSelf={"center"}
                sx={{
                  textDecoration: "none",
                  fontSize: "15px",
                  fontWeight: 400,
                  lineHeight: "26px",
                  display: "flex",
                  alignContent: "center",
                  gap: "10px",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <PhotoOutlined height={15} width={15} className={"action"} />
                Drop or Add Media Files
              </Link>
            </DropZone>
          )}
        </Box>
      )}

      {(isOpen || selectedMedia.length > 0) && (
        <Box
          sx={{
            padding: "4px 8px",
            height: "fit-content",
          }}
        >
          <AddMedia
            headless={true}
            set={(media) => {
              props.setMedia(media.map((value) => value.id))
            }}
            openModal={openModal}
            setModalOpen={props.setModalOpen}
            uploadLocation={"chat"}
            media={selectedMedia}
            isPopUpOpen={!selectedMedia.length}
          />
        </Box>
      )}
    </Box>
  )
}
