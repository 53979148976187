import { useTheme } from "@mui/material"
import axios from "axios"
import { IPhoto } from "../utils/photos"

export interface IDownloadResourceResult {
  download: (files: IPhoto[]) => Promise<boolean | NodeJS.Timeout>
}

export default (): IDownloadResourceResult => {
  const theme = useTheme()
  const accepted_media = ".jpg, .jpeg, .png, .gif, .mp4"

  const extractFileExtension = (url: string): string => {
    // Regex to get file extension immediately before first query string question mark.
    const regex = /(?:\.([^./?]+))(\?.*)?$/
    const match = url.match(regex)
    if (
      match &&
      match[1] &&
      accepted_media.indexOf(match[1].toLowerCase()) !== 0
    ) {
      return match[1].toLowerCase()
    } else {
      console.error("File type error")
      throw new Error(
        "Download error, please refresh the page and try again. If this persists please contact customer support."
      )
    }
  }

  const getResource = async (file: string): Promise<Blob> => {
    const res = await axios.get(file, {
      responseType: "blob",
    })

    return res.data
  }

  const delay = (milliseconds: number): Promise<NodeJS.Timeout> => {
    return new Promise((resolve) => {
      setTimeout(resolve, milliseconds)
    })
  }

  const handleFileDownload = (blob: Blob, filename: string): NodeJS.Timeout => {
    const blobURL = window.URL?.createObjectURL
      ? window.URL.createObjectURL(blob)
      : window.webkitURL.createObjectURL(blob)

    const temp = document.createElement("a")

    temp.style.display = "none"
    temp.href = blobURL

    // eslint-disable-next-line
    temp.setAttribute("download", filename)

    if (typeof temp.download === "undefined") {
      // eslint-disable-next-line
      temp.setAttribute("target", "_blank")
    }

    document.body.appendChild(temp)
    temp.click()

    return setTimeout(() => {
      document.body.removeChild(temp)
      window.URL.revokeObjectURL(blobURL)
    }, 200)
  }

  const download = async (
    files: IPhoto[]
  ): Promise<boolean | NodeJS.Timeout> => {
    if (!files) {
      throw new Error("File(s) are required!")
    }

    if (files.length === 1) {
      const blob = new Blob([await getResource(files[0].originalUrl)], {
        type: "application/octet-stream",
      })
      const ext: string = extractFileExtension(files[0].originalUrl)

      return handleFileDownload(blob, `blossm_file[0].${ext}`)
    } else {
      files
        .map(async (file) => await getResource(file.originalUrl))
        .map(async (blobResp, idx) => {
          const blob = new Blob([await blobResp], {
            type: "application/octet-stream",
          })
          const ext: string = extractFileExtension(files[idx].originalUrl)

          if (theme.breakpoints.down("sm")) {
            await delay(idx * 1000) // IOS didn't like that the download button is being pressed more than one-time a second.
          }

          return handleFileDownload(blob, `blossm_file[${idx}].${ext}`)
        })
    }

    return true
  }

  return {
    download,
  }
}
