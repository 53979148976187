import { alpha, Button, IconButton } from "@mui/material"
import { styled } from "@mui/system"
import { Colors } from "../../styles/Colors"
import { LoadingButton } from "@mui/lab"

export const PrimaryButton = styled(Button)(({ theme }) => ({
  borderRadius: 24,
  backgroundColor: theme.palette.primary.main,
  color: Colors.white,
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.4),
  },
  "&:disabled": {
    color: alpha(Colors.white, 0.7),
    backgroundColor: alpha(theme.palette.primary.main, 0.7),
  },
}))

export const SecondaryButton = styled(Button)(({ theme }) => ({
  borderRadius: 24,
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.text.primary,
  "&:hover": {
    backgroundColor: alpha(theme.palette.secondary.light, 0.4),
  },
  "&:disabled": {
    color: alpha(theme.palette.text.primary, 0.4),
    backgroundColor: alpha(theme.palette.secondary.light, 0.7),
  },
}))

export const SecondaryLoadingButton = styled(LoadingButton)(({ theme }) => ({
  borderRadius: 24,
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.text.primary,
  "&:hover": {
    backgroundColor: alpha(theme.palette.secondary.light, 0.4),
  },
  "&:disabled": {
    color: alpha(theme.palette.text.primary, 0.4),
    backgroundColor: alpha(theme.palette.secondary.light, 0.7),
  },
}))

export const ErrorButton = styled(Button)(({ theme }) => ({
  borderRadius: 24,
  backgroundColor: theme.palette.error.light,
  color: Colors.white,
  "&:hover": {
    backgroundColor: alpha(theme.palette.error.light, 0.4),
  },
  "&:disabled": {
    color: alpha(Colors.white, 0.5),
    backgroundColor: alpha(theme.palette.error.light, 0.7),
  },
}))

export const InverseButton = styled(Button)(({ theme }) => ({
  borderRadius: 24,
  backgroundColor: Colors.white,
  color: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: alpha(Colors.black, 0.1),
  },
}))

export const WhiteTextButton = styled(Button)(({ theme }) => ({
  borderRadius: 24,
  color: Colors.white,
  border: "none",
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.light, 0.1),
    border: "none",
  },
}))

export const BorderedWhiteTextButton = styled(Button)(({ theme }) => ({
  borderRadius: 24,
  color: Colors.white,
  borderColor: Colors.white,
  "&:disabled": {
    backgroundColor: alpha(Colors.white, 0.1),
    borderColor: alpha(Colors.white, 0.5),
    color: alpha(Colors.white, 0.5),
  },
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.light, 0.1),
    borderColor: alpha(theme.palette.primary.light, 0.1),
  },
}))

export const SecondaryTextButton = styled(Button)(({ theme }) => ({
  borderRadius: 24,
  color: theme.palette.secondary.light,
  border: "none",
  "&:hover": {
    backgroundColor: alpha(Colors.black, 0.1),
    border: "none",
  },
}))

export const ErrorTextButton = styled(Button)(({ theme }) => ({
  borderRadius: 24,
  color: theme.palette.error.light,
  border: "none",
  "&:hover": {
    backgroundColor: alpha(theme.palette.error.light, 0.1),
    border: "none",
  },
}))

export const ErrorTextButtonOutlined = styled(Button)(({ theme }) => ({
  borderRadius: 24,
  color: theme.palette.error.light,
  borderColor: theme.palette.error.light,
  "&:hover": {
    backgroundColor: alpha(theme.palette.error.light, 0.1),
    borderColor: theme.palette.error.light,
  },
}))

export const ContainedSecondaryButton = styled(Button)(({ theme }) => ({
  borderRadius: 24,
  backgroundColor: Colors.white,
  color: theme.palette.text.primary,
  borderColor: Colors.black,
  "&:hover": {
    backgroundColor: theme.palette.secondary.light,
    border: "none",
    margin: "0.8px",
  },
}))

export const CustomIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: Colors.white,
  width: "fit-content",
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.4),
  },
  "&:disabled": {
    color: alpha(Colors.white, 0.7),
    backgroundColor: alpha(theme.palette.primary.main, 0.7),
  },
}))

export const ContainedAddButton = styled(Button)(({ theme }) => ({
  height: "auto",
  width: "100%",
  backgroundColor: Colors.white,
  borderRadius: "1.5rem",
  borderColor: theme.palette.error.light,
}))
