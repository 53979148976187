import styled from "@emotion/styled"
import { Theme, useTheme } from "@mui/material"
import {
  Inventory2Outlined,
  ExploreOutlined,
  MailOutlined,
  NotificationsNone,
} from "@mui/icons-material"
import React, { useContext } from "react"
import Link from "next/link"
import { Colors } from "../../styles/Colors"
import Badge from "@mui/material/Badge"
import {
  SiteNotificationsContext,
  SiteNotificationType,
} from "../../contexts/siteNotificationContext"
import { UserContext } from "../../contexts/user"
import { useRouter } from "next/router"
import { Avatar } from "./Avatar"
import {
  SiteActionRequiredContext,
  SiteActionRequiredType,
} from "../../contexts/siteActionRequiredContext"
import { MessageContext, MessageType } from "../../contexts/websocket"

export interface IMobileNavProps {
  location: string
  menuOpen?: boolean
  profileImage?: string
  openMenu: () => void
}

interface IStandardNavItems {
  icon: JSX.Element
  location: string
}

function getStandardNavItems(
  notificationsContext: SiteNotificationType,
  actionRequiredContext: SiteActionRequiredType,
  MessageContext: MessageType
): IStandardNavItems[] {
  return [
    {
      icon: <ExploreOutlined />,
      location: "/",
    },
    {
      icon: (
        <Badge
          badgeContent={notificationsContext.newSiteNotificationCount}
          color="primary"
        >
          <NotificationsNone />
        </Badge>
      ),
      location: "/notifications",
    },
    {
      icon: (
        <Badge badgeContent={MessageContext.unreadMessageCount} color="primary">
          <MailOutlined />
        </Badge>
      ),
      location: "/messages",
    },
    {
      icon: (
        <Badge
          badgeContent={
            actionRequiredContext.actionRequiredNotifications
              ? actionRequiredContext.actionRequiredNotifications
              : 0
          }
          color="primary"
        >
          <Inventory2Outlined />
        </Badge>
      ),
      location: "/orders",
    },
  ]
}

export const MobileNav = ({
  location,
  menuOpen,
  openMenu,
}: IMobileNavProps): JSX.Element => {
  const theme = useTheme()
  const { context } = useContext(UserContext)
  const { siteNotificationsContext } = useContext(SiteNotificationsContext)
  const { siteActionRequiredContext } = useContext(SiteActionRequiredContext)
  const { messageContext } = useContext(MessageContext)
  const router = useRouter()

  const standardNavItems = getStandardNavItems(
    siteNotificationsContext,
    siteActionRequiredContext,
    messageContext
  )

  return (
    <StyledMobileNav>
      {standardNavItems.map((item, index) => {
        return (
          <Link href={item.location} key={index}>
            <StyledIcon theme={theme} selected={location === item.location}>
              {item.icon}
            </StyledIcon>
          </Link>
        )
      })}
      {context.isAuthenticated && (
        <StyledIcon
          theme={theme}
          selected={menuOpen}
          onClick={
            context.profileName === ""
              ? () => router.push("/login")
              : () => openMenu()
          }
        >
          <StyledImageContainer>
            <Avatar
              online={false}
              profileImage={context.avatarUrl}
              size={"25"}
            />
          </StyledImageContainer>
        </StyledIcon>
      )}
    </StyledMobileNav>
  )
}

const StyledImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 10%;
  height: 25px;
  width: 25px;

  > div {
    position: unset !important;
  }
`

const StyledIcon = styled.div<{ selected?: boolean; theme: Theme }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) =>
    props.selected
      ? props.theme.palette.primary.main
      : props.theme.palette.text.secondary};
  padding-bottom: 4px;
`

const StyledMobileNav = styled.div`
  height: fit-content;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  padding: 16px;
  background: ${Colors.white};
  z-index: 1000;
`
