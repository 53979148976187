import axios, { AxiosRequestConfig, AxiosResponse } from "axios"
import { GetServerSidePropsContext } from "next"
import Router from "next/router"
import { getIpAddress } from "./ipAddress"

const windowUrl = process.browser ? window.location.origin : getBackendUrl()

export const baseUrl =
  process.env.NODE_ENV === "development"
    ? process.env.NEXT_PUBLIC_SERVER_URL
    : windowUrl

export function getBackendUrl(): string {
  let backendUrl
  if (process.env.NEXTJS_DJANGO_SERVER_URL) {
    backendUrl = process.env.NEXTJS_DJANGO_SERVER_URL
  } else {
    backendUrl = "http://django:8024"
  }
  return backendUrl
}

function getBaseConfig<D>(): AxiosRequestConfig<D> {
  if (process.browser) {
    return {
      withCredentials: true,
      baseURL: baseUrl,
      timeout: 60000,
      headers: {
        "X-CSRFToken": getCookieOrEmptyString("csrftoken"),
      },
    }
  } else {
    return {
      withCredentials: true,
      baseURL: getBackendUrl(),
      timeout: 60000,
    }
  }
}

export function getCookieOrEmptyString(name: string): string {
  const nameLenPlus = name.length + 1
  if (process.browser) {
    return document.cookie
      .split(";")
      .map((c) => c.trim())
      .filter((cookie) => {
        return cookie.substring(0, nameLenPlus) === `${name}=`
      })
      .map((cookie) => {
        return decodeURIComponent(cookie.substring(nameLenPlus))
      })[0]
  } else {
    return ""
  }
}

// Wraps request functions to handle redirecting banned users
function banCheck<R>(promise: Promise<R>): Promise<R> {
  return new Promise<R>((resolve, reject) => {
    promise
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        if (
          axios.isAxiosError(error) &&
          error.response?.data === "banned" &&
          Router.pathname !== "/banned"
        ) {
          Router.push("banned")
        }
        reject(error)
      })
  })
}

// Generic request functions that use the CSRF tokens from Django.
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export async function get<T = any, R = AxiosResponse<T>, D = any>(
  path: string,
  data?: D,
  config?: AxiosRequestConfig<D>,
  retryCount = 0
): Promise<R> {
  const retryLimit = 3
  const defaultErrorResponse = (): Promise<R> => {
    return banCheck(
      new Promise((resolve, reject) => {
        reject({
          config: {
            ...getBaseConfig(),
            ...config,
            ...{ params: data },
          },
          code: 408, // Request timeout
          isAxiosError: true,
        })
      })
    )
  }

  if (retryCount >= retryLimit) {
    return defaultErrorResponse()
  }

  let response: Awaited<R>
  try {
    response = await axios.get<T, R, D>(path, {
      ...getBaseConfig(),
      ...config,
      ...{ params: data },
    })
    return banCheck(new Promise((resolve) => resolve(response)))
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  } catch (error: AxiosError) {
    if (
      error.code === "ECONNABORTED" ||
      error.response.status === 429 ||
      (error.response.status >= 500 && error.response.status <= 599)
    ) {
      console.warn("Network issues detected. Trying again...")
      setTimeout(
        () => get(path, data, config, retryCount + 1),
        Math.exp(retryCount) * 1000
      )
    } else {
      // Anything other than the statuses listed above: don't retry
      return banCheck(new Promise((resolve, reject) => reject(error)))
    }
  }

  return defaultErrorResponse()
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export function post<T = any, R = AxiosResponse<T>, D = any>(
  path: string,
  data?: D,
  config?: AxiosRequestConfig<D>
): Promise<R> {
  const parsedUrl = new URL(path, baseUrl ?? window.location.origin)
  return banCheck(
    axios.post<T, R, D>(parsedUrl.href, data, {
      ...getBaseConfig(),
      ...config,
    })
  )
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export function put<T = any, R = AxiosResponse<T>, D = any>(
  path: string,
  data?: D,
  config?: AxiosRequestConfig<D>
): Promise<R> {
  const parsedUrl = new URL(path, baseUrl ?? window.location.origin)
  return banCheck(
    axios.put<T, R, D>(parsedUrl.href, data, {
      ...getBaseConfig(),
      ...config,
    })
  )
}

// Renamed as "delete" is a TS keyword
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export function deleteRequest<T = any, R = AxiosResponse<T>, D = any>(
  path: string,
  config?: AxiosRequestConfig<D>
): Promise<R> {
  const parsedUrl = new URL(path, baseUrl ?? window.location.origin)
  return banCheck(
    axios.delete<T, R, D>(parsedUrl.href, { ...getBaseConfig(), ...config })
  )
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export function head<T = any, R = AxiosResponse<T>, D = any>(
  path: string,
  config?: AxiosRequestConfig<D>
): Promise<R> {
  const parsedUrl = new URL(path, baseUrl ?? window.location.origin)
  return banCheck(
    axios.head<T, R, D>(parsedUrl.href, { ...getBaseConfig(), ...config })
  )
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export function options<T = any, R = AxiosResponse<T>, D = any>(
  path: string,
  config?: AxiosRequestConfig<D>
): Promise<R> {
  const parsedUrl = new URL(path, baseUrl ?? window.location.origin)
  return banCheck(
    axios.options<T, R, D>(parsedUrl.href, {
      ...getBaseConfig(),
      ...config,
    })
  )
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export function patch<T = any, R = AxiosResponse<T>, D = any>(
  path: string,
  data?: D,
  config?: AxiosRequestConfig<D>
): Promise<R> {
  const parsedUrl = new URL(path, baseUrl ?? window.location.origin)
  return banCheck(
    axios.patch<T, R, D>(parsedUrl.href, data, {
      ...getBaseConfig(),
      ...config,
    })
  )
}

// More speicific request types
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export function login<T = any, R = AxiosResponse<T>>(
  loginData: ILoginData
): Promise<R> {
  const parsedUrl = new URL("auth/login/", baseUrl ?? window.location.origin)
  return axios.post<T, R>(parsedUrl.href, loginData, getBaseConfig())
}

// More speicific request types
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export function logout<T = any, R = AxiosResponse<T>>(): Promise<R> {
  const parsedUrl = new URL("/auth/logout/", baseUrl ?? window.location.origin)
  const request = axios.post<T, R>(parsedUrl.href, {}, getBaseConfig())
  request.then(() => {
    window.sessionStorage.clear()
  })
  return request
}

// Interfaces for data types
export interface ILoginData {
  email: string
  password: string
  tfa_code?: string
  captcha?: string
  use_saved_browser?: boolean
}

export interface ILoginResponse {
  email: string
  username: string
  avatarUrl: string
  theme: "light" | "dark"
}

export function createSsrRequestConfig(
  context: GetServerSidePropsContext
): AxiosRequestConfig {
  const ip = getIpAddress(context)
  const headers = {
    ...context.req.headers,
    "X-Real-Ip": ip,
    "X-Forwarded-For": ip,
    "Host": context.req.headers["x-forwarded-server"],
  }
  return {
    headers: headers,
    "X-CSRFToken": context.req.cookies["csrftoken"],
    "X-Forwarded-Proto": "https",
  } as AxiosRequestConfig
}
