import { Theme, Tooltip, Typography, useTheme } from "@mui/material"
import { Check } from "@mui/icons-material"
import styled from "@emotion/styled"
import { Colors } from "../../styles/Colors"

export interface IProfileVerifiedProps {
  forceMobile?: boolean
}

export const ProfileVerified = ({
  forceMobile = true,
}: IProfileVerifiedProps): JSX.Element => {
  const theme = useTheme()
  return (
    <Tooltip title={"Blossm-verified creator"}>
      <StyledProfileVerified theme={theme} forceMobile={forceMobile}>
        <Check sx={{ fontSize: 13, color: Colors.white }} />
        {!forceMobile && (
          <Typography
            sx={{ display: { xs: "none", s: "none", md: "flex" } }}
            variant={"badge"}
            color={Colors.white}
            noWrap
          >
            Verified
          </Typography>
        )}
      </StyledProfileVerified>
    </Tooltip>
  )
}

const StyledProfileVerified = styled.div<{
  theme: Theme
  forceMobile: boolean
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => (props.forceMobile ? "2px" : "0px 8px 0px 6px")};
  ${(props) => props.theme.breakpoints.down("md")} {
    padding: 2px;
  }

  gap: 2px;
  border-radius: 64px;

  background-color: ${(props) => props.theme.palette.primary.main};
  width: fit-content;
`
