import { Dispatch } from "react"
import { AxiosError } from "axios"
import { createErrorMessageList, AxiosErrorResponseData } from "./errors"
import { ErrorActions, ErrorActionTypes } from "../contexts/photoUploadErrors"

export enum ProfileUpdate {
  Profile = "profile",
  Avatar = "avatar",
  Media = "media",
  Tags = "tags",
}

export function modalDisplayErrorHandler(
  status: number,
  errorDispatch: Dispatch<ErrorActions>,
  error: AxiosError<AxiosErrorResponseData>,
  updateType: ProfileUpdate,
  buttonClickCallback?: () => void
): void {
  if (error !== undefined && error.response !== undefined) {
    if (error.response.status === 429) {
      errorDispatch({
        type: ErrorActionTypes.Enque,
        payload: {
          title: `Error Updating ${
            updateType.charAt(0).toUpperCase() + updateType.slice(1)
          }`,
          body: createErrorMessageList(
            `You've changed your ${updateType} too many times lately. Try again later.`
          ),
          affirmativeButtonText: "Ok",
          affirmativeOnClick:
            buttonClickCallback !== undefined
              ? () => buttonClickCallback()
              : undefined,
        },
      })
    } else {
      errorDispatch({
        type: ErrorActionTypes.Enque,
        payload: {
          title: `Error Updating ${
            updateType.charAt(0).toUpperCase() + updateType.slice(1)
          }`,
          body: createErrorMessageList(error?.response?.data),
          affirmativeButtonText: "Ok",
          affirmativeOnClick:
            buttonClickCallback !== undefined
              ? () => buttonClickCallback()
              : undefined,
        },
      })
    }
  } else {
    errorDispatch({
      type: ErrorActionTypes.Enque,
      payload: {
        title: `Error Updating ${
          updateType.charAt(0).toUpperCase() + updateType.slice(1)
        }`,
        body: createErrorMessageList(
          `There was an error while updating your ${updateType}. Please check your network connection and try again.`
        ),
        affirmativeButtonText: "Ok",
        affirmativeOnClick:
          buttonClickCallback !== undefined
            ? () => buttonClickCallback()
            : undefined,
      },
    })
  }
}
