import { StyledSocialBar } from "./styledSocialBar"
import { ISocialMediaLink } from "../../utils/parsers"
import { getLogo, getLogoBlack } from "./Profile/Header/Logo"

export interface ISocialBarWithLinksProps {
  links: ISocialMediaLink[]
  size?: "xs" | "Small" | "Large"
  useBlack?: boolean
}

const SocialBarWithLinks = ({
  links,
  size,
  useBlack = false,
}: ISocialBarWithLinksProps): JSX.Element => {
  return (
    <StyledSocialBar size={size}>
      {links?.map((link, index) => {
        return (
          <div className="social-item" key={index}>
            <a href={link.url} target="_blank">
              <img
                className="icon"
                src={
                  useBlack
                    ? getLogoBlack(link.platformName)
                    : getLogo(link.platformName, false)
                }
              />
            </a>
          </div>
        )
      })}
    </StyledSocialBar>
  )
}

export default SocialBarWithLinks
