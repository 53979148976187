import { IProfileCardProps } from "../components/Organisms/HorizontalProfileCard"
import { ActionMap } from "../utils/types"
import React, { Dispatch } from "react"

export const pagingInitialState: PagingStateType = {
  currentPage: 1,
  pageSize: 0,
  profileCards: [],
  numPages: 1,
  isMobile: false,
}

export type PagingStateType = {
  currentPage: number
  pageSize: number
  profileCards: IProfileCardProps[]
  numPages: number
  isMobile: boolean
}

export enum PagingAction {
  UpdateCards = "Update Cards",
  UpdateCurrentPage = "UpdateCurrentPage",
  SetMobile = "Set Mobile",
}

type PagingPayload = {
  [PagingAction.UpdateCards]: {
    profileCards: IProfileCardProps[]
    pageSize: number
    numPages: number
    currentPage: number
    replaceCards?: boolean
  }
  [PagingAction.UpdateCurrentPage]: {
    nextPage: number
  }
  [PagingAction.SetMobile]: boolean
}

export type PagingActions =
  ActionMap<PagingPayload>[keyof ActionMap<PagingPayload>]

export function pagingReducer(
  state: PagingStateType = pagingInitialState,
  action: PagingActions
): PagingStateType {
  let newState
  let currentStateItem
  switch (action.type) {
    case PagingAction.SetMobile:
      newState = {
        ...state,
        isMobile: action.payload,
      }
      break
    case PagingAction.UpdateCards:
      if (state.isMobile && !action.payload.replaceCards) {
        currentStateItem = [
          ...state.profileCards,
          ...action.payload.profileCards,
        ]
      } else {
        currentStateItem = action.payload.profileCards
      }
      newState = {
        ...state,
        profileCards: currentStateItem,
        pageSize: action.payload.pageSize,
        numPages: action.payload.numPages,
        currentPage: action.payload.currentPage,
      }
      break
    case PagingAction.UpdateCurrentPage:
      newState = {
        ...state,
        currentPage: action.payload.nextPage,
      }
      break
    default:
      newState = {
        ...state,
      }
      break
  }
  return newState
}

export const PagingContext = React.createContext<{
  pagingContext: PagingStateType
  pagingDispatch: Dispatch<PagingActions>
}>({
  pagingContext: pagingInitialState,
  pagingDispatch: () => null,
})
