import {
  IPromoRequestSubmitData,
  IRequestData,
} from "../components/Molecules/Promo/OrderPromo"
import { DateRange } from "@mui/lab"
import {
  addDays,
  format,
  getHours,
  isAfter,
  isBefore,
  isEqual,
  isWithinInterval,
  parseISO,
  startOfDay,
} from "date-fns"

/**
 * # Python and Javascript have different "weekday" number ranges so we use ISO8601 to be consistent
 * @param date: Date
 */

export function getWeekdayISO8601(date: Date): number {
  const day = date.getDay()
  return day === 0 ? 7 : day
}

export function getMonthDayYearNumericDateString(
  nextAvailableDateString: string
): string {
  const dateOptions = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    weekday: "short",
    timeZone: "UTC",
  } as Intl.DateTimeFormatOptions
  return new Date(nextAvailableDateString).toLocaleDateString(
    "en-US",
    dateOptions
  )
}

export function getNextAvailableDateLabel(
  nextAvailableDateString: string,
  dateOptions: Intl.DateTimeFormatOptions = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
  }
): "Today" | "Tomorrow" | string {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const nextAvailableDate = new Date(nextAvailableDateString)
  nextAvailableDate.setHours(0, 0, 0, 0)
  const tomorrowDate = new Date()
  tomorrowDate.setDate(today.getDate() + 1)
  tomorrowDate.setHours(0, 0, 0, 0)
  // In the future, when we have language localization, we'll swap this out with a translation
  if (
    !isNaN(nextAvailableDate.valueOf()) &&
    !isNaN(today.valueOf()) &&
    nextAvailableDate.toISOString() === today.toISOString()
  ) {
    return "Today"
  } else if (
    !isNaN(nextAvailableDate.valueOf()) &&
    !isNaN(tomorrowDate.valueOf()) &&
    nextAvailableDate.toISOString() === tomorrowDate.toISOString()
  ) {
    return "Tomorrow"
  } else {
    return nextAvailableDate.toLocaleDateString("en-US", dateOptions)
  }
}

export const getNameDaysWeekly = (arrayDays: Array<number>): Array<string> => {
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ]
  const daysAvailables: Array<string> = []
  if (arrayDays.length === 7) {
    return ["Any Day"]
  }
  arrayDays.forEach((day) => {
    daysAvailables.push(` ${days[day - 1]}`)
  })
  return daysAvailables
}

/**
 * Coerce time zone of date passed in to the time zone this user's browser. Our date picker is time zone agnostic (eg:
 * no time zone info available to the user in the UI), so we need this step to normalize dates across time zones.
 * @param dateString
 */
export function coerceDateStringToClientTimeZone(dateString: string): Date {
  const today = new Date()
  const inputDate = new Date(dateString)
  //there's a chance of a timezone shift by the time of the future promo
  const timeZoneHoursOffset = Math.floor(inputDate.getTimezoneOffset() / 60)
  let timezoneOffsetString: string
  if (Math.abs(timeZoneHoursOffset) < 10) {
    timezoneOffsetString = `0${Math.abs(timeZoneHoursOffset)}:00`
  } else {
    timezoneOffsetString = `${Math.floor(
      Math.abs(today.getTimezoneOffset()) / 60
    )}:00`
  }
  const timeZonePlusMinus = timeZoneHoursOffset > 0 ? "-" : "+"
  return new Date(
    `${dateString.split("+")[0]}${timeZonePlusMinus}${timezoneOffsetString}`
  )
}

export function removeTimeZoneFromPromoRequest(
  requestData: IRequestData
): IPromoRequestSubmitData {
  // We treat a day the same everywhere on the planet so we need to remove time zone info so browsers don't try to
  // adjust timezones when sending the data.
  if (requestData.promo_time !== undefined) {
    const month = requestData.promo_time.getMonth() + 1
    return {
      ...requestData,
      promo_time: `${requestData.promo_time.getFullYear()}-${
        month < 10 ? "0" + month : month
      }-${requestData.promo_time.getDate()}T00:00:00Z`,
    }
  } else {
    return {
      ...requestData,
      promo_time: "",
    }
  }
}

export function getMonthNumber(monthName: string): number {
  const months: { [key: string]: number } = {
    "Jan": 0,
    "Feb": 1,
    "Mar": 2,
    "Apr": 3,
    "May": 4,
    "Jun": 5,
    "Jul": 6,
    "Aug": 7,
    "Sep": 8,
    "Oct": 9,
    "Nov": 10,
    "Dec": 11,
  }

  return months[monthName]
}

export function forceDateStringToDeviceTimeZone(dateString: string): Date {
  const inputDate = new Date(dateString)
  //there's a chance of a timezone shift by the time of the future promo
  const tzoffset = inputDate.getTimezoneOffset() * 60000
  if (tzoffset >= 0) {
    return new Date(new Date(dateString).getTime() + tzoffset)
  } else {
    return new Date(new Date(dateString).getTime() - tzoffset)
  }
}

export function forceDateToDeviceTimeZone(date: Date): Date {
  const tzoffset = new Date().getTimezoneOffset() * 60000
  return new Date(date.getTime() + tzoffset)
}

export const getDatesWithSameDay = (
  month: number,
  datesArray: string[]
): number[] => {
  return datesArray
    .filter((dateString) => {
      return new Date(dateString).getUTCMonth() === month
    })
    .map((dateString) => {
      return new Date(dateString).getUTCDate()
    })
}

/**
 * # date range util functions
 */
export const userDateTimeISOToNearestGMTDateISO = (
  offsetDateStr: string
): string => {
  const offsetDate = forceDateToDeviceTimeZone(parseISO(offsetDateStr))
  const roundToNextDay = getHours(offsetDate) >= 12
  const dateTimeGMT = roundToNextDay ? addDays(offsetDate, 1) : offsetDate
  return format(dateTimeGMT, "yyyy-MM-dd")
}
export const blackoutDateGMT = (
  bd: IServerBlackoutDate
): IServerBlackoutDate => {
  return {
    uuid: bd.uuid,
    startDate: userDateTimeISOToNearestGMTDateISO(bd.startDate),
    endDate: userDateTimeISOToNearestGMTDateISO(bd.endDate),
  }
}
export const dateRangeToNewBlackoutDate = (
  dateRange: DateRange<Date>
): IServerBlackoutDate => {
  const startDate = dateRange[0]
    ? userDateTimeISOToNearestGMTDateISO(dateRange[0].toISOString())
    : ""
  const endDate = dateRange[1]
    ? userDateTimeISOToNearestGMTDateISO(dateRange[1].toISOString())
    : startDate
  return {
    startDate,
    endDate,
    uuid: "",
  }
}
export const adjustDateRange = (
  blackoutDates: IServerBlackoutDate[],
  dateRange: DateRange<Date>,
  preSelectedDateRange: IServerBlackoutDate
): IServerBlackoutDate[] => {
  return blackoutDates.map((bd) => {
    if (preSelectedDateRange && bd.uuid === preSelectedDateRange.uuid) {
      const adjustedBD = dateRangeToNewBlackoutDate(dateRange)
      adjustedBD.uuid = preSelectedDateRange.uuid
      return adjustedBD
    }
    return bd
  })
}
export const removeFragmentedDates = (
  originalList: IServerBlackoutDate[],
  modifiedList: IServerBlackoutDate[],
  removeBlackoutDateHandler: (blackoutDate: IServerBlackoutDate) => void
): void => {
  const fragmentedDates = originalList.filter(
    (original) =>
      !modifiedList.some((modified) => modified.uuid === original.uuid)
  )
  fragmentedDates.forEach((fragmentedDate) => {
    removeBlackoutDateHandler(fragmentedDate)
  })
}
const shouldMerge = (isoStr1: string, isoStr2: string): boolean => {
  return isEqual(
    addDays(forceDateStringToDeviceTimeZone(isoStr2), 1),
    forceDateStringToDeviceTimeZone(isoStr1)
  )
}
const getNextAvailableEndDate = (
  dates: IServerBlackoutDate[],
  blackoutDate: IServerBlackoutDate,
  currentIndex: number
): string => {
  const nextBD = dates.find((curr, index) => {
    return (
      shouldMerge(curr.startDate, blackoutDate.endDate) && currentIndex < index
    )
  })
  const iterate = nextBD && shouldMerge(nextBD.startDate, blackoutDate.endDate)
  return iterate
    ? getNextAvailableEndDate(dates, nextBD, currentIndex++)
    : blackoutDate.endDate
}
export const mergeAdjacentDates = (
  dates: Array<IServerBlackoutDate>
): Array<IServerBlackoutDate> => {
  const sortedDates = dates.sort(
    (a, b) =>
      forceDateStringToDeviceTimeZone(a.startDate).getTime() -
      forceDateStringToDeviceTimeZone(b.startDate).getTime()
  )
  return sortedDates.reduce(
    (
      currentlyMergedDates: Array<IServerBlackoutDate>,
      currentBD: IServerBlackoutDate,
      currentIndex: number
    ) => {
      const prevBD =
        currentIndex > 0 ? sortedDates[currentIndex - 1] : undefined
      const nextBD =
        currentIndex < sortedDates.length - 1
          ? sortedDates[currentIndex + 1]
          : undefined
      const shouldBeDropped =
        prevBD &&
        (isBefore(
          forceDateStringToDeviceTimeZone(currentBD.startDate),
          addDays(forceDateStringToDeviceTimeZone(prevBD.endDate), 1)
        ) ||
          isEqual(
            forceDateStringToDeviceTimeZone(currentBD.startDate),
            addDays(forceDateStringToDeviceTimeZone(prevBD.endDate), 1)
          ))

      const adjacent =
        nextBD && shouldMerge(nextBD.startDate, currentBD.endDate)
      const alreadyMerged = isOverlapping(currentlyMergedDates, currentBD)

      if (!shouldBeDropped && !adjacent && !alreadyMerged) {
        currentlyMergedDates.push(currentBD)
      } else if (adjacent && !alreadyMerged) {
        currentBD.endDate = getNextAvailableEndDate(
          sortedDates,
          currentBD,
          currentIndex
        )
        if (currentBD.uuid === "") {
          currentBD.uuid = nextBD.uuid
        }
        currentlyMergedDates.push(currentBD)
      }
      return currentlyMergedDates
    },
    []
  )
}
export const isOverlapping = (
  blackoutDates: IBlackoutDate[],
  newBD: IBlackoutDate
): boolean => {
  return blackoutDates.some((blackoutDate: IBlackoutDate) => {
    const bdGMT = blackoutDateGMT({ ...blackoutDate, uuid: "" })
    const newBdGMT = blackoutDateGMT({ ...newBD, uuid: "" })
    const currentStartDate = forceDateStringToDeviceTimeZone(bdGMT.startDate)
    const currentEndDate = forceDateStringToDeviceTimeZone(bdGMT.endDate)
    const newStartDate = forceDateStringToDeviceTimeZone(newBdGMT.startDate)
    const newEndDate = forceDateStringToDeviceTimeZone(newBdGMT.endDate)
    const interval = { start: currentStartDate, end: currentEndDate }
    return (
      isWithinInterval(newStartDate, interval) ||
      isWithinInterval(newEndDate, interval) ||
      (isAfter(currentEndDate, newStartDate) &&
        isBefore(currentStartDate, newEndDate)) ||
      isEqual(currentStartDate, newStartDate) ||
      isEqual(currentEndDate, newEndDate)
    )
  })
}
export const getBlackoutDateByUuid = (
  blackoutDates: IServerBlackoutDate[],
  preSelectedDateRange?: IServerBlackoutDate
): IServerBlackoutDate | undefined => {
  if (!preSelectedDateRange) {
    return undefined
  }
  return blackoutDates.find((bd) => {
    return bd.uuid === preSelectedDateRange.uuid
  })
}

export const findFirstAvailableDay = (blackoutDates: IBlackoutDate[]): Date => {
  const currentDay = startOfDay(new Date())
  const blackoutDate =
    blackoutDates.find((blackoutDate) => {
      return isWithinInterval(currentDay, {
        start: forceDateStringToDeviceTimeZone(blackoutDate.startDate),
        end: forceDateStringToDeviceTimeZone(blackoutDate.endDate),
      })
    }) || null
  return blackoutDate
    ? addDays(forceDateStringToDeviceTimeZone(blackoutDate.endDate), 1)
    : currentDay
}
export const isStartDate = (
  blackoutDates: IBlackoutDate[],
  date: Date
): boolean => {
  return blackoutDates.some((currentDateRange) => {
    const currentStartDate = forceDateStringToDeviceTimeZone(
      currentDateRange.startDate
    )
    return isEqual(currentStartDate, date)
  })
}
export const isEndDate = (
  blackoutDates: IBlackoutDate[],
  date: Date
): boolean => {
  return blackoutDates.some((currentDateRange) => {
    const currentEndDate = forceDateStringToDeviceTimeZone(
      currentDateRange.endDate
    )
    return isEqual(currentEndDate, date)
  })
}

export type IBlackoutDate = {
  startDate: string
  endDate: string
}

export type IServerBlackoutDate = IBlackoutDate & {
  uuid: string
}
export const generateLabelValue = (startDate: Date, endDate: Date): string => {
  const currentYear = new Date().getFullYear()
  const startYear = startDate.getFullYear()
  const endYear = endDate.getFullYear()
  const startMonthShort = startDate.toLocaleString("default", {
    month: "short",
  })
  const endMonthShort = endDate.toLocaleString("default", { month: "short" })
  const startDay = startDate.getDate()
  const endDay = endDate.getDate()
  const showStartYear =
    (startYear !== endYear && startMonthShort === endMonthShort) ||
    (currentYear !== startYear && isEqual(startDate, endDate))
  const isDiffDate = !isEqual(startDate, endDate)
  const showEndYear =
    startYear !== endYear || (endYear && endYear !== currentYear)
  const showEndMonth = showEndYear || startMonthShort !== endMonthShort
  return (
    startMonthShort +
    " " +
    startDay +
    (showStartYear ? " " + startYear : "") +
    (isDiffDate ? " - " : "") +
    (isDiffDate && showEndMonth ? endMonthShort + " " : "") +
    (isDiffDate ? endDay + " " : "") +
    (isDiffDate && showEndYear ? endYear : "")
  )
}
