import { ActionMap } from "../utils/types"
import React, { Dispatch } from "react"

export const initialState: BalanceStateType = {
  balance: 0,
}

export type BalanceStateType = {
  balance: number
}

export enum BalanceActionTypes {
  SetBalance = "Set Balance",
  AddBalance = "Add Balance",
  ReduceBalance = "Reduce Balance",
}

type BalancePayload = {
  [BalanceActionTypes.SetBalance]: {
    balance: number
  }
}

export type BalanceActions =
  ActionMap<BalancePayload>[keyof ActionMap<BalancePayload>]

export function reducer(
  state: BalanceStateType = initialState,
  action: BalanceActions
): BalanceStateType {
  let newState
  // let currentStateItem
  switch (action.type) {
    case BalanceActionTypes.SetBalance:
      newState = {
        ...state,
        balance: action.payload.balance,
      }
      break
    default:
      newState = {
        ...state,
      }
      break
  }
  return newState
}

export const BalanceContext = React.createContext<{
  balanceContext: BalanceStateType
  balanceDispatch: Dispatch<BalanceActions>
}>({
  balanceContext: initialState,
  balanceDispatch: () => null,
})
