import {useTheme} from "@mui/material"
import { useState, useEffect } from "react"

export function getWindowDimensions(): {width: number, height: number} {
    if (process.browser) {
        const {innerWidth: width, innerHeight: height} = window
        return {
            width,
            height,
        }
    } else {
        return {
            width: 0,
            height: 0,
        }
    }
}

export default function useWindowDimensions(): {width: number, height: number} {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

    useEffect(() => {
        function handleResize(): void {
            setWindowDimensions(getWindowDimensions())
        }

        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    return windowDimensions
}

export function isDesktopWidth(): boolean {
    const theme = useTheme()
    return getWindowDimensions().width > theme.breakpoints.values.sm
}
