import { MyWebsocket } from "../websockets/websocket"
import { MessageActions, MessageActionTypes } from "../contexts/websocket"
import { Dispatch } from "react"

export function setWebsocket(
  websocket: MyWebsocket,
  dispatch: Dispatch<MessageActions>
): void {
  dispatch({
    type: MessageActionTypes.NewWebsocket,
    payload: {
      websocket: websocket,
    },
  })
}
