import styled from "@emotion/styled"
import { PhotoOutlined, Send } from "@mui/icons-material"
import { Input, Theme, useTheme } from "@mui/material"
import { ChangeEvent, useContext, useMemo, useState } from "react"
import { isDesktopWidth } from "../../../utils/windowDimensions"
import { MessageContext } from "../../../contexts/websocket"
import {
  ACCEPTED_IMAGE_FORMATS,
  ACCEPTED_VIDEO_FORMATS,
} from "../../../utils/constants"
import { UserContext } from "../../../contexts/user"

export interface IChatBarProps {
  onSubmit?: (chatInputValue: string) => void
  onAddMediaIconClicked: () => void
  uploadMedia: (media: File[]) => Promise<void>
  toProfileUUID: string | undefined
}

export const ChatBar = ({
  onSubmit,
  onAddMediaIconClicked,
  uploadMedia,
  toProfileUUID,
}: IChatBarProps): JSX.Element => {
  const theme = useTheme()
  const [chatInputValue, setChatInputValue] = useState("")
  const [lastUpdateUserTypingSent, setLastUpdateUserTypingSent] =
    useState<number>(0)
  const { messageContext } = useContext(MessageContext)
  const { context } = useContext(UserContext)
  const isReconnecting = useMemo(
    () => messageContext.reconnecting,
    [messageContext.reconnecting]
  )
  const websocket = useMemo(
    () => messageContext.websocket,
    [messageContext.websocket]
  )
  const currentUuid = useMemo(() => context.uuid, [context.uuid])

  const handleTextInput = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    if (toProfileUUID !== undefined) {
      if (event.currentTarget.value !== "") {
        if (lastUpdateUserTypingSent < Date.now() - 20000) {
          websocket?.updateUserTyping(true, toProfileUUID, currentUuid)
          setLastUpdateUserTypingSent(Date.now())
        }
      } else {
        websocket?.updateUserTyping(false, toProfileUUID, currentUuid)
      }
    }
    setChatInputValue(event.currentTarget.value)
  }

  return (
    <StyledChatBar theme={theme}>
      <PhotoOutlined
        height={15}
        width={15}
        className={"action"}
        onClick={onAddMediaIconClicked}
      />
      <Input
        sx={{
          background: "rgba(0, 0, 0, 0.06)",
          padding: "12px",
          borderRadius: "4px 4px 0 0",
          marginTop: "10px",
        }}
        multiline={true}
        minRows={1}
        maxRows={5}
        fullWidth={true}
        placeholder={isReconnecting ? "Reconnecting chat" : "Chat"}
        value={chatInputValue}
        onChange={handleTextInput}
        onKeyDown={(event) => {
          if (event.key === "Enter" && onSubmit !== undefined) {
            event.preventDefault()
            onSubmit(chatInputValue)
            setChatInputValue("")
            setLastUpdateUserTypingSent(0)
          }
        }}
        onSubmit={() => {
          if (onSubmit !== undefined) {
            onSubmit(chatInputValue)
            setChatInputValue("")
            setLastUpdateUserTypingSent(0)
          }
        }}
        autoFocus={isDesktopWidth()}
        disabled={isReconnecting}
        onPaste={async (event) => {
          const file =
            event.clipboardData.files.length > 0
              ? event.clipboardData.files[0]
              : null
          const acceptedFileTypes = [
            ...Object.keys(ACCEPTED_IMAGE_FORMATS),
            ...Object.keys(ACCEPTED_VIDEO_FORMATS)[0],
          ]
          if (file !== null && acceptedFileTypes.indexOf(file.type) > -1) {
            await uploadMedia([file])
          }
        }}
      />
      <Send
        height={15}
        width={15}
        className={"action"}
        onClick={() => {
          if (onSubmit !== undefined) {
            onSubmit(chatInputValue)
            setChatInputValue("")
            setLastUpdateUserTypingSent(0)
          }
        }}
      />
    </StyledChatBar>
  )
}

const StyledChatBar = styled.div<{ theme: Theme }>`
  display: flex;
  padding: 8px;
  width: 100%;
  align-items: center;
  gap: 10px;
  background: white;
  min-height: 56px;
  margin-right: 24px;
  width: 100%;
  box-shadow: 0 -2px 2px 0 rgb(0, 0, 0, 0.1);
  z-index: 999;

  ${(props) => props.theme.breakpoints.up("sm")} {
    width: 100%;
    margin-right: 0;
  }

  .action {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.54);
  }
`
