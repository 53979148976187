import React, {Dispatch} from "react"
import {ActionMap} from "../utils/types"

type ChatType = {
    otherUser: string
    messageDrawerOpen: boolean
}

export const initialChatState: ChatType = {
    otherUser: "",
    messageDrawerOpen: false,
}

export enum ChatActionTypes {
    ChatWithUser = "CHATWITHUSER",
    SetMessageDrawerOpen = "SETMESSAGEDRAWEROPEN",
}

type ChatPayload = {
    [ChatActionTypes.ChatWithUser]: string
    [ChatActionTypes.SetMessageDrawerOpen]: boolean
}

export type ChatActions = ActionMap<ChatPayload>[keyof ActionMap<ChatPayload>]

export function chatReducer(state = initialChatState, action: ChatActions): ChatType {
    switch (action.type) {
        case ChatActionTypes.ChatWithUser: {
            return {
                ...state,
                otherUser: action.payload,
                messageDrawerOpen: Boolean(action.payload),
            }
        }
        case ChatActionTypes.SetMessageDrawerOpen: {
            return {
                ...state,
                messageDrawerOpen: action.payload,
            }
        }
        default:
            return state
    }
}

export const ChatContext = React.createContext<{context: ChatType, dispatch: Dispatch<ChatActions>}>({
    context: initialChatState,
    dispatch: () => null,
})
