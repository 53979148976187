import styled from "styled-components"

export const StyledSocialBar = styled.div.attrs(
  (props: { size: string }) => props
)`
  display: flex;
  justify-content: flex-start;
  gap: ${(props) =>
    props.size === "xs" ? "5px" : props.size === "Small" ? "10px" : "20px"};
  .social-item {
    display: flex;
    gap: 2px;
    align-items: center;
    cursor: pointer;
    .icon {
      height: ${(props) =>
        props.size === "xs"
          ? "16px"
          : props.size === "Small"
          ? "24px"
          : "32px"};
      width: ${(props) =>
        props.size === "xs"
          ? "16px"
          : props.size === "Small"
          ? "24px"
          : "32px"};
    }
  }
`
