import {Check} from "@mui/icons-material"
import {SxProps, Theme, Tooltip, useTheme} from "@mui/material"

export interface IVerifiedProps {
    size?: "Small" | "Medium" | "Large"
    sx?: SxProps<Theme>
    disabled?: boolean
}

const sizeMap = {
    Small: {size: "14px", top: "1px"},
    Medium: {size: "16px", top: "2px"},
    Large: {size: "20px", top: "3px"},
}

export const Verified = (props: IVerifiedProps): JSX.Element => {
    const size = props.size || "Medium"
    const theme = useTheme()
    return (
        <Tooltip title={"Blossm-verified creator"}>
            <Check
                sx={{
                    color: "White",
                    borderRadius: "50%",
                    height: sizeMap[size].size,
                    width: sizeMap[size].size,
                    backgroundColor: props.disabled ? theme.palette.grey[500] : theme.palette.primary.main,
                    padding: "1px",
                    display: "inline-block",
                    position: "relative",
                    top: sizeMap[size].top,
                    ...props.sx,
                }}
            />
        </Tooltip>
    )
}
