import {
  Alert,
  Box,
  Dialog,
  Fab,
  Snackbar,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Typography,
  useTheme as useThemeMaterial,
} from "@mui/material"
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import {
  ArrowBack,
  ArrowForward,
  DeleteOutline,
  MoreHoriz,
  MoreVert,
  Visibility,
  VisibilityOff,
  VisibilityOffOutlined,
} from "@mui/icons-material"
import { Colors } from "../../styles/Colors"
import { IPhoto } from "../../utils/photos"
import { deleteRequest, patch } from "../../utils/requests"
import styled, { useTheme } from "styled-components"
import { Modal } from "../Molecules/Modal/Modal"
import { Download } from "./Download"
import {
  modalDisplayErrorHandler,
  ProfileUpdate,
} from "../../utils/imageErrorModalHandler"
import { RequestErrorModal } from "../Molecules/RequestErrorModal"
import { ErrorContext } from "../../contexts/photoUploadErrors"
import { BlossmImage } from "./BlossmImage"

export interface IImageViewerProps {
  images: IPhoto[]
  open: boolean
  onClose: () => void
  position: number
  setPosition: Dispatch<SetStateAction<number>>
  onDelete?: () => void
  onUpdate?: (message: string) => void
  isMediaProfile?: boolean
  showDownloadBtn?: boolean
  snackbarMessage?: string
  setSnackbarMessage?: Dispatch<React.SetStateAction<string>>
}

export const ImageViewer = ({
  images,
  open,
  onClose,
  position,
  setPosition,
  onDelete,
  onUpdate,
  isMediaProfile,
  showDownloadBtn = false,
  snackbarMessage,
  setSnackbarMessage,
}: IImageViewerProps): JSX.Element => {
  const theme = useTheme()
  const themeMaterial = useThemeMaterial()
  const [deleteModal, setDeleteModal] = useState(false)
  const { errorDispatch } = useContext(ErrorContext)

  function downHandler(event: KeyboardEvent): void {
    switch (event.code) {
      case "ArrowRight":
        setPosition((prevState) =>
          prevState < images.length - 1 ? prevState + 1 : prevState
        )
        break
      case "ArrowLeft":
        setPosition((prevState) =>
          prevState !== 0 ? prevState - 1 : prevState
        )
        break
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", downHandler)

    return () => {
      document.removeEventListener("keydown", downHandler)
    }
  }, [images])

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      PaperProps={{
        sx: {
          backgroundColor: "#000",
          width: "100%",
          height: "60%",
          "& .floating-button": {
            background: "rgba(0, 0, 0, .7)",
            "&:hover": {
              background: "rgba(0, 0, 0, .9)",
            },
            "& svg": { color: Colors.white },
          },
        },
      }}
    >
      {images[position] && images[position].isVideo ? (
        <video
          src={images[position]?.originalUrl}
          autoPlay={true}
          controls={true}
          style={{ width: "100%", height: "100%", zIndex: 0 }}
        />
      ) : (
        <BlossmImage
          scaleDown={true}
          alt={"viewable-image"}
          src={images[position]?.originalUrl}
        />
      )}

      {showDownloadBtn && images[position] && (
        <Box
          className="floating-button"
          sx={{
            position: "absolute",
            top: 12,
            right: 8,
          }}
        >
          <Download files={[images[position]]} />
        </Box>
      )}

      {(onDelete || onUpdate) && (
        <SpeedDial
          ariaLabel="Media options"
          direction="down"
          FabProps={{ size: "medium", className: "floating-button" }}
          sx={{ position: "absolute", top: 12, right: 8 }}
          icon={<SpeedDialIcon icon={<MoreVert />} openIcon={<MoreHoriz />} />}
        >
          {onUpdate &&
            (images[position]?.isPublic ? (
              <SpeedDialAction
                key="visibility"
                icon={<VisibilityOff />}
                tooltipTitle={"Make private"}
                onClick={() => {
                  const formData = new FormData()
                  formData.append("is_public", "False")
                  patch(
                    `/photo_video/photo/update/${images[position].id}/`,
                    formData
                  ).then(() => {
                    onUpdate("Media set to private")
                  })
                }}
                className="floating-button"
              />
            ) : (
              <SpeedDialAction
                key="visibility"
                icon={<Visibility />}
                tooltipTitle={"Make public"}
                onClick={() => {
                  const formData = new FormData()
                  formData.append("is_public", "True")
                  patch(
                    `/photo_video/photo/update/${images[position].id}/`,
                    formData
                  )
                    .then(() => {
                      onUpdate("Media set to public")
                    })
                    .catch((error) => {
                      modalDisplayErrorHandler(
                        error.response.status,
                        errorDispatch,
                        error,
                        ProfileUpdate.Media
                      )
                    })
                }}
                className="floating-button"
              />
            ))}

          {onDelete && (
            <SpeedDialAction
              key="delete"
              icon={<DeleteOutline />}
              tooltipTitle={"Delete"}
              onClick={() => {
                setDeleteModal(true)
              }}
              className="floating-button"
            />
          )}
        </SpeedDial>
      )}
      {images.length > 1 && (
        <>
          {position !== 0 && (
            <Fab
              size="medium"
              className="floating-button"
              onClick={() => setPosition(position - 1)}
              sx={{
                top: "50%",
                position: "absolute",
                transform: "translateY(-50%)",
                left: 12,
              }}
            >
              <ArrowBack />
            </Fab>
          )}

          {position !== images.length - 1 && (
            <Fab
              size="medium"
              className="floating-button"
              onClick={() => setPosition(position + 1)}
              sx={{
                top: "50%",
                position: "absolute",
                transform: "translateY(-50%)",
                right: 12,
              }}
            >
              <ArrowForward />
            </Fab>
          )}
        </>
      )}
      {isMediaProfile && !images[position]?.isPublic && (
        <StyledPBannerVisibily theme={theme}>
          <div className="content-banner">
            <VisibilityOffOutlined sx={{ marginRight: "10px" }} />
            <Typography variant={"body2"}>
              Only you can see this media
            </Typography>
          </div>
        </StyledPBannerVisibily>
      )}
      <Modal
        open={deleteModal}
        title="Delete Media"
        text="Are you sure you want to delete this media?"
        onHandle={() => {
          deleteRequest(
            `/photo_video/photo/delete/${images[position].id}/`
          ).then(() => {
            setDeleteModal(false)
            if (onDelete) {
              onDelete()
            }
          })
        }}
        onClose={() => {
          setDeleteModal(false)
        }}
        optionOne="Cancel"
        optionTwo="Delete Media"
      />

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(snackbarMessage)}
        autoHideDuration={10000}
        onClose={() => {
          if (setSnackbarMessage) {
            setSnackbarMessage("")
          }
        }}
      >
        <Alert
          severity="success"
          onClose={() => {
            if (setSnackbarMessage) {
              setSnackbarMessage("")
            }
          }}
          sx={{
            [themeMaterial.breakpoints.down("sm")]: {
              width: "100%",
            },
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <RequestErrorModal />
    </Dialog>
  )
}

const StyledPBannerVisibily = styled.div`
  width: 100%;
  color: ${Colors.white};
  position: absolute;
  bottom: 0;
  opacity: 0.7;
  padding: 18px 0px;

  .content-banner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`
