import styled from "@emotion/styled"
import { Container, Grid, Theme, useMediaQuery, useTheme } from "@mui/material"
import React, { useCallback, useContext, useState, useRef } from "react"
import { UserContext } from "../../../../contexts/user"
import { DesktopNavbar } from "../../DesktopNavbar"
import { SearchResults } from "../../SearchResults"
import { MessagesDrawer } from "./MessagesDrawer"
import { IProfileCardProps } from "../../HorizontalProfileCard"
import { get } from "../../../../utils/requests"
import { ArgJSONMap } from "../../../../utils/argjsonmap"
import { parseProfileCardsResponse } from "../../../../utils/parsers"
import { debounce } from "../../../../utils/debounce"
import { SettingsLauncher } from "../../../Atoms/SettingsLauncher"
import { MobileNav } from "../../../Atoms/MobileNav"
import { useRouter } from "next/router"
import { ImpersonateBanner } from "../../../Molecules/ImpersonateBanner"
import { trackSearchEvent } from "../../../../utils/eventTracking"

export interface ILayoutProps {
  children: JSX.Element
  showRightColumnSearch?: boolean
}

export const Layout = ({
  children,
  showRightColumnSearch = true,
}: ILayoutProps): JSX.Element => {
  const theme = useTheme()
  const router = useRouter()
  const { context } = useContext(UserContext)
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"))
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const [settingsMenuOpen, setSettingsMenuOpen] = useState(false)

  const [profiles, setProfiles] = useState<IProfileCardProps[]>([])
  const requestProfilesDebounce = debounce((value: string) => {
    const url =
      value !== ""
        ? `profile_query/?profile_name=${value}&only_has_promo=true`
        : "profile_query/?only_has_promo=true"
    get(url)
      .then((response) => {
        const data = ArgJSONMap.fromParsedJson(response.data)
        const profiles = data.getList("profiles") ?? []
        setProfiles(parseProfileCardsResponse(profiles))
        trackSearchEvent(value, "right_column")
      })
      .catch((error) => {
        console.warn(error)
      })
  }, 500)
  const debounceRequest = useCallback(
    (value: string) => requestProfilesDebounce(value),
    []
  )
  const searchProfiles = (searchTerm: string): void => {
    debounceRequest(searchTerm)
  }
  const impersonateBanner = useRef<HTMLDivElement>(null)

  return (
    <StyledLayout
      backgroundColor={theme.palette.background.default}
      theme={theme}
      isMobile={isMobile}
      impersonateBanner={impersonateBanner.current}
    >
      <div ref={impersonateBanner}>
        <ImpersonateBanner />
      </div>
      <Container
        className={"page-container"}
        disableGutters={useMediaQuery(theme.breakpoints.down("md"))}
        maxWidth={false}
      >
        <Grid
          sx={{ background: theme.palette.background.paper }}
          className={"layout-main-grid"}
          container
        >
          {(isTablet || isDesktop) && (
            <Grid item sm={0.5} md={0.5} lg={1.5}>
              <DesktopNavbar />
            </Grid>
          )}
          <Grid
            item
            xs={isMobile ? 12 : 11}
            md={showRightColumnSearch ? 7 : 10.5}
            className={"main-content-body"}
          >
            <>
              <div className={"page-content-box"}>{children}</div>
              {isMobile && (
                <>
                  <MobileNav
                    menuOpen={settingsMenuOpen}
                    openMenu={() => setSettingsMenuOpen(true)}
                    location={router.pathname}
                  />
                  <SettingsLauncher
                    userName={context.profileName}
                    open={settingsMenuOpen}
                    setOpen={setSettingsMenuOpen}
                    onClose={() => setSettingsMenuOpen(false)}
                    onOpen={() => setSettingsMenuOpen(true)}
                    profileImage={context.avatarUrl}
                    profileName={context.profileDisplayName}
                    verified={context.verified}
                    online={true}
                    handleLogOut={() => setSettingsMenuOpen(false)}
                  />
                </>
              )}
            </>
          </Grid>
          {showRightColumnSearch && isDesktop && (
            <Grid item xs={3.5}>
              <SearchResults
                profiles={profiles}
                searchProfiles={(term) => searchProfiles(term)}
              />
            </Grid>
          )}
        </Grid>
      </Container>
      {!isMobile && !isTablet && <MessagesDrawer />}
    </StyledLayout>
  )
}

export const StyledLayout = styled.div<{
  backgroundColor: string
  theme: Theme
  isMobile: boolean
  impersonateBanner: HTMLDivElement | null
}>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: ${(props) => props.backgroundColor};
  display: block;
  flex-direction: column;
  overflow: hidden;

  .page-content-box {
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .page-container {
    height: calc(
      100% -
        ${(props) =>
          props.impersonateBanner ? props.impersonateBanner.offsetHeight : 0}px
    );
    padding-bottom: 2px;
    overflow: hidden;
    display: flex;
    justify-content: center;

    .layout-main-grid {
      width: 100%;
      height: 100%;
      max-width: 1600px;
      margin-right: 0;
    }
  }

  .main-content-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  }
`
