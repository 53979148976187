import { ArgJSONMap } from "./argjsonmap"

export type AxiosErrorResponseData =
  | { [key: string]: string }
  | { [key: string]: string[] }
  | string[]
  | string

/**
 * Parse object, list of strings, or string in to error message string.
 * @param errors
 */
export function createErrorMessageList(
  errors: AxiosErrorResponseData
): string[] {
  const errorData = ArgJSONMap.fromParsedJson(errors)
  let totalErrorMessage: string[] = []
  if (Array.isArray(errors)) {
    errors.forEach((error) => {
      if (typeof error === "object") {
        totalErrorMessage = totalErrorMessage.concat(parseErrorObject(error))
      } else {
        totalErrorMessage = totalErrorMessage.concat(errors)
      }
    })
  } else if (typeof errors === "object") {
    Object.keys(errors).forEach((key: string) => {
      if (Array.isArray(errors[key])) {
        const errorList = errorData.getListOfType<never>(key)
        errorList.forEach((error) => {
          if (typeof error === "object") {
            totalErrorMessage = totalErrorMessage.concat(
              parseErrorObject(error)
            )
          } else {
            totalErrorMessage.push(error)
          }
        })
      } else if (typeof errors[key] === "string") {
        totalErrorMessage.push(errors[key] as string)
      }
    })
  } else if (typeof errors === "string") {
    totalErrorMessage.push(errors)
  }
  return totalErrorMessage
}

export function createErrorMessageString(
  errors: { [key: string]: string[] } | string[] | string
): string {
  let errorMsg = ""

  if (Array.isArray(errors)) {
    for (const item of errors) {
      errorMsg += `${item}`
    }
  } else if (typeof errors === "object") {
    for (const key of Object.keys(errors)) {
      if (typeof errors[key] === "string") {
        errorMsg += errors[key]
      } else if (Array.isArray(errors[key])) {
        for (const error of errors[key]) {
          errorMsg += `${error} `
        }
      }
    }
  } else if (typeof errors === "string") {
    errorMsg = errors
  }
  return errorMsg
}

function parseErrorObject(errors: { [key: string]: never }): string[] {
  const errorData = ArgJSONMap.fromParsedJson(errors)
  const totalErrorMessage: string[] = []

  Object.keys(errors).forEach((key: string) => {
    if (Array.isArray(errors[key])) {
      const errorList = errorData.getListOfType<string>(key)
      errorList.forEach((error) => {
        totalErrorMessage.push(error)
      })
    } else if (typeof errors[key] === "string") {
      totalErrorMessage.push(errors[key] as string)
    }
  })
  return totalErrorMessage
}
