import styled from "@emotion/styled"
import { ThemeProvider, useMediaQuery } from "@mui/material"
import CssBaseline from "@mui/material/CssBaseline"
import { ReactElement, ReactNode, useEffect, useReducer, useState } from "react"
import { theme } from "../../styles/Theme"
import {
  ErrorContext,
  errorReducer,
  initialErrorState,
} from "../contexts/photoUploadErrors"
import {
  ActionTypes as UserActionTypes,
  UserContext,
  initialState as userInitialState,
  reducer as userReducer,
} from "../contexts/user"
import {
  UserSocialAccountsContext,
  initialState as userSocialAccountsInitialState,
  reducer as userSocialAccountsReducer,
} from "../contexts/userSocialAccounts"
import {
  MessageActionTypes,
  MessageContext,
  initialWebsocketContextState,
  websocketContextReducer,
} from "../contexts/websocket"
import "../styles/globals.css"
import { updateUserContext } from "../utils/account"
// import {MyWebsocket} from "../websockets/privateMessageWebsockets"
import { NextPage } from "next"
import type { AppProps } from "next/app"
import Head from "next/head"
import { useRouter } from "next/router"
import { SEO } from "../components/Organisms/SEO"
import {
  BalanceActionTypes,
  BalanceContext,
  initialState as balanceInitialState,
  reducer as balanceReducer,
} from "../contexts/balance"
import {
  CampaignContext,
  initialState as campaignInitialState,
  reducer as campaignReducer,
} from "../contexts/campaigns"
import { ChatContext, chatReducer, initialChatState } from "../contexts/chat"
import {
  FansMetricConfigContext,
  initialState as fansMetricInitialState,
  reducer as fansMetricReducer,
} from "../contexts/fansMetricContext"
import {
  PagingAction,
  PagingContext,
  pagingInitialState,
  pagingReducer,
} from "../contexts/paging"
import {
  ActionTypes as ActionRequiredActionTypes,
  SiteActionRequiredContext,
  actionRequiredInitialState,
  actionRequiredReducer,
} from "../contexts/siteActionRequiredContext"
import {
  SiteNotificationsContext,
  notificationsInitialState,
  notificationsReducer,
} from "../contexts/siteNotificationContext"
import {
  WafflesContext,
  ActionTypes as waffleActionTypes,
  initialState as waffleIntialState,
  waffleReducer,
} from "../contexts/waffles"
import { ArgJSONMap } from "../utils/argjsonmap"
import { SetCookie } from "../utils/cookies"
import { get } from "../utils/requests"
import { setWebsocket } from "../utils/websocket"
import { MyWebsocket } from "../websockets/websocket"
import ErrorBoundary from "./error-boundary"

export interface ICustomPageProps {
  isPublicRoute: boolean
  captchaKey?: string
}

export type NextPageWithLayout<T = never> = NextPage<T> & {
  getLayout?: (page: ReactElement) => ReactNode
}

interface IAppProps<T> extends AppProps<T> {
  Component: NextPageWithLayout<T>
}

export function MyApp({
  Component,
  pageProps,
}: IAppProps<ICustomPageProps>): JSX.Element {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const getLayout = Component.getLayout ?? ((page) => page)
  const [userContext, setUserDispatch] = useReducer(
    userReducer,
    userInitialState
  )
  const [errorContext, errorDispatch] = useReducer(
    errorReducer,
    initialErrorState
  )
  const [userSocialAccountsContext, setUserSocialAccounts] = useReducer(
    userSocialAccountsReducer,
    userSocialAccountsInitialState
  )
  const [messageContext, messageDispatch] = useReducer(
    websocketContextReducer,
    initialWebsocketContextState
  )
  const [chatContext, chatDispatch] = useReducer(chatReducer, initialChatState)
  const [isLoadingMe, setIsLoadingMe] = useState(true)
  const [checkedAuth, setCheckedAuth] = useState(false)
  const [siteNotificationsContext, siteNotificationsDispatch] = useReducer(
    notificationsReducer,
    notificationsInitialState
  )
  const [siteActionRequiredContext, siteActionRequiredDispatch] = useReducer(
    actionRequiredReducer,
    actionRequiredInitialState
  )
  const [waffleContext, wafflesDispatch] = useReducer(
    waffleReducer,
    waffleIntialState
  )
  const [campaignContext, campaignDispatch] = useReducer(
    campaignReducer,
    campaignInitialState
  )

  const [balanceContext, balanceDispatch] = useReducer(
    balanceReducer,
    balanceInitialState
  )

  const [pagingContext, pagingDispatch] = useReducer(
    pagingReducer,
    pagingInitialState
  )

  const [fansMetricConfigContext, fansMetricConfigDispatch] = useReducer(
    fansMetricReducer,
    fansMetricInitialState
  )

  const router = useRouter()
  const { profile } = router.query

  const handleActionRequiredNotifications = (): void => {
    Promise.all([
      get(`/schedule/buyer/action_required/?profile_uuid=${userContext.uuid}`),
      get(`/schedule/seller/action_required/?profile_uuid=${userContext.uuid}`),
    ])
      .then(([buyerActionRequiredResponse, sellerActionRequiredResponse]) => {
        const buyerActions = ArgJSONMap.fromParsedJson(
          buyerActionRequiredResponse.data
        ).getList("results")
        const sellerActions = ArgJSONMap.fromParsedJson(
          sellerActionRequiredResponse.data
        ).getList("results")

        if (buyerActions || sellerActions) {
          siteActionRequiredDispatch({
            type: ActionRequiredActionTypes.LoadNotificationActionRequiredCount,
            payload: {
              actionRequiredNotifications:
                (buyerActions ? buyerActions.length : 0) +
                (sellerActions ? sellerActions.length : 0),
            },
          })
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    let parsedProfile: string | undefined
    if (Array.isArray(profile)) {
      if (profile.length > 0) {
        parsedProfile = profile[0]
      } else {
        parsedProfile = undefined
      }
    } else {
      parsedProfile = profile
    }
    updateUserContext(setUserDispatch, siteNotificationsDispatch, parsedProfile)
      .then(() => {
        setIsLoadingMe(false)
      })
      .catch(() => {
        console.warn("Failed to update user info")
        setIsLoadingMe(false)
      })
    get("/waffles/").then((response) => {
      wafflesDispatch({
        type: waffleActionTypes.PopulateWaffles,
        payload: {
          type: response.data.type,
          flags: response.data.flags.map((item: { name: string }) => {
            return item.name
          }),
          switches: response.data.switches.map((item: { name: string }) => {
            return item.name
          }),
        },
      })
    })
  }, [])

  useEffect(() => {
    //hide zendesk on mobile
    // eslint-disable-next-line
    // @ts-ignore
    zE("messenger:set", "cookies", !isMobile)
    pagingDispatch({
      type: PagingAction.SetMobile,
      payload: isMobile,
    })
  }, [isMobile])

  useEffect(() => {
    if (pageProps.captchaKey) {
      setUserDispatch({
        type: UserActionTypes.SetCaptcha,
        payload: {
          captcha: pageProps.captchaKey,
        },
      })
    }
  }, [pageProps.captchaKey])

  useEffect(() => {
    if (userContext.uuid !== "") {
      get(`/total_pm_unread_count/?profile_id=${userContext.uuid}`)
        .then((response) => {
          const data = ArgJSONMap.fromParsedJson(response.data)
          messageDispatch({
            type: MessageActionTypes.UpdateUnreadMessageCount,
            payload: {
              unreadMessageCount: data.getNumber("count"),
            },
          })
        })
        .catch((error) => console.warn(error))

      if (messageContext.websocket === undefined) {
        const websocket = new MyWebsocket(
          userContext.uuid,
          messageDispatch,
          siteNotificationsDispatch,
          setUserDispatch,
          balanceDispatch
        )
        setWebsocket(websocket, messageDispatch)
      }
      messageContext.websocket?.changeProfile(userContext.uuid)
      getBalance()
      //get Action Required Orders
      handleActionRequiredNotifications()
    }
  }, [userContext.uuid])

  useEffect(() => {
    if (router.query) {
      if (
        router.query.ref ||
        router.query.utm_source ||
        router.query.utm_medium ||
        router.query.utm_campaign
      ) {
        if (router.query.ref) {
          const refer = router.query.ref.toString()
          get(`is_profile?name=${refer}`).catch(() => {
            return null
          })
          delete router.query.ref
        }

        if (router.query.utm_source) {
          const source = router.query.utm_source.toString()
          SetCookie("utm_source", source, 5)
          delete router.query.utm_source
        }

        if (router.query.utm_medium) {
          const medium = router.query.utm_medium.toString()
          SetCookie("utm_medium", medium, 5)
          delete router.query.utm_medium
        }

        if (router.query.utm_campaign) {
          const campaign = router.query.utm_campaign.toString()
          SetCookie("utm_campaign", campaign, 5)
          delete router.query.utm_campaign
        }

        router.push(router)
      }
    }
  }, [router.query])

  //Install notifications service worker
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/notifications-service-worker.js")
        .then((registration) => {
          registration.addEventListener("updatefound", () => {
            const installingWorker = registration.installing
            console.log(
              "A new service worker is being installed:",
              installingWorker
            )
          })
        })
        .catch((error) => {
          console.error(`Service worker registration failed: ${error}`)
        })
    } else {
      console.error("Service workers are not supported.")
    }
  }, [])

  //Update balance user
  function getBalance(): void {
    get("purchase/balance/").then((response) => {
      const balanceData = ArgJSONMap.fromParsedJson(response.data)
      const number = balanceData.getNumber("balance")
      balanceDispatch({
        type: BalanceActionTypes.SetBalance,
        payload: {
          balance: number,
        },
      })
    })
  }

  //redirect Routes
  useEffect(() => {
    if (isLoadingMe) {
      return
    }
    if (!userContext.isAuthenticated && !pageProps.isPublicRoute) {
      router.replace("/login")
    } else {
      setCheckedAuth(true)
    }
  }, [isLoadingMe, userContext.isAuthenticated, router.pathname])

  //loading page...
  if (isLoadingMe || !checkedAuth) {
    return <span>Loading...</span>
  }

  return (
    <ErrorBoundary router={router}>
      <Head>
        <meta
          name="viewport"
          content="width=device-width,minimum-scale=1,initial-scale=1"
        />
      </Head>
      <SEO />
      <StyledDiv>
        <UserContext.Provider
          value={{ context: userContext, dispatch: setUserDispatch }}
        >
          <UserSocialAccountsContext.Provider
            value={{
              context: userSocialAccountsContext,
              dispatch: setUserSocialAccounts,
            }}
          >
            <MessageContext.Provider
              value={{ messageContext, messageDispatch }}
            >
              <SiteNotificationsContext.Provider
                value={{ siteNotificationsContext, siteNotificationsDispatch }}
              >
                <ChatContext.Provider
                  value={{ context: chatContext, dispatch: chatDispatch }}
                >
                  <SiteActionRequiredContext.Provider
                    value={{
                      siteActionRequiredContext,
                      siteActionRequiredDispatch,
                    }}
                  >
                    <WafflesContext.Provider
                      value={{
                        wafflesContext: waffleContext,
                        wafflesDispatch: wafflesDispatch,
                      }}
                    >
                      <CampaignContext.Provider
                        value={{
                          campaignContext: campaignContext,
                          campaignDispatch: campaignDispatch,
                        }}
                      >
                        <BalanceContext.Provider
                          value={{
                            balanceContext: balanceContext,
                            balanceDispatch: balanceDispatch,
                          }}
                        >
                          <ErrorContext.Provider
                            value={{
                              errorContext,
                              errorDispatch,
                            }}
                          >
                            <PagingContext.Provider
                              value={{
                                pagingContext,
                                pagingDispatch,
                              }}
                            >
                              <FansMetricConfigContext.Provider
                                value={{
                                  fansMetricConfigContext,
                                  fansMetricConfigDispatch,
                                }}
                              >
                                <ThemeProvider theme={theme}>
                                  <CssBaseline />
                                  {getLayout(<Component {...pageProps} />)}
                                </ThemeProvider>
                              </FansMetricConfigContext.Provider>
                            </PagingContext.Provider>
                          </ErrorContext.Provider>
                        </BalanceContext.Provider>
                      </CampaignContext.Provider>
                    </WafflesContext.Provider>
                  </SiteActionRequiredContext.Provider>
                </ChatContext.Provider>
              </SiteNotificationsContext.Provider>
            </MessageContext.Provider>
          </UserSocialAccountsContext.Provider>
        </UserContext.Provider>
      </StyledDiv>
    </ErrorBoundary>
  )
}

export default MyApp

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
`
