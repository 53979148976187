import React from "react"
import {Grid} from "@mui/material"
import {SxProps} from "@mui/system"
import {Theme} from "@mui/material/styles"

interface ILoginGridProps {
    children?: JSX.Element[] | JSX.Element
    sx?: SxProps<Theme>
}

export const LoginGrid = ({children, sx}: ILoginGridProps): JSX.Element => {
    return (
        <Grid
            container
            direction="row"
            justifyContent="space-around"
            sx={sx}
        >
            <Grid
                item
                xl={4}
                lg={5}
                md={7}
                sm={10}
                xs={12}
                position="relative"
            >
                {children}
            </Grid>
        </Grid>
    )
}
