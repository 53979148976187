import { ArgJSONMap } from "../utils/argjsonmap"
import { IMessageProps } from "../components/Atoms/Chat/Message"
import { IChatConnectedResponse } from "./interfaces"
import { Dispatch } from "react"
import { MessageActions, MessageActionTypes } from "../contexts/websocket"
import {
  ActionTypes,
  SiteNotificationsActions,
} from "../contexts/siteNotificationContext"
import { ActionTypes as UserActionTypes, UserActions } from "../contexts/user"
import { INotificationProps } from "../components/Molecules/Notification"
import { parsePhotos } from "../utils/parsers"
import { BalanceActions, BalanceActionTypes } from "../contexts/balance"
import { IChatAlertProps } from "../components/Atoms/Chat/Alert"

export interface IChatMessage {
  text: string
  fromUser: string
}

export interface IChatHistoryMessages {
  messages: IMessageProps[]
}

const websocketChatActionMap: {
  [keys: string]: (
    profileUUID: string | undefined,
    data: ArgJSONMap,
    dispatch: Dispatch<MessageActions>
  ) => void
} = {
  send_chat_history: handleChatHistory,
  send_private_message: handlePrivateMessage,
  send_chat_connected: handleChatConnected,
  send_unread_message_count: handleUnreadMessageCount,
  send_user_typing_update: handleUserTypingUpdate,
  send_chat_alert: handleChatAlerts,
}

const websocketSiteNotificationsActionMap: {
  [keys: string]: (
    profileUUID: string | undefined,
    data: ArgJSONMap,
    dispatch: Dispatch<SiteNotificationsActions>
  ) => void
} = {
  send_notification: handleNotification,
}

const userActionMap: {
  [keys: string]: (
    profileUUID: string | undefined,
    data: ArgJSONMap,
    dispatch: Dispatch<UserActions>
  ) => void
} = {
  update_promo_request_limit: handlePromoRequestLimitChange,
  email_update: handleEmailUpdate,
}

const websocketUserActionMap: {
  [keys: string]: (
    profileUUID: string | undefined,
    data: ArgJSONMap,
    balanceDispatch: Dispatch<BalanceActions>
  ) => void
} = {
  balance_update: handleBalanceUpdate,
}

const websocketUserMaintenanceActionMap: {
  [keys: string]: (
    profileUUID: string | undefined,
    data: ArgJSONMap,
    dispatch: Dispatch<UserActions>
  ) => void
} = {
  force_logout: handleForceLogout,
}

function parsePrivateMessage(
  p: ArgJSONMap,
  myProfileUUID: string | undefined
): IMessageProps {
  return {
    from: p.getString("fp") === myProfileUUID ? "User" : "Partner",
    date: new Date(p.getNumber("t") * 1000), // Convert seconds to milliseconds
    timestamp: p.getNumber("t"),
    message:
      p.getBoolean("blocked") === false
        ? p.getString("m")
        : p.getString("X-Denied"),
    isIgnored: p.getBoolean("ignored"),
    media: parsePhotos(p.getListOfType<object>("media")),
    type: "message",
    id: p.getString("i"),
  }
}

function parseHistoryPrivateMessage(
  p: ArgJSONMap,
  myProfileUUID: string | undefined
): IMessageProps {
  const fromUserData = new ArgJSONMap(p.getObjectString("from_user"))
  return {
    from: fromUserData.getString("uuid") === myProfileUUID ? "User" : "Partner",
    date: new Date(p.getNumber("t") * 1000), // Convert seconds to milliseconds
    timestamp: p.getNumber("t"),
    message: p.getString("m"),
    isIgnored: p.getBoolean("ignored"),
    media: parsePhotos(p.getListOfType<object>("media")),
    type: "message",
    id: p.getString("i"),
  }
}

function parseChatAlert(
  p: ArgJSONMap,
  myProfileUUID: string | undefined
): IChatAlertProps {
  const message = p.getMap("message") || {}
  const fromUserData = new ArgJSONMap(p.getObjectString("from_user"))

  return {
    from: fromUserData.getString("uuid") === myProfileUUID ? "User" : "Partner",
    message: p.getString("m") || message.getString("message"),
    left_message: p.getString("m_l") || message.getString("message_left"),
    right_message: p.getString("m_r") || message.getString("message_right"),
    notice_type: p.getString("notice_type") || message.getString("type"),
    timestamp: p.getNumber("t") || message.getNumber("timestamp"),
    type: "alert",
    date: new Date(p.getNumber("t") * 1000), // Convert seconds to milliseconds
    orderStatusLink: p.getString("order_status_link"),
    id: p.getString("i"),
  }
}

function parseChatConnected(p: ArgJSONMap): IChatConnectedResponse {
  return {
    otherProfileName: p.getString("to_profile"),
  }
}

function handleChatConnected(
  profileUUID: string | undefined,
  data: ArgJSONMap,
  dispatch: Dispatch<MessageActions>
): void {
  dispatch({
    type: MessageActionTypes.ChatConnected,
    payload: parseChatConnected(data),
  })
}

export function handleChatAlerts(
  profileUUID: string | undefined,
  data: ArgJSONMap,
  dispatch: Dispatch<MessageActions>
): void {
  const message = parseChatAlert(data, profileUUID)
  dispatch({
    type: MessageActionTypes.AlertMessage,
    payload: {
      alertMessage: message,
    },
  })
}
export function handleChatHistory(
  profileUUID: string | undefined,
  data: ArgJSONMap,
  dispatch: Dispatch<MessageActions>
): void {
  const messages = data.getList("messages") ?? []
  dispatch({
    type: MessageActionTypes.ChatHistory,
    payload: {
      historyPrivateMessages: messages.map((pm: ArgJSONMap) => {
        const message_type = pm.getString("type")
        if (message_type === "chat_alert") {
          return parseChatAlert(pm, profileUUID)
        } else {
          return parseHistoryPrivateMessage(pm, profileUUID)
        }
      }),
    },
  })
}

function handlePrivateMessage(
  profileUUID: string | undefined,
  data: ArgJSONMap,
  dispatch: Dispatch<MessageActions>
): void {
  // Todo: figure out what to create for blocked messages: some new component for system messages??
  const message = parsePrivateMessage(data, profileUUID)
  dispatch({
    type: MessageActionTypes.PrivateMessage,
    payload: {
      privateMessage: message,
    },
  })
}

function handleUnreadMessageCount(
  profileUUID: string | undefined,
  data: ArgJSONMap,
  dispatch: Dispatch<MessageActions>
): void {
  dispatch({
    type: MessageActionTypes.UpdateUnreadMessageCount,
    payload: {
      unreadMessageCount: data.getNumber("count"),
    },
  })
}

function handleUserTypingUpdate(
  profileUUID: string | undefined,
  data: ArgJSONMap,
  dispatch: Dispatch<MessageActions>
): void {
  if (data.getString("from_profile_uuid") !== profileUUID) {
    dispatch({
      type: MessageActionTypes.OtherUserTyping,
      payload: {
        otherUserTyping: data.getBoolean("is_typing"),
      },
    })
  }
}

function parseSiteNotification(data: ArgJSONMap): INotificationProps {
  return {
    profileImage: data.getString("avatar_thumbnail"),
    notificationText: data.getString("message"),
    timeStamp: data.getString("timestamp"),
    notificationSeen: data.getBoolean("read"),
    link: data.getString("link"),
    linkText: data.getString("link_text"),
    // Not implemented in the websocket yet. Below handled thru standard requests to the API, currently.
    // silenced: false, // Todo: how do we silence?
    notificationsDisabled: false, // todo: disabling notifications conflicts with Settings -> Notification settings. Need to ask PM about this.
    currentProfileUuid: "", // Not implemented in the websocket yet
    notificationUuid: "", // Not implemented in the websocket yet
    targetProfile: "", // Not implemented in the websocket yet
    sender: "", // Not implemented in the websocket yet
    senderName: "", // Not implemented in the websocket yet
  }
}

function parseSiteNotificationCount(data: ArgJSONMap): number {
  return data.getNumber("unread_notifications_count")
}

function handleNotification(
  profileUUID: string | undefined,
  data: ArgJSONMap,
  dispatch: Dispatch<SiteNotificationsActions>
): void {
  dispatch({
    type: ActionTypes.AddSiteNotification,
    payload: {
      siteNotification: parseSiteNotification(data),
    },
  })
  dispatch({
    type: ActionTypes.UpdateSiteNotificationCount,
    payload: {
      newSiteNotificationCount: parseSiteNotificationCount(data),
    },
  })
}

function parsePromoRequestsRemaining(data: ArgJSONMap): {
  promoRequestsRemaining: number
  userIdVerified: boolean
} {
  return {
    promoRequestsRemaining: data.getNumber("promo_request_limit_remaining"),
    userIdVerified: data.getBoolean("id_verified"),
  }
}

function parseEmail(data: ArgJSONMap): {
  email: string
  isEmailValidated: boolean
} {
  return {
    email: data.getString("email"),
    isEmailValidated: data.getBoolean("validated"),
  }
}

function handlePromoRequestLimitChange(
  profileUUID: string | undefined,
  data: ArgJSONMap,
  dispatch: Dispatch<UserActions>
): void {
  dispatch({
    type: UserActionTypes.PromoRequestsRemainingUpdate,
    payload: parsePromoRequestsRemaining(data),
  })
}

function handleEmailUpdate(
  profileUUID: string | undefined,
  data: ArgJSONMap,
  dispatch: Dispatch<UserActions>
): void {
  dispatch({
    type: UserActionTypes.ProfileEmailUpdate,
    payload: parseEmail(data),
  })
}

function handleBalanceUpdate(
  profileUUID: string | undefined,
  data: ArgJSONMap,
  balanceDispatch: Dispatch<BalanceActions>
): void {
  balanceDispatch({
    type: BalanceActionTypes.SetBalance,
    payload: {
      balance: data.getNumber("balance"),
    },
  })
}

function handleForceLogout(
  profileUUID: string | undefined,
  data: ArgJSONMap,
  dispatch: Dispatch<UserActions>
): void {
  dispatch({
    type: UserActionTypes.Logout,
    payload: {
      isAuthenticated: false,
    },
  })
  window.location.replace("/")
}

// these are actions in the Websocket backend so don't change these strings to camel case
export enum ChatActions {
  JoinChat = "join_chat",
  Disconnected = "disconnected",
  Reconnected = "reconnected",
}

interface IChannelSubscription {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  callback: (...args: any[]) => void
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  args: any[]
}

export class MyWebsocket {
  private socket?: WebSocket
  private isReconnecting: boolean
  private url: string
  private chatMessageDispatch: Dispatch<MessageActions>
  private notificationDispatch: Dispatch<SiteNotificationsActions>
  private userDispatch: Dispatch<UserActions>
  private balanceDispatch: Dispatch<BalanceActions>
  private requestID: number
  private myProfileUUID: string | undefined
  private eventSubscriptions: { [key: string]: IChannelSubscription[] } = {}
  private reconnectionManager: ReconnectionManager

  constructor(
    myProfileUUID: string | undefined,
    chatMessageDispatch: Dispatch<MessageActions>,
    notificationDispatch: Dispatch<SiteNotificationsActions>,
    userDispatch: Dispatch<UserActions>,
    balanceDispatch: Dispatch<BalanceActions>
  ) {
    const websocketProtocol =
      window.location.host.indexOf("localhost") > -1 ? "ws" : "wss"
    this.url = `${websocketProtocol}://${
      process.env.NEXT_PUBLIC_WEBSOCKET_URL ?? window.location.host
    }/ws/${myProfileUUID}/`
    this.myProfileUUID = myProfileUUID
    this.isReconnecting = false
    this.chatMessageDispatch = chatMessageDispatch
    this.notificationDispatch = notificationDispatch
    this.userDispatch = userDispatch
    this.balanceDispatch = balanceDispatch
    this.requestID = 0
    this.reconnectionManager = new ReconnectionManager()
    let reconnectingTimer: NodeJS.Timeout | null = null
    this.subscribe(ChatActions.Disconnected, () => {
      if (reconnectingTimer === null) {
        reconnectingTimer = setTimeout(() => {
          this.chatMessageDispatch({
            type: MessageActionTypes.Reconnecting,
            payload: {
              reconnecting: true,
            },
          })
        }, 10000)
      }
    })
    this.subscribe(ChatActions.Reconnected, () => {
      if (reconnectingTimer !== null) {
        clearTimeout(reconnectingTimer)
        reconnectingTimer = null
      }
      this.chatMessageDispatch({
        type: MessageActionTypes.Reconnecting,
        payload: {
          reconnecting: false,
        },
      })
    })
    this.recreateWebsocket()

    window.addEventListener("offline", () => {
      this.close()
    })

    window.addEventListener("online", () => {
      this.recreateWebsocket()
    })
  }

  public get reconnecting(): boolean {
    return this.isReconnecting
  }

  public close(): void {
    if (this.websocket !== undefined) {
      this.publish(ChatActions.Disconnected)
      // Remove listeners in Javascript event loop so UI updates aren't sent to old websockets that are no longer in use
      this.websocket.onopen = null
      this.websocket.onclose = null
      this.websocket.onerror = null
      this.websocket.onmessage = null
      this.websocket.close()
      this.websocket = undefined
    }
  }

  private recreateWebsocket(): void {
    this.isReconnecting = true
    this.websocket = new WebSocket(this.url)
    this.websocket.onopen = (): void => {
      this.publish(ChatActions.Reconnected)
      this.publish(ChatActions.JoinChat)
      this.isReconnecting = false
      this.reconnectionManager.reset()
    }

    this.websocket.onmessage = (e): void => {
      const data = new ArgJSONMap(e.data)
      const type = data.getString("type")

      if (Object.keys(websocketChatActionMap).indexOf(type) > -1) {
        websocketChatActionMap[type](
          this.myProfileUUID,
          data,
          this.chatMessageDispatch
        )
      } else if (
        Object.keys(websocketSiteNotificationsActionMap).indexOf(type) > -1
      ) {
        websocketSiteNotificationsActionMap[type](
          this.myProfileUUID,
          data,
          this.notificationDispatch
        )
      } else if (Object.keys(userActionMap).indexOf(type) > -1) {
        userActionMap[type](this.myProfileUUID, data, this.userDispatch)
      } else if (
        Object.keys(websocketUserMaintenanceActionMap).indexOf(type) > -1
      ) {
        websocketUserMaintenanceActionMap[type](
          this.myProfileUUID,
          data,
          this.userDispatch
        )
      } else if (Object.keys(websocketUserActionMap).indexOf(type) > -1) {
        websocketUserActionMap[type](
          this.myProfileUUID,
          data,
          this.balanceDispatch
        )
      }
    }

    this.websocket.onclose = (e: CloseEvent): void => {
      if (e.code !== 4500) {
        this.isReconnecting = true
        this.publish(ChatActions.Disconnected)
        this.close()
        setTimeout(() => {
          this.recreateWebsocket()
        }, this.reconnectionManager.getReconnectionMilliseconds())
      }
    }

    this.websocket.onerror = (): void => {
      this.isReconnecting = true
      this.publish(ChatActions.Disconnected)
      this.close()
      setTimeout(() => {
        this.recreateWebsocket()
      }, this.reconnectionManager.getReconnectionMilliseconds())
    }
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public subscribe(
    event: ChatActions,
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    callback: (...args: any[]) => void,
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    args: any[] = []
  ): void {
    if (!(event in this.eventSubscriptions)) {
      this.eventSubscriptions[event] = []
    }
    this.eventSubscriptions[event].push({
      args,
      callback,
    })
  }

  public unsubscribe(event: string): void {
    if (event in this.eventSubscriptions) {
      delete this.eventSubscriptions[event]
    }
  }

  private publish(event: string): void {
    if (event in this.eventSubscriptions) {
      this.eventSubscriptions[event].forEach(
        (subscription: IChannelSubscription) => {
          subscription.callback(...subscription.args)
        }
      )
    }
  }

  private set websocket(socket: WebSocket | undefined) {
    this.socket = socket
  }

  private get websocket(): WebSocket | undefined {
    return this.socket
  }

  public joinPMChat(toProfileUUID: string, myProfileUuid: string): boolean {
    let success: boolean
    if (this.websocket !== undefined) {
      try {
        this.websocket.send(
          JSON.stringify({
            action: ChatActions.JoinChat,
            to_profile_uuid: toProfileUUID,
            request_id: this.requestID,
            my_profile_uuid: myProfileUuid,
          })
        )
        success = true
        this.requestID++
        // Only one chat window's updates can be subscribed to at a time in this browser's tab
        this.unsubscribe(ChatActions.JoinChat)
        this.subscribe(
          ChatActions.JoinChat,
          () => this.joinPMChat(toProfileUUID, myProfileUuid),
          [toProfileUUID]
        )
      } catch (e) {
        console.warn(e)
        success = false
      }
    } else {
      success = false
    }
    return success
  }

  public updateUserTyping(
    isTyping: boolean,
    toProfileUuid: string,
    fromProfileUuid: string
  ): boolean {
    let success: boolean
    if (this.websocket !== undefined) {
      try {
        this.websocket.send(
          JSON.stringify({
            action: "send_user_typing",
            is_typing: isTyping,
            to_profile_uuid: toProfileUuid,
            from_profile_uuid: fromProfileUuid,
            request_id: this.requestID,
          })
        )
        success = true
      } catch (e) {
        success = false
      }
      this.requestID++
    } else {
      success = false
    }
    return success
  }

  private sendChangeProfileMessage(myProfileUuid: string): boolean {
    let success: boolean
    if (this.websocket !== undefined) {
      try {
        this.websocket.send(
          JSON.stringify({
            action: "change_profile",
            profile_uuid: myProfileUuid,
            request_id: this.requestID,
          })
        )
        success = true
      } catch (e) {
        success = false
      }
      this.requestID++
    } else {
      success = false
    }
    return success
  }

  public changeProfile(profileUuid: string): boolean {
    this.myProfileUUID = profileUuid
    this.isReconnecting = false
    this.requestID = 0
    return this.sendChangeProfileMessage(this.myProfileUUID)
  }
}

class ReconnectionManager {
  private timeoutIncreaseLimit = 5
  private attempts = 0
  private baseTimeout = 5000
  private maxTimeout = 30000

  public getReconnectionMilliseconds(): number {
    if (this.attempts < this.timeoutIncreaseLimit) {
      this.attempts++
      return this.attempts * this.baseTimeout
    } else {
      return this.maxTimeout
    }
  }

  public reset(): void {
    this.attempts = 0
  }
}
