import styled from "@emotion/styled"
import { Grid, Link, Typography } from "@mui/material"
import { useTheme } from "@material-ui/core/styles"
import BlossmLogoSmall from "../../assets/BlossmLogoSmall.svg"
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined"
import { ReactElement, useMemo } from "react"
import { DownloadOutlined } from "@mui/icons-material"
import { parseTimestampForLocaleDateString } from "../../../utils/parsers"
import { ONLY_FANS_GOOGLE_CHROME_EXT_URL } from "../../../utils/constants"
import moment from "moment"

export interface IChatAlertProps {
  from: "Partner" | "User"
  message: string
  left_message: string
  right_message: string
  notice_type: string
  type: "alert"
  timestamp: number
  date: Date
  orderStatusLink: string
  id: string
}

export const Alert = (props: IChatAlertProps): JSX.Element => {
  const theme = useTheme()
  const message = useMemo(() => props.message, [props.message])
  const from = useMemo(() => props.from, [props.from])
  const left_message = useMemo(() => props.left_message, [props.left_message])
  const right_message = useMemo(
    () => props.right_message,
    [props.right_message]
  )
  const notice_type = useMemo(() => props.notice_type, [props.notice_type])
  const timestamp = useMemo(() => props.timestamp, [props.timestamp])
  const date = useMemo(() => props.date, [props.date])
  const orderStatusLink = useMemo(
    () => props.orderStatusLink,
    [props.orderStatusLink]
  )
  const installExt = useMemo(
    () => message.includes("install the Blossm Extension"),
    [message]
  )

  const getColor = useMemo(() => {
    switch (notice_type) {
      case "error":
      case "warning":
        return theme.palette.warning.main
      default:
        return theme.palette.primary.main
    }
  }, [notice_type])

  const GetIcon = (): ReactElement => {
    switch (notice_type) {
      case "error":
      case "warning":
        return (
          <WarningAmberOutlinedIcon color={"warning"} fontSize={"medium"} />
        )
      default:
        return (
          <img
            height={36}
            width={22}
            alt={"blossm logo"}
            src={BlossmLogoSmall.src}
          />
        )
    }
  }

  if (from === "User") {
    return <></>
  }

  return (
    <StyledMessage type={notice_type}>
      {parseTimestampForLocaleDateString(timestamp, date) && (
        <div className="timeStamp">
          <Typography
            color={theme.palette.text.secondary}
            variant={"caption"}
            align={"center"}
          >
            {parseTimestampForLocaleDateString(timestamp, date)}
          </Typography>
        </div>
      )}
      <StyledAlert theme={theme} borderColor={getColor}>
        <GetIcon />
        <Grid
          container
          sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
        >
          <Grid item>
            <Typography
              variant="subtitle2"
              fontWeight="600"
              component="p"
              color={getColor}
            >
              {message}
            </Typography>
          </Grid>
          <Grid item width={"fit-content"}>
            {orderStatusLink && (
              <Link href={orderStatusLink}>
                <Typography variant="subtitle2" fontWeight="600" component="p">
                  View Order Details
                </Typography>
              </Link>
            )}
          </Grid>
          <Grid item>
            <Grid
              container
              spacing={2}
              columns={16}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Grid item>
                <Typography variant="subtitle2" component="p" color={getColor}>
                  {moment(left_message).calendar()}
                </Typography>
              </Grid>
              {right_message && (
                <>
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      component="p"
                      color={getColor}
                      fontWeight="400"
                    >
                      <svg
                        width="5"
                        height="5"
                        viewBox="0 0 5 5"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="2.97168"
                          cy="2.29297"
                          r="2"
                          fill={getColor}
                        />
                      </svg>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      component="p"
                      fontWeight="400"
                      color={getColor}
                    >
                      {right_message}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        {installExt && (
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 45,
            }}
          >
            <Link
              alignSelf={"center"}
              sx={{
                textDecoration: "none",
                fontSize: "15px",
                fontWeight: 400,
                lineHeight: "26px",
                display: "flex",
                alignContent: "center",
                gap: "10px",
                justifyContent: "center",
                cursor: "pointer",
              }}
              href={ONLY_FANS_GOOGLE_CHROME_EXT_URL}
              target="_blank"
            >
              <DownloadOutlined fontSize={"small"} color={"primary"} />
            </Link>
          </Grid>
        )}
      </StyledAlert>
    </StyledMessage>
  )
}

export const StyledMessage = styled.div<{ type: string }>`
  display: flex;
  flex-direction: column;
  padding: 4px 10px;
  gap: 4px;

  .timeStamp {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 12px;
  }
`

const StyledAlert = styled.div<{ borderColor: string }>`
  display: flex;
  padding: 6px 16px 6px 16px;
  gap: 16px;
  border: 1px solid ${(props) => props.borderColor};
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: 4px;
  align-items: center;
`
