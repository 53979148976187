import DownloadIcon from "@mui/icons-material/Download"
import useDownloadResource from "../../hooks/useDownloadResource"
import { IPhoto } from "../../utils/photos"

interface IDownload {
  files: IPhoto[]
}

export const Download = ({ files }: IDownload): JSX.Element => {
  const { download } = useDownloadResource()

  return (
    <div onClick={() => download(files)} style={{ cursor: "pointer" }}>
      <DownloadIcon fontSize={"small"} color="primary" />
    </div>
  )
}
