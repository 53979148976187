import { createTheme } from "@mui/material/styles"
import "typeface-inter"
import "@mui/material/styles"
import "@mui/material/Typography"
import { Colors } from "../src/styles/Colors"

// Extending the default typography variants with custom types so Typescript doesn't throw errors.
declare module "@mui/material/styles" {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface TypographyVariants {
    badge: React.CSSProperties
    caption2: React.CSSProperties
    subtitle3: React.CSSProperties
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface TypeText {
    solid: string
    primary: string
    secondary: string
    disabled: string
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Palette {
    buttonTextPrimary: Palette["primary"]
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface PaletteOptions {
    buttonTextPrimary: PaletteOptions["primary"]
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface PaletteColor {
    solid?: string
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface SimplePaletteColorOptions {
    solid?: string
  }

  // allow configuration using `createTheme`
  // eslint-disable-next-line @typescript-eslint/naming-convention
  // allow configuration using `createTheme`
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface TypographyVariantsOptions {
    badge: React.CSSProperties
    caption2: React.CSSProperties
    subtitle3: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface TypographyPropsVariantOverrides {
    badge: true
    caption2: true
    subtitle3: true
  }
}

// Override the color prop's allowed strings to include new custom defined colors.
declare module "@mui/material/Button" {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface ButtonPropsColorOverrides {
    buttonTextPrimary: true
  }
}

export const theme = createTheme({
  breakpoints: {
    values: {
      //mobile starts theoretically at 0
      xs: 380, // small mobile devices detected here
      sm: 768, // tablet size here and up, mobile ends here
      md: 1024, // desktop here + larger, tablet ends here
      lg: 1440,
      xl: 1920,
    },
  },
  typography: {
    allVariants: {
      color: "rgba(0,0,0,.87)",
    },
    fontFamily: "Inter",
    badge: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "13px",
      lineHeight: "20px",
      letterSpacing: "0.14px",
    },
    caption2: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "150%",
      letterSpacing: "0.2px",
    },
    subtitle3: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "150%",
      letterSpacing: "0.15px",
    },
  },
  palette: {
    error: {
      main: "#EF5350",
      light: "#E57373",
    },
    primary: {
      contrastText: Colors.white,
      main: "#5d54db",
      light: "#a2b6ff",
    },
    secondary: {
      contrastText: "rgba(0,0,0,.87)",
      dark: "#2b2b2b",
      light: "#D0FA5F",
      main: "#DCE3FF",
    },
    action: {
      selected: "rgba(0, 137, 123, 1)",
      active: "rgba(0, 137, 123, 1)",
      hoverOpacity: 0.4,
    },
    buttonTextPrimary: {
      light: "rgba(0, 0, 0, 0.60)",
      main: "rgba(0, 0, 0, 0.87)",
      dark: "rgba(0, 0, 0, 1)",
    },
    text: {
      solid: "rgba(0, 0, 0, 1)",
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
    },
    background: {
      paper: "rgba(255, 255, 255, 1.0)",
      default: "rgba(250, 250, 250, 1.0)",
    },
  },
})

theme.typography.h1 = {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "96px",
  lineHeight: "125%",
  letterSpacing: "-1.5px",
}

theme.typography.h2 = {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "60px",
  lineHeight: "125%",
  letterSpacing: "-0.5px",
}

theme.typography.h3 = {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "48px",
  lineHeight: "125%",
  letterSpacing: "0px",
}

theme.typography.h4 = {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "34px",
  lineHeight: "125%",
  letterSpacing: "0px",
}

theme.typography.h5 = {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "24px",
  lineHeight: "125%",
}

theme.typography.h6 = {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "20px",
  lineHeight: "125%",
}

theme.typography.body1 = {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "125%",
}

theme.typography.body2 = {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "125%",
}

theme.typography.subtitle1 = {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "150%",
  letterSpacing: "0px",
}

theme.typography.subtitle2 = {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "150%",
  letterSpacing: "0px",
}

theme.typography.overline = {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "200%",
  letterSpacing: "0px",
  textTransform: "uppercase",
}

theme.typography.caption = {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "150%",
  letterSpacing: "0px",
}

theme.typography.button = {
  fontFamily: "Inter",
  fontSize: "15px",
  fontWeight: 500,
  lineHeight: "26px",
  letterSpacing: "0.46000000834465027px",
  textAlign: "left",
}
