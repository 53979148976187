// ArgJSONMap will report if you forget to parse something if you call `logUnusedDebugging`.
// `logUnusedDebugging` doesn't output anything if compiled with PRODUCTION=true
export class ArgJSONMap {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private parsed: { [key: string]: any } = {}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private usedFields: { [key: string]: any } = {}

  constructor(public readonly stringMessage: string) {
    try {
      this.parsed = JSON.parse(stringMessage)
    } catch (e) {
      console.error("Cannot JSON parse", { message: stringMessage })
      this.parsed = {}
    }
  }

  unusedFields(): string[] {
    const fields: string[] = []
    for (const key in this.parsed) {
      if (this.usedFields[key] !== true) {
        fields.push(key)
      }
    }
    return fields
  }

  ignore(key: string): void {
    this.usedFields[key] = true
  }

  getAny(key: string): object | boolean | string | number | undefined {
    this.ignore(key)
    if (this.parsed[key] === null) {
      return undefined
    }
    return this.parsed[key]
  }

  getList(key: string): ArgJSONMap[] | undefined {
    this.ignore(key)
    if (this.parsed[key] === null) {
      return undefined
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.parsed[key].map((item: any) => ArgJSONMap.fromParsedJson(item))
  }

  getListOfType<T>(key: string, reportError = true): T[] {
    const x = this.getAny(key)
    if (typeof x !== "object") {
      if (reportError) {
        console.warn(`getListOfType(${key}): ${x} is wrong type`, {
          message: this.stringMessage,
        })
      }
      return []
    }
    return <T[]>x
  }

  getAsString(key: string): string {
    const x = this.getAny(key)
    return `${x}`
  }

  getStringOrUndefined(key: string, reportError = true): string | undefined {
    const x = this.getAny(key)
    if (typeof x !== "string") {
      if (reportError && x !== undefined) {
        console.warn(`getStringOrUndefined(${key}): ${x} is wrong type`, {
          message: this.stringMessage,
        })
      }
      return undefined
    }
    return x
  }

  getString(key: string, reportError = true): string {
    const x = this.getAny(key)
    if (typeof x !== "string") {
      if (reportError) {
        console.warn(`getString(${key}): ${x} is wrong type`, {
          message: this.stringMessage,
        })
      }
      return ""
    }
    return x
  }

  getStringWithNumbers(key: string, reportError = true): string {
    const x = this.getAny(key)
    if (typeof x !== "string" && typeof x !== "number") {
      if (reportError) {
        console.warn(`getStringWithNumbers(${key}): ${x} is wrong type`, {
          message: this.stringMessage,
        })
      }
      return ""
    }
    return `${x}`
  }

  getNumberFromString(key: string, reportError = true): number {
    const x = Number(this.getStringWithNumbers(key))
    if (isNaN(Number(x))) {
      if (reportError) {
        console.warn(`getNumberFromString(${key}): ${x} is wrong type`, {
          message: this.stringMessage,
        })
      }
    }
    return x
  }

  getStrings(keys: string[]): Map<string, string> {
    const foundKeys = new Map()
    for (const key of keys) {
      if (this.getStringOrUndefined(key, false) !== undefined) {
        foundKeys.set(key, this.getStringOrUndefined(key, false))
      }
    }
    return foundKeys
  }

  getBoolean(key: string, defaultValue = false, reportError = true): boolean {
    const x = this.getAny(key)
    if (typeof x !== "boolean") {
      if (reportError) {
        console.warn(`getBoolean(${key}): ${x} is wrong type`, {
          message: this.stringMessage,
        })
      }
      return defaultValue
    }
    return x
  }

  getNumber(key: string, reportError = true): number {
    const x = this.getAny(key)
    if (typeof x !== "number") {
      if (reportError) {
        console.warn(`getNumber(${key}): ${x} is wrong type`, {
          message: this.stringMessage,
        })
      }
      return NaN
    }
    return x
  }

  getNumberOrUndefined(key: string, reportError = true): number | undefined {
    const x = this.getAny(key)
    if (typeof x !== "number") {
      if (reportError && x !== undefined) {
        console.warn(`getNumberOrUndefined(${key}): ${x} is wrong type`, {
          message: this.stringMessage,
        })
      }
      return undefined
    }
    return x
  }

  getMap(key: string): ArgJSONMap {
    const x = this.getObjectString(key)
    if (x === "") {
      return new ArgJSONMap("{}")
    }
    return new ArgJSONMap(x)
  }

  getObjectString(key: string, reportError = true): string {
    const x = this.getAny(key)
    if (typeof x !== "object") {
      if (reportError) {
        console.warn(`getObjectString(${key}): ${x} is wrong type`, {
          message: this.stringMessage,
        })
      }
      return ""
    }
    return JSON.stringify(x)
  }

  getObjectStringOrUndefined(
    key: string,
    reportError = true
  ): string | undefined {
    const x = this.getAny(key)
    if (typeof x !== "object") {
      if (reportError && x !== undefined) {
        console.warn(`getObjectStringOrUndefined(${key}): ${x} is wrong type`, {
          message: this.stringMessage,
        })
      }
      return undefined
    }
    return JSON.stringify(x)
  }

  getObject(key: string, reportError = true): object {
    const x = this.getAny(key)
    if (typeof x !== "object") {
      if (reportError) {
        console.warn(`getObject(${key}): ${x} is wrong type`, {
          message: this.stringMessage,
        })
      }
      return {}
    }
    return x
  }

  logUnusedDebugging(moduleName: string): void {
    for (const key of this.unusedFields()) {
      const value =
        typeof this.parsed[key] === "object"
          ? JSON.stringify(this.parsed[key])
          : this.parsed[key]
      console.debug(
        `Unhandled message argument: . Key:${key} Value:${value} in ${moduleName}`
      )
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromParsedJson(parsed: any): ArgJSONMap {
    const argJsonMap = new ArgJSONMap("{}")
    argJsonMap.parsed = parsed
    return argJsonMap
  }
}
