import {styled} from "@mui/system"
import {
    AccountCircle,
    ArrowForward,
    CalendarToday,
} from "@mui/icons-material"

export const PrimaryColorArrowForward = styled(ArrowForward)(({theme}) => ({
    color: theme.palette.primary.main,
}))

export const PrimaryColorCalendarToday = styled(CalendarToday)(({theme}) => ({
    color: theme.palette.primary.main,
}))

export const PrimaryColorAccountCircle = styled(AccountCircle)(({theme}) => ({
    color: theme.palette.primary.main,
}))
