import React, { Dispatch } from "react"
import { ActionMap } from "../utils/types"

export const initialState: WafflesType = {
  type: "", // Starting with an empty str is intended to use as a switch to check if the waffles have been loaded, before making breakfast decisions
  flags: [],
  switches: [],
}

export type WafflesType = {
  type: string
  flags: string[]
  switches: string[]
}

export enum ActionTypes {
  PopulateWaffles = "POPULATEWAFFLES",
  DropWaffles = "DROPWAFFLES",
}

type WafflePayload = {
  [ActionTypes.PopulateWaffles]: WafflesType
  [ActionTypes.DropWaffles]: WafflesType
}

export type WafflesActions =
  ActionMap<WafflePayload>[keyof ActionMap<WafflePayload>]

export function waffleReducer(
  state: WafflesType = initialState,
  action: WafflesActions
): WafflesType {
  let newState
  switch (action.type) {
    case ActionTypes.PopulateWaffles:
      newState = {
        ...state,
        type: action.payload.type,
        flags: action.payload.flags,
        switches: action.payload.switches,
      }
      break

    case ActionTypes.DropWaffles:
      newState = initialState
      break

    default:
      newState = {
        ...state,
      }
      break
  }

  return newState
}

export const WafflesContext = React.createContext<{
  wafflesContext: WafflesType
  wafflesDispatch: Dispatch<WafflesActions>
}>({ wafflesContext: initialState, wafflesDispatch: () => null })
