import styled from "@emotion/styled"
import {
  Badge,
  Divider,
  SwipeableDrawer,
  Theme,
  Typography,
  useTheme,
} from "@mui/material"
import React, { useContext, useState } from "react"
import { Verified } from "./Verified"
import {
  AddBoxOutlined,
  AlternateEmail,
  ArrowDropDown,
  AttachMoney,
  Check,
  HelpOutline,
  Inventory2Outlined,
  Logout,
  MailOutlined,
  NotificationsOutlined,
  RocketLaunchOutlined,
  Settings,
  ShoppingCartOutlined,
  Star,
} from "@mui/icons-material"
import Link from "next/link"
import { SelectProfileRadioButtons } from "../Organisms/SelectProfileRadioButtons"
import { PopUp } from "./PopUp"
import { ModalLogout } from "../Molecules/ModalLogout/ModalLogout"
import { logout } from "../../utils/requests"
import { ActionTypes, UserContext } from "../../contexts/user"
import { useRouter } from "next/router"
import { Colors } from "../../styles/Colors"
import { Avatar } from "./Avatar"
import instagram from "../assets/Icons/Black/Instagram.svg"
import twitter from "../assets/Icons/Black/Twitter.svg"
import tiktok from "../assets/Icons/Black/TikTok.svg"
import telegram from "../assets/Icons/Black/Telegram.svg"
import {
  SiteNotificationsContext,
  ActionTypes as SiteNotificationActionTypes,
} from "../../contexts/siteNotificationContext"
import { SiteActionRequiredContext } from "../../contexts/siteActionRequiredContext"
import { MessageContext } from "../../contexts/websocket"
import { BalanceContext } from "../../contexts/balance"

import {
  WafflesContext,
  ActionTypes as WaffleActionTypes,
  initialState as waffleInitialState,
} from "../../contexts/waffles"
import { BlossmImage } from "./BlossmImage"

export interface ISettingsLauncherProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onClose: () => void
  onOpen: () => void
  profileImage: string
  profileName: string
  online: boolean
  verified: boolean
  userName: string
  handleLogOut: () => void
}

export const SettingsLauncher = ({
  open,
  setOpen,
  onClose,
  onOpen,
  profileImage,
  profileName,
  userName,
  handleLogOut,
  verified,
}: ISettingsLauncherProps): JSX.Element => {
  const theme = useTheme()
  const { dispatch } = useContext(UserContext)
  const [profileSelectionOpen, setProfileSelectionOpen] = useState(false)
  const [logoutOpen, setLogoutOpen] = useState(false)
  const router = useRouter()
  const { context } = useContext(UserContext)
  const { messageContext } = useContext(MessageContext)
  const { wafflesContext, wafflesDispatch } = useContext(WafflesContext)

  const { balanceContext } = useContext(BalanceContext)
  const createUrl = context.userIdVerified
    ? "/create"
    : "/id-verification/verify?source=navclick"

  const { siteNotificationsContext, siteNotificationsDispatch } = useContext(
    SiteNotificationsContext
  )
  const { siteActionRequiredContext } = useContext(SiteActionRequiredContext)
  const logOutModal = (): void => {
    logout()
      .then(() => {
        handleLogOut()
        dispatch({
          type: ActionTypes.Logout,
          payload: {
            isAuthenticated: false,
          },
        })
        siteNotificationsDispatch({
          type: SiteNotificationActionTypes.ResetSiteNotification,
          payload: {
            siteNotification: null,
          },
        })
        wafflesDispatch({
          type: WaffleActionTypes.DropWaffles,
          payload: waffleInitialState,
        })

        setLogoutOpen(false)
        router.push("/")
      })
      .catch(() => {
        setLogoutOpen(false)
      })
  }

  return (
    <>
      <ModalLogout
        open={logoutOpen}
        title="Log Out?"
        text="Are you sure you want to log out?"
        onHandle={logOutModal}
        onClose={() => {
          setLogoutOpen(false)
        }}
      />
      <SwipeableDrawer
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        anchor={"right"}
        onClick={() => {
          setOpen(false)
        }}
      >
        <StyledSettingsLauncher theme={theme}>
          <div className={"setting-launcher-header"}>
            {profileImage && (
              <Link href={"/" + context.profileName}>
                <div
                  className={"settings-launcher-header-image-container"}
                  style={{ cursor: "pointer" }}
                >
                  <BlossmImage
                    src={context.avatarUrl}
                    alt={"profileImage"}
                    height={100}
                    width={100}
                  />
                </div>
              </Link>
            )}
            <span
              className={"setting-launcher-header-row"}
              onClick={() => setProfileSelectionOpen(true)}
            >
              <Typography noWrap variant={"subtitle1"}>
                {profileName}
              </Typography>
              {verified && <Verified size={"Small"} />}
              <ArrowDropDown sx={{ color: theme.palette.text.secondary }} />
            </span>
            <Typography color={theme.palette.primary.main} variant={"body2"}>
              @{userName}
            </Typography>
          </div>
          <Divider />
          <div className={"settings-launcher-body"}>
            <Link href={"/notifications"}>
              <div className={"settings-launcher-body-row"}>
                <Badge
                  badgeContent={
                    siteNotificationsContext.newSiteNotificationCount
                  }
                  color="primary"
                >
                  <NotificationsOutlined
                    sx={{ color: theme.palette.text.secondary }}
                  />
                </Badge>
                <Typography color={"inherit"} variant={"body1"}>
                  Notifications
                </Typography>
              </div>
            </Link>
            <Link href={"/orders"}>
              <div className={"settings-launcher-body-row"}>
                <Badge
                  badgeContent={
                    siteActionRequiredContext.actionRequiredNotifications
                      ? siteActionRequiredContext.actionRequiredNotifications
                      : 0
                  }
                  color="primary"
                >
                  <Inventory2Outlined
                    sx={{ color: theme.palette.text.secondary }}
                  />
                </Badge>

                <Typography color={"inherit"} variant={"body1"}>
                  My Orders
                </Typography>
              </div>
            </Link>
            <Link href={"/" + userName}>
              <div className={"settings-launcher-body-row"}>
                <div className={"settings-launcher-profile-icon-container"}>
                  <Avatar
                    online={false}
                    profileImage={context.avatarUrl}
                    size={"Small"}
                  />
                </div>
                <Typography variant={"body1"}>My Profile</Typography>
              </div>
            </Link>
            {wafflesContext.type !== "" &&
              wafflesContext.flags.indexOf("show_traffic_coming_soon") > -1 && ( // Waffle Check
                <Link href={"/traffic"}>
                  <div className={"settings-launcher-body-row"}>
                    <RocketLaunchOutlined
                      sx={{ color: theme.palette.text.secondary }}
                    />
                    <Typography color={"inherit"} variant={"body1"}>
                      Buy Traffic
                    </Typography>
                  </div>
                </Link>
              )}
            {!context.userIdVerified && (
              <Link href={"/id-verification/verify?source=navclick"}>
                <div className={"settings-launcher-body-row"}>
                  <div className={"verify-check-background"}>
                    <Check sx={{ color: Colors.white }} fontSize={"small"} />
                  </div>
                  <Typography variant={"body1"}>Verify ID</Typography>
                  <div className={"verify-dot"} />
                </div>
              </Link>
            )}
            <Link href={createUrl}>
              <div className={"settings-launcher-body-row"}>
                <AddBoxOutlined sx={{ color: theme.palette.text.secondary }} />
                <Typography variant={"body1"}>Create</Typography>
              </div>
            </Link>
            <Link href={`/add-accounts?profileName=${context.profileName}`}>
              <div className={"settings-launcher-body-row"}>
                <AlternateEmail sx={{ color: theme.palette.text.secondary }} />
                <Typography variant={"body1"}>My Socials</Typography>
              </div>
            </Link>
            <Link href={"/activity"}>
              <div className={"settings-launcher-body-row"}>
                <ShoppingCartOutlined
                  sx={{ color: theme.palette.text.secondary }}
                />
                <Typography variant={"body1"}>Activity</Typography>
              </div>
            </Link>
            <Link href={"/payout"}>
              <div className={"settings-launcher-body-row"}>
                <AttachMoney sx={{ color: theme.palette.text.secondary }} />
                <div>
                  <Typography variant={"body1"}>Balance</Typography>
                  <Typography color={"inherit"} variant={"body2"}>
                    $
                    {balanceContext.balance &&
                      balanceContext.balance >= 0 &&
                      balanceContext.balance.toFixed(2)}
                  </Typography>
                </div>
              </div>
            </Link>
            <Link href={"/settings/account"}>
              <div className={"settings-launcher-body-row"}>
                <Settings sx={{ color: theme.palette.text.secondary }} />
                <Typography variant={"body1"}>Settings</Typography>
              </div>
            </Link>
            <Link href={"/messages"}>
              <div className={"settings-launcher-body-row"}>
                <Badge
                  badgeContent={messageContext.unreadMessageCount}
                  color="primary"
                >
                  <MailOutlined sx={{ color: theme.palette.text.secondary }} />
                </Badge>
                <Typography color={"inherit"} variant={"body1"}>
                  Messages
                </Typography>
              </div>
            </Link>
            <a target="_blank" href={"https://blossm.zendesk.com/hc/en-us/"}>
              <div className={"settings-launcher-body-row"}>
                <HelpOutline sx={{ color: theme.palette.text.secondary }} />
                <Typography variant={"body1"}>Need Help?</Typography>
              </div>
            </a>
            <Link href={"/refer/" + context.profileName}>
              <div className={"settings-launcher-body-row"}>
                <Star sx={{ color: theme.palette.text.secondary }} />
                <Typography variant={"body1"}>Refer and Earn</Typography>
              </div>
            </Link>
            <div
              className={"settings-launcher-body-row"}
              onClick={() => setLogoutOpen(true)}
            >
              <Logout sx={{ color: theme.palette.text.secondary }} />
              <Typography variant={"body1"}>Log Out</Typography>
            </div>
            <InfoLinks>
              <Divider sx={{ width: "100%" }} />
              <Link href={"/terms"} className={"info-link"} target="_blank">
                <Typography variant={"body2"} sx={{ mt: 2, cursor: "pointer" }}>
                  Terms of Service
                </Typography>
              </Link>
              <Link href={"/privacy"} target="_blank">
                <Typography variant={"body2"} sx={{ cursor: "pointer" }}>
                  Privacy
                </Typography>
              </Link>
              <Link href={"/guidelines"} target="_blank">
                <Typography variant={"body2"} sx={{ cursor: "pointer" }}>
                  Guidelines
                </Typography>
              </Link>
              {/*<Link href={"/billing"}>*/}
              {/*    <Typography variant={"body2"} sx={{cursor: "pointer"}}>Billing</Typography>*/}
              {/*</Link>*/}
              <a
                href={
                  "https://blossm.zendesk.com/hc/en-us/requests/new?ticket_form_id=12510615290637"
                }
                target="_blank"
              >
                <Typography variant={"body2"} sx={{ cursor: "pointer" }}>
                  Feedback
                </Typography>
              </a>
              <a href={"https://blossmblog.com/"} target="_blank">
                <Typography variant={"body2"} sx={{ cursor: "pointer" }}>
                  Blossm Blog
                </Typography>
              </a>
              <Link href={"/media-kit"} target="_blank">
                <Typography variant={"body2"} sx={{ cursor: "pointer" }}>
                  Media Kit
                </Typography>
              </Link>
              <ContactBox>
                <a href={"https://www.instagram.com/blossmhq/"} target="_blank">
                  <img src={instagram.src} width={24} height={24} />
                </a>
                <a href={"https://twitter.com/blossmhq/"} target="_blank">
                  <img src={twitter.src} width={24} height={24} />
                </a>
                <a href={"https://www.tiktok.com/@blossmhq"} target="_blank">
                  <img src={tiktok.src} width={24} height={24} />
                </a>
                <a href={"https://t.me/blossmhq"} target="_blank">
                  <img src={telegram.src} width={24} height={24} />
                </a>
              </ContactBox>
            </InfoLinks>
          </div>
        </StyledSettingsLauncher>
      </SwipeableDrawer>
      <PopUp open={profileSelectionOpen} handleToggle={setProfileSelectionOpen}>
        <SelectProfileRadioButtons setDrawerOpen={setProfileSelectionOpen} />
      </PopUp>
    </>
  )
}

const InfoLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 16px;
`

const ContactBox = styled.div`
  display: flex;
  gap: 16px;
  padding-top: 16px;
`

const StyledSettingsLauncher = styled.div<{ theme: Theme }>`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 24px;
  height: 100%;

  .setting-launcher-header {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-bottom: 24px;

    .setting-launcher-header-row {
      display: flex;
      gap: 5px;
      padding-top: 12px;
      align-items: center;
      width: 100%;
      text-overflow: ellipsis;
      max-width: 250px;
    }
  }

  .settings-launcher-header-image-container {
    border-radius: 8px;
    border: 4px solid ${(props) => props.theme.palette.primary.light};
    width: 100px;
    height: 100px;
    box-sizing: content-box;
  }

  .settings-launcher-body {
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    padding-left: 16px;
    padding-right: 16px;
    gap: 24px;
    height: 100%;

    .settings-launcher-body-row {
      display: flex;
      gap: 32px;
      align-items: center;
      cursor: pointer;
      width: 100%;

      .verify-check-background {
        display: flex;
        width: 24px;
        height: 24px;
        background: ${(props) => props.theme.palette.info.light};
        border-radius: 50%;
        align-items: center;
        justify-content: center;
      }

      .verify-dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: ${(props) => props.theme.palette.warning.light};
      }
    }
  }

  .settings-launcher-profile-icon-container {
    overflow: hidden;
    position: relative;
    border-radius: 2px;
    width: 24px;
    height: 24px;
  }
`
