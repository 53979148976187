import {
  Dialog,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import styled from "@emotion/styled"
import { CloseSharp } from "@mui/icons-material"

export interface IPopUpProps {
  children: JSX.Element | JSX.Element[]
  open: boolean
  handleToggle: (open: boolean) => void
  size?: "small"
  title?: string
  forceDialog?: boolean
}

export const PopUp = ({
  children,
  open,
  handleToggle,
  size,
  title,
  forceDialog = false,
}: IPopUpProps): JSX.Element => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <>
      {!forceDialog && isMobile ? (
        <SwipeableDrawer
          anchor={"bottom"}
          open={open}
          onClose={() => handleToggle(false)}
          onOpen={() => handleToggle(true)}
          PaperProps={{
            sx: {
              borderTopLeftRadius: "1rem",
              borderTopRightRadius: "1rem",
            },
          }}
        >
          {children}
        </SwipeableDrawer>
      ) : (
        <Dialog
          maxWidth={size && size === "small" ? "xs" : "sm"}
          open={open}
          fullWidth
          onClose={() => handleToggle(false)}
          PaperProps={{
            sx: {
              borderRadius: "1rem",
            },
          }}
        >
          {title && (
            <CloseBar hasTitle={!!title}>
              {title && (
                <Typography variant={"body1"} fontWeight={"bold"}>
                  {title}
                </Typography>
              )}
              <CloseSharp
                sx={{ cursor: "pointer", color: theme.palette.text.secondary }}
                onClick={() => handleToggle(false)}
              />
            </CloseBar>
          )}
          {children}
        </Dialog>
      )}
    </>
  )
}

const CloseBar = styled.div<{ hasTitle: boolean }>`
  display: flex;
  width: 100%;
  justify-content: ${(props) =>
    props.hasTitle ? "space-between" : "flex-end"};
  padding: 16px;
  align-items: center;
`
