import { Box, Typography } from "@mui/material"
import { getCookieOrEmptyString } from "../../utils/requests"

export const ImpersonateBanner = (): JSX.Element => {
  const impersonateUsername = getCookieOrEmptyString("impersonate_username")

  return (
    <>
      {impersonateUsername && (
        <Box
          sx={{
            color: "#000000",
            justifyContent: "center",
            background: "#d0fa5f",
            padding: "16px",
            display: "flex",
            gap: "8px",
            a: { color: "#5d54db", "&:hover": { color: "#a2b6ff" } },
          }}
        >
          <Typography variant="body1" fontWeight="bold">
            Impersonating MyUser:{" "}
            <a href={`/${impersonateUsername}`}>{impersonateUsername}</a>
          </Typography>
          <Typography
            variant="body1"
            fontWeight="bold"
            sx={{ padding: "0 8px" }}
          >
            |
          </Typography>
          <Typography variant="body1" fontWeight="bold">
            <a
              href="/staff_tools/unimpersonate/"
              onClick={() => {
                window.sessionStorage.removeItem("profileUuid")
              }}
            >
              Stop Impersonating
            </a>
          </Typography>
        </Box>
      )}
    </>
  )
}
