import { Box, Typography, useTheme } from "@mui/material"
import Link from "next/link"
import { Avatar, IAvatarProps } from "../Avatar"
import { Verified } from "../Verified"
import { StaffBadge } from "../StaffBadge"
import React, { useMemo } from "react"

export interface IMessageHeaderProps {
  avatar: IAvatarProps
  username: string
  displayName: string
  verified: boolean
  isCustomerSupport: boolean
}

export const MessageHeader = (props: IMessageHeaderProps): JSX.Element => {
  const theme = useTheme()

  const avatar = useMemo(() => props.avatar, [props.avatar])

  const username = useMemo(() => props.username, [props.username])

  const displayName = useMemo(() => props.displayName, [props.displayName])

  const isVerified = useMemo(() => props.verified, [props.verified])
  const isCustomerSupport = useMemo(
    () => props.isCustomerSupport,
    [props.isCustomerSupport]
  )

  return (
    <Box
      sx={{
        background:
          "linear-gradient(180deg, " +
          theme.palette.primary.main +
          " -47.19%, rgba(255, 255, 255, 0) 85.47%)",
        padding: "12px",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          display: "inline-block",
          border: "4px solid #ffffff",
          borderRadius: avatar.profileImage?.includes("avatar_1")
            ? "50%"
            : "12%",
          cursor: "pointer",
        }}
      >
        <Link href={"/" + username}>
          {/*Scale of 1.2 is chosen to remove the built-in border of the default avatar svg*/}
          <Avatar
            online={avatar.online}
            profileImage={avatar.profileImage}
            size={"Extra Large"}
            defaultAvatarScale={1.2}
          />
        </Link>
      </Box>

      <Box
        sx={{
          margin: "2px 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Link href={"/" + username}>
          <Typography
            variant={"h6"}
            noWrap
            sx={{ display: "inline", marginRight: "5px" }}
          >
            {displayName}
          </Typography>
        </Link>
        {isVerified && <Verified size="Large" />}
      </Box>
      <Box
        sx={{
          margin: "2px 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isCustomerSupport && (
          <StaffBadge expand={true} sx={{ top: "0px", marginLeft: "2px" }} />
        )}
      </Box>
      <Link href={"/" + username}>
        <Typography
          variant={"body2"}
          noWrap
          sx={{ color: theme.palette.primary.main, cursor: "pointer" }}
        >
          @{username}
        </Typography>
      </Link>
    </Box>
  )
}
