import {
  Box,
  Chip,
  SxProps,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import SmallLogo from "../assets/Icons/Black/BlossmLogoSmallBlack.svg"
import React from "react"
import { BlossmImage } from "./BlossmImage"

export interface IStaffBadgeProps {
  expand?: boolean
  sx?: SxProps<Theme>
  disabled?: boolean
}

const chipStyling = (theme: Theme, expanded: boolean): SxProps => ({
  height: "20px",
  ...(!expanded && {
    width: "20px",
  }),
  backgroundColor: theme.palette.secondary.light,
  border: `1px solid ${theme.palette.secondary.dark}`,
  display: "inline-flex",
  position: "relative",
  top: "2px",
  ...(expanded && {
    paddingLeft: "7px",
    paddingRight: "7px",
    "& span": {
      paddingLeft: "4px",
      paddingRight: "0px",
    },
  }),
  ...(!expanded && {
    "& span": {
      padding: "0px",
    },
  }),
  "& .MuiChip-avatar": {
    margin: "0px !important",
    maxHeight: "12px",
    height: "12px",
    minHeight: "12px",
    width: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ...(!expanded && {
      "& span": {
        margin: "1px !important",
        width: "10px",
        height: "10px",
      },
    }),
  },
})
const boxStyling = (expanded: boolean): SxProps => ({
  position: "relative",
  ...(!expanded && {
    width: "13px !important",
    height: "13px !important",
  }),
})

export const StaffBadge = (props: IStaffBadgeProps): JSX.Element => {
  const expanded = props.expand || false
  const theme = useTheme()

  const chipFinalStyling = {
    ...chipStyling(theme, expanded),
    ...(props.sx || {}),
  }

  return (
    <Tooltip title="Blossm Support Staff">
      <Chip
        label={
          expanded ? (
            <Typography fontWeight={500} fontFamily={"Inter"} fontSize={"13px"}>
              Staff
            </Typography>
          ) : undefined
        }
        sx={{ ...chipFinalStyling }}
        avatar={
          <Box sx={{ ...boxStyling(expanded) }}>
            <BlossmImage
              layout={expanded ? "fill" : "fixed"}
              width={12}
              height={12}
              alt="viewable-image"
              src={SmallLogo}
            />
          </Box>
        }
      />
    </Tooltip>
  )
}
