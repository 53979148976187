/**
 * debug contains wrappers for console functions.  `log` is not included by design. In most cases,
 * you either want `debug` or `error`
 *
 * Nothing is exported because should be treated as a global module
 *
 * Variadic args (...args) are not supported on purpose.
 * This is because they polyfill, and that is breaking the ability for closure compuler
 * to remove unused code.
 Closure Compiler (http://github.com/google/closure-compiler)
 Version: v20170218
 Built on: 2017-02-23 11:19
 */

// we're going to call console in here, so we need to disable this linter
// tslint:disable:use-custom-console
// tslint:disable:no-any
// tslint:disable:strict-type-predicates
import { isProduction } from "./environment"
import SimpleType = newrelic.SimpleType

/**
 * @define {boolean}
 */
const SUPPRESS_LOGS = false

export const enum SubSystemType {
  Default = "",
}

export type OptionalAttributes<T> = {
  [K in keyof T]?: T[K]
}

function reportNewRelicError(
  level: string,
  errorObject: Error,
  attributes: Record<string, SimpleType> = {}
): void {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const newrelic = window["newrelic"]
  try {
    if (newrelic === undefined || !newrelic.noticeError) {
      return
    }
    const namespacedAttributes: Record<string, SimpleType> = {}
    namespacedAttributes["attributes.level"] = level
    for (const key in attributes) {
      if (Object.prototype.hasOwnProperty.call(attributes, key)) {
        namespacedAttributes[`attributes.${key}`] = attributes[key]
      }
    }
    newrelic.noticeError(errorObject, namespacedAttributes)
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    logError(
      `New Relic Error in reportError: ${
        typeof e === "object" ? e?.toString() : e
      }`
    )
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function objOrEmptyString(extra?: Record<string, SimpleType>): any {
  if (extra === undefined) {
    return ""
  }
  return extra
}

function consoleTrace(): void {
  if (SUPPRESS_LOGS || isProduction() || !process.browser) {
    return
  }
  if (window.console === undefined) {
    return
  }
  if (console.trace === undefined) {
    return
  }
  console.trace()
}

// error should be used when there is an error that we can recover from
export function logError(
  x: string,
  extra?: Record<string, SimpleType>,
  subSystemType = SubSystemType.Default
): void {
  if (SUPPRESS_LOGS || !process.browser) {
    return
  }
  if (window.console !== undefined) {
    if (console.error === undefined && !isProduction()) {
      console.log(subSystemType.concat("ERROR "), x, objOrEmptyString(extra))
    } else {
      console.error(subSystemType.concat("ERROR "), x, objOrEmptyString(extra))
    }
    consoleTrace()
  }
  let message: string
  try {
    message = typeof x === "object" ? JSON.stringify(x) : x.toString()
  } catch (e) {
    message = `${x}`
  }
  reportNewRelicError(subSystemType.concat("ERROR"), new Error(message), extra)
}

// debug should be used for all messages that shouldn't be seen by end users
// function debug(x: never, extra?: never): void {
//     if (window.location.search.indexOf("debug=1") === -1) {
//         if (SUPPRESS_LOGS) {
//             return
//         }
//         if (isProduction()) {
//             return
//         }
//     }
//     if (window.console !== undefined) {
//         if (console.debug === undefined && !isProduction()) {
//             console.log("DEBUG ", x, objOrEmptyString(extra))
//         } else {
//             console.debug(x, objOrEmptyString(extra))
//         }
//     }
// }

// // Global exception handler
// window.onerror = (msg: string, url?: string, lineNo?: number, columnNo?: number, err?: Error): boolean => {
//     let errorMessage: string
//     try {
//         errorMessage =  JSON.stringify(err)
//     } catch (e) {
//         errorMessage = `${err}`
//     }
//     const message = [
//         `"Message: " ${msg}`,
//         `"URL: "  ${url}`,
//         `"Line: " ${lineNo}`,
//         `"Column: " ${columnNo}`,
//         `"Error object: " ${errorMessage}`,
//     ].join("\n")
//     logError(message, {}, SubSystemType.Default, true)
//     return false // still log the global error
// }
