import styled from "@emotion/styled"
import { IMessageProps, Message } from "../../Atoms/Chat/Message"
import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { Theme, useTheme } from "@mui/material"
import { MessageActionTypes, MessageContext } from "../../../contexts/websocket"
import {
  IMessageHeaderProps,
  MessageHeader,
} from "../../Atoms/Chat/MessageHeader"
import { IChatAlertProps, Alert } from "../../Atoms/Chat/Alert"

export interface IMessageBoxProps {
  messageHeaderProps: IMessageHeaderProps
  messages: (IChatAlertProps | IMessageProps)[]
  mediaOpen: boolean
}

export const MessageBox = (props: IMessageBoxProps): JSX.Element => {
  const [otherUserTyping, setOtherUserTyping] = useState<boolean>(false)
  const container = useRef<HTMLDivElement>(null)
  const { messageContext, messageDispatch } = useContext(MessageContext)
  const theme = useTheme()

  const scrollToBottom = (): void => {
    if (container.current !== null) {
      container.current.scrollTop = container.current?.scrollHeight
    }
  }

  const messageHeaderProps = useMemo(
    () => props.messageHeaderProps,
    [props.messageHeaderProps]
  )
  const messages = useMemo(() => props.messages, [props.messages])
  const mediaOpen = useMemo(() => props.mediaOpen, [props.mediaOpen])
  const isOtherUserTyping = useMemo(
    () => messageContext.otherUserTyping,
    [messageContext.otherUserTyping]
  )

  useEffect(() => {
    scrollToBottom()
  }, [messages, mediaOpen])

  useEffect(() => {
    if (isOtherUserTyping !== otherUserTyping) {
      setOtherUserTyping(isOtherUserTyping)
    }
    if (isOtherUserTyping) {
      setTimeout(() => {
        messageDispatch({
          type: MessageActionTypes.OtherUserTyping,
          payload: {
            otherUserTyping: false,
          },
        })
      }, 60000)
    }
  }, [isOtherUserTyping])

  return (
    <StyledMessageBox theme={theme} ref={container}>
      <MessageHeader {...messageHeaderProps} />
      {messages.map((message) => {
        if (
          "isIgnored" in message &&
          message.isIgnored &&
          message.from === "Partner"
        ) {
          return
        }

        return (
          <span key={message.id}>
            {message.type === "message" && <Message {...message} />}
            {message.type === "alert" && <Alert {...message} />}
          </span>
        )
      })}
      {otherUserTyping && (
        <div className="dots-container">
          <span className="dots-pill">
            <span className="other-user-typing-dots" />
          </span>
        </div>
      )}
    </StyledMessageBox>
  )
}
const StyledMessageBox = styled.div<{ theme: Theme }>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.down("md")} {
    padding-top: 55px;
  }

  padding-bottom: ${(props) =>
    props.theme.breakpoints.up("sm") ? "8px" : "50px"};
  overflow-y: auto;
  .see-new-messages-button {
    position: absolute;
    bottom: 50px;
  }
  .other-user-typing-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${(props) => props.theme.palette.primary.main};
    color: ${(props) => props.theme.palette.text.primary};
    box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff,
      10014px 0 0 0 #9880ff;
    animation: dotTyping 1.5s infinite linear;
  }
  @keyframes dotTyping {
    0% {
      box-shadow: 9984px 0 0 0 ${(props) => props.theme.palette.grey.A200},
        9999px 0 0 0 ${(props) => props.theme.palette.grey.A200},
        10014px 0 0 0 ${(props) => props.theme.palette.grey.A200};
    }
    16.667% {
      box-shadow: 9984px -10px 0 0 ${(props) => props.theme.palette.grey.A200},
        9999px 0 0 0 ${(props) => props.theme.palette.grey.A200},
        10014px 0 0 0 ${(props) => props.theme.palette.grey.A200};
    }
    33.333% {
      box-shadow: 9984px 0 0 0 ${(props) => props.theme.palette.grey.A200},
        9999px 0 0 0 ${(props) => props.theme.palette.grey.A200},
        10014px 0 0 0 ${(props) => props.theme.palette.grey.A200};
    }
    50% {
      box-shadow: 9984px 0 0 0 ${(props) => props.theme.palette.grey.A200},
        9999px -10px 0 0 ${(props) => props.theme.palette.grey.A200},
        10014px 0 0 0 ${(props) => props.theme.palette.grey.A200};
    }
    66.667% {
      box-shadow: 9984px 0 0 0 ${(props) => props.theme.palette.grey.A200},
        9999px 0 0 0 ${(props) => props.theme.palette.grey.A200},
        10014px 0 0 0 ${(props) => props.theme.palette.grey.A200};
    }
    83.333% {
      box-shadow: 9984px 0 0 0 ${(props) => props.theme.palette.grey.A200},
        9999px 0 0 0 ${(props) => props.theme.palette.grey.A200},
        10014px -10px 0 0 ${(props) => props.theme.palette.grey.A200};
    }
    100% {
      box-shadow: 9984px 0 0 0 ${(props) => props.theme.palette.grey.A200},
        9999px 0 0 0 ${(props) => props.theme.palette.grey.A200},
        10014px 0 0 0 ${(props) => props.theme.palette.grey.A200};
    }
  }
  .dots-pill {
    background-color: ${(props) => props.theme.palette.primary.main};
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 60px;
  }
  .dots-container {
    margin-left: 8px;
    margin-right: 8px;
  }
  .see-new-messages-button-container,
  .dots-container {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 147px;
    justify-content: center;
    width: 100%;
    ${(props) => props.theme.breakpoints.up("sm")} {
      bottom: 130px;
      width: 450px;
    }
  }
`
