import { get } from "./requests"
import { ArgJSONMap } from "./argjsonmap"
import { ActionTypes, UserActions } from "../contexts/user"
import { Dispatch } from "react"
import { SiteNotificationsActions } from "../contexts/siteNotificationContext"
import { ActionTypes as SiteNotificationActionTypes } from "../contexts/siteNotificationContext"
import {
  forceDateStringToDeviceTimeZone,
  IServerBlackoutDate,
  userDateTimeISOToNearestGMTDateISO,
} from "./dates"
import { isBefore, startOfDay } from "date-fns"

interface IAccountResponse {
  username: string
  email: string
  avatarUrl: string
  theme: "light" | "dark"
}

export function updateUserContext(
  dispatch: Dispatch<UserActions>,
  siteNotificationDispatch: Dispatch<SiteNotificationsActions>,
  uuid?: string
): Promise<string> {
  return new Promise((resolve, reject) => {
    let url
    if (uuid !== undefined) {
      url = `/account/?uuid=${uuid}`
    } else {
      const uuidTemp = window.sessionStorage.getItem("profileUuid")
      url = uuidTemp !== null ? `/account/?uuid=${uuidTemp}` : "/account/"
    }
    get(url)
      .then((response: { data: IAccountResponse }) => {
        if (response.data.username !== undefined) {
          const data = ArgJSONMap.fromParsedJson(response.data)
          const profileUuid = data.getString("uuid")
          window.sessionStorage.setItem("profileUuid", profileUuid)

          const parsedTags = data.getString("tags")
          let tagMap = Array<number>()
          if (parsedTags === "[]") {
            tagMap = []
          } else {
            tagMap = parsedTags
              .replaceAll("[", "")
              .replaceAll("]", "")
              .split(",")
              .map((t) => {
                return parseInt(t)
              })
          }
          const blackout_dates_str = data.getString("blackout_dates") || ""
          const blackout_dates_iso_list: IServerBlackoutDate[] = JSON.parse(
            blackout_dates_str.replace(/'/g, '"')
          )
          const today = startOfDay(new Date())
          const formatted_blackout_dates_iso_list: IServerBlackoutDate[] =
            blackout_dates_iso_list
              .map((bd) => {
                const parsedStartDate = userDateTimeISOToNearestGMTDateISO(
                  bd["startDate"]
                )
                const parsedEndDate = userDateTimeISOToNearestGMTDateISO(
                  bd["endDate"]
                )
                const uuid = bd["uuid"]
                return {
                  startDate: parsedStartDate,
                  endDate: parsedEndDate,
                  uuid,
                }
              })
              .filter((bd) => {
                const parsedEndDate = forceDateStringToDeviceTimeZone(
                  bd["endDate"]
                )
                return !isBefore(parsedEndDate, today)
              })
          dispatch({
            type: ActionTypes.Login,
            payload: {
              avatarUrl: data.getString("avatarUrl"),
              theme: data.getString("theme") === "light" ? "light" : "dark",
              email: data.getString("email"),
              isEmailValidated: data.getBoolean("is_email_validated"),
              isPhoneValidated: data.getBoolean("is_phone_validated"),
              isTfaEnabled: data.getBoolean("is_tfa_enabled"),
              tfaDevice: data.getString("tfa_device"),
              showOnboardingBar: data.getBoolean("show_onboarding_bar"),
              accountAgeInDays: data.getNumber("account_age_in_days"),
              phoneNumber: data.getStringOrUndefined("phone_number") || "",
              userUuid: data.getString("user_uuid"),
              username: data.getString("username"),
              profileName: data.getString("profile_name"),
              profileDisplayName: data.getString("profile_display_name"),
              id: parseInt(data.getString("id")),
              // i dont know why this isnt a thing anymore
              // userId: data.getNumber("user_id"),
              uuid: profileUuid,
              isAuthenticated: true,
              authenticationAttempted: true,
              verified: data.getBoolean("verified"),
              isCustomerSupport: data.getBoolean("customer_support"),
              promoRequestsRemaining: data.getNumber(
                "promo_request_limit_remaining"
              ),
              userIdVerified: data.getBoolean("id_verified"),
              buyerAgreementAccepted: data.getBoolean(
                "buyer_agreement_accepted"
              ),
              sellerAgreementAccepted: data.getBoolean(
                "seller_agreement_accepted"
              ),
              isEndorsed: data.getBoolean("is_endorsed"),
              isStaff: data.getBoolean("is_staff"),
              userRole: data.getString("user_role") || "",
              location: data.getString("location") || "",
              tags: tagMap,
              blackout_dates_iso_list: formatted_blackout_dates_iso_list,
              telegramUsername: data.getString("telegram_username") || "",
              telegramChatId: data.getString("telegram_chat_id") || "",
              telegramUrl: data.getString("telegram_url") || "",
            },
          })
          siteNotificationDispatch({
            type: SiteNotificationActionTypes.UpdateSiteNotificationCount,
            payload: {
              newSiteNotificationCount: data.getNumber(
                "unread_notification_count"
              ),
            },
          })
          resolve(data.getString("uuid"))
        }
        reject("")
      })
      .catch(() => {
        dispatch({
          type: ActionTypes.Logout,
          payload: {
            isAuthenticated: false,
          },
        })
        reject("")
      })
  })
}
