import { ErrorDialog } from "../Organisms/ErrorDialog"
import React, { useContext, useEffect, useState } from "react"
import {
  ErrorActionTypes,
  ErrorContext,
} from "../../contexts/photoUploadErrors"

export const RequestErrorModal = (): JSX.Element => {
  const { errorContext, errorDispatch } = useContext(ErrorContext)
  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false)

  useEffect(() => {
    if (errorContext.errorMessages.length > 0) {
      setErrorDialogOpen(true)
    }
  }, [errorContext.errorMessages])

  return (
    <>
      {errorContext.errorMessages.length > 0 && (
        <ErrorDialog
          errorTitle={errorContext.errorMessages[0].title}
          errorDialogOpen={errorDialogOpen}
          setErrorDialogOpen={setErrorDialogOpen}
          affirmativeButtonText={
            errorContext.errorMessages[0].affirmativeButtonText
          }
          affirmativeOnClick={() => {
            if (
              errorContext.errorMessages[0].affirmativeOnClick !== undefined
            ) {
              errorContext.errorMessages[0].affirmativeOnClick()
            }
            errorDispatch({
              type: ErrorActionTypes.Deque,
            })
            // setErrorMessages((prevState) => [...prevState.slice(1)])
          }}
          negativeButtonText={errorContext.errorMessages[0].negativeButtonText}
          negativeOnClick={errorContext.errorMessages[0].negativeOnClick}
        >
          <div>{errorContext.errorMessages[0].body}</div>
        </ErrorDialog>
      )}
    </>
  )
}
