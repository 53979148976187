import React, { Dispatch } from "react"
import { ActionMap } from "../utils/types"
import { ALERT_SEVERITY_LEVEL } from "../utils/constants"

export enum ACCOUNT_VIEW_STATES {
  COLLAPSED = "Collapsed",
  ADD = "Add",
  DELETE = "Delete",
  VERIFICATION = "Verification",
  VERIFY_STATS = "VerifyStats",
}

export enum SOCIAL_ACCOUNT_VERIFY_STATS_PATH {
  EXTENSION = "Extension",
  UPLOAD_VIDEO = "Upload",
  FANSMETRIC = "FansMetric",
}

export enum SOCIAL_ACCOUNT_VERIFY_STATS_SUB_PATH {
  STEP_1 = "StepOne",
  STEP_2 = "StepTwo",
  STEP_3 = "StepThree",
  STEP_4 = "StepFour",
}

export const initialState: UserSocialAccounts = {
  accounts: {
    verified: [],
    unverified: [],
    missing: [],
  },
  mediaTypes: [],
  accountViewState: {},
  alert: { isOpen: false },
  errors: [],
  accountToVerify: undefined,
  accountStatsToVerify: undefined,
  verifyStatsPath: undefined,
  verifyStatsSubPath: undefined,
  goToVerifyStatsSubmissionStatus: false,
}

export enum ActionTypes {
  SET = "USER_SOCIAL_ACCOUNTS.SET",
  SET_VERIFIED = "USER_SOCIAL_ACCOUNTS.SET_VERIFIED",
  SET_UNVERIFIED = "USER_SOCIAL_ACCOUNTS.SET_UNVERIFIED",
  SET_MISSING = "USER_SOCIAL_ACCOUNTS.SET_MISSING",
  SET_ACCOUNT_VIEW_STATE = "USER_SOCIAL_ACCOUNTS.SET_ACCOUNT_VIEW_STATE",
  SET_ALERT = "USER_SOCIAL_ACCOUNTS.SET_ALERT",
  SET_ERRORS = "USER_SOCIAL_ACCOUNTS.SET_ERRORS",
  SET_ACCOUNT_TO_VERIFY = "USER_SOCIAL_ACCOUNTS.SET_ACCOUNT_TO_VERIFY",
  SET_ACCOUNT_STATS_TO_VERIFY = "USER_SOCIAL_ACCOUNTS.SET_ACCOUNT_STATS_TO_VERIFY",
  SET_VERIFY_STATS_PATH = "USER_SOCIAL_ACCOUNTS.SET_VERIFY_STATS_PATH",
  SET_VERIFY_STATS_SUB_PATH = "USER_SOCIAL_ACCOUNTS.SET_VERIFY_STATS_SUB_PATH",
  SET_GO_TO_VERIFY_STATS_SUBMISSION_STATUS = "USER_SOCIAL_ACCOUNTS.SET_GO_TO_VERIFY_STATS_SUBMISSION_STATUS",
}

export enum VERIFICATION_METHODS {
  DirectMessage = "DirectMessage",
  CodeInBio = "CodeInBio",
  Disabled = "Disabled",
}

type VerificationState = {
  view: VERIFICATION_METHODS
  includeVerifyBy: boolean
}

export type AccountStatsToVerify = {
  topRated: number
  fans: number
  following: number
  videoSlug: string
  videoThumbnail: string
  videoUrl: string
  isRejected: boolean
  alertType?: "success" | "error" | "info" | "warning"
  alertMsg?: string
  statMsg?: string
}

export type UserSocialAccountType = {
  key: string
  accountName: string
  userName: string
  uuid: string
  isVerified: boolean
  verificationCode: string
  verificationDmConfirmed: boolean
  blossmUsername: string
  blossmProfileUrl: string
  verifyByDirectMessage: boolean
  verifyByCodeInBio: boolean
  verifyByLogin: boolean
  verificationState: VerificationState
  canVerifyStats: boolean
  statVerificationExampleVideoUrl: string
}

export type UserSocialAccountView = {
  [id: string]: ACCOUNT_VIEW_STATES
}

export type UserSocialAccountAlert = {
  text?: string
  severity?: ALERT_SEVERITY_LEVEL
  isOpen: boolean
}

export type UserSocialAccounts = {
  accounts: {
    verified: UserSocialAccountType[]
    unverified: UserSocialAccountType[]
    missing: UserSocialAccountType[]
  }
  mediaTypes: UserSocialAccountType[]
  accountViewState: UserSocialAccountView
  alert?: UserSocialAccountAlert
  errors?: string[]
  accountToVerify?: UserSocialAccountType | undefined
  accountStatsToVerify?: AccountStatsToVerify | undefined
  verifyStatsPath?: SOCIAL_ACCOUNT_VERIFY_STATS_PATH | undefined
  verifyStatsSubPath?: SOCIAL_ACCOUNT_VERIFY_STATS_SUB_PATH | undefined
  goToVerifyStatsSubmissionStatus?: boolean
}

type UserSocialAccountsPayload = {
  [ActionTypes.SET]: UserSocialAccounts
  [ActionTypes.SET_VERIFIED]: UserSocialAccountType[]
  [ActionTypes.SET_UNVERIFIED]: UserSocialAccountType[]
  [ActionTypes.SET_MISSING]: UserSocialAccountType[]
  [ActionTypes.SET_ACCOUNT_VIEW_STATE]: UserSocialAccountView
  [ActionTypes.SET_ALERT]: UserSocialAccountAlert
  [ActionTypes.SET_ERRORS]: string[]
  [ActionTypes.SET_ACCOUNT_TO_VERIFY]: UserSocialAccountType | undefined
  [ActionTypes.SET_ACCOUNT_STATS_TO_VERIFY]: AccountStatsToVerify | undefined
  [ActionTypes.SET_VERIFY_STATS_PATH]:
    | SOCIAL_ACCOUNT_VERIFY_STATS_PATH
    | undefined
  [ActionTypes.SET_VERIFY_STATS_SUB_PATH]:
    | SOCIAL_ACCOUNT_VERIFY_STATS_SUB_PATH
    | undefined
  [ActionTypes.SET_GO_TO_VERIFY_STATS_SUBMISSION_STATUS]: boolean
}
const sortAccounts = (
  a: UserSocialAccountType,
  b: UserSocialAccountType
): number => {
  const accountNameComparison = a.accountName.localeCompare(b.accountName)
  if (accountNameComparison !== 0) {
    return accountNameComparison
  }
  return a.userName.localeCompare(b.userName)
}
export type UserSocialAccountsActions =
  ActionMap<UserSocialAccountsPayload>[keyof ActionMap<UserSocialAccountsPayload>]

export function reducer(
  state: UserSocialAccounts = initialState,
  action: UserSocialAccountsActions
): UserSocialAccounts {
  let newState

  switch (action.type) {
    case ActionTypes.SET:
      newState = {
        ...state,
        accounts: action.payload.accounts,
        mediaTypes: action.payload.mediaTypes,
        accountViewState: action.payload.accountViewState,
      }
      break
    case ActionTypes.SET_VERIFIED:
      newState = {
        ...state,
        accounts: {
          ...state.accounts,
          verified: action.payload.sort(sortAccounts),
        },
      }
      break
    case ActionTypes.SET_UNVERIFIED:
      newState = {
        ...state,
        accounts: {
          ...state.accounts,
          unverified: action.payload.sort(sortAccounts),
        },
      }
      break
    case ActionTypes.SET_MISSING:
      newState = {
        ...state,
        accounts: {
          ...state.accounts,
          missing: action.payload.sort(sortAccounts),
        },
      }
      break
    case ActionTypes.SET_ACCOUNT_VIEW_STATE:
      newState = {
        ...state,
        accountViewState: action.payload,
      }
      break
    case ActionTypes.SET_ALERT:
      newState = {
        ...state,
        alert: action.payload,
      }
      break
    case ActionTypes.SET_ERRORS:
      newState = {
        ...state,
        errors: action.payload,
      }
      break
    case ActionTypes.SET_ACCOUNT_TO_VERIFY:
      newState = {
        ...state,
        accountToVerify: action.payload,
      }
      break
    case ActionTypes.SET_ACCOUNT_STATS_TO_VERIFY:
      newState = {
        ...state,
        accountStatsToVerify: action.payload,
      }
      break
    case ActionTypes.SET_VERIFY_STATS_PATH:
      newState = {
        ...state,
        verifyStatsPath: action.payload,
      }
      break
    case ActionTypes.SET_VERIFY_STATS_SUB_PATH:
      newState = {
        ...state,
        verifyStatsSubPath: action.payload,
      }
      break
    case ActionTypes.SET_GO_TO_VERIFY_STATS_SUBMISSION_STATUS:
      newState = {
        ...state,
        goToVerifyStatsSubmissionStatus: action.payload,
      }
      break
    default:
      newState = {
        ...state,
      }
      break
  }

  return newState
}

export const UserSocialAccountsContext = React.createContext<{
  context: UserSocialAccounts
  dispatch: Dispatch<UserSocialAccountsActions>
}>({ context: initialState, dispatch: () => null })
