import styled from "@emotion/styled"
import { ChatHeader, IChatHeaderProps } from "../../Atoms/Chat/ChatHeader"
import { MessageBox } from "./MessageBox"
import { ChatBar } from "../../Atoms/Chat/ChatBar"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { useRouter } from "next/router"
import { UserContext } from "../../../contexts/user"
import {
  Alert,
  CircularProgress,
  Snackbar,
  Theme,
  useTheme,
} from "@mui/material"
import { MessageContext } from "../../../contexts/websocket"
import { baseUrl, get, post } from "../../../utils/requests"
import { ArgJSONMap } from "../../../utils/argjsonmap"
import { handleChatHistory } from "../../../websockets/websocket"
import {
  parseAuthProfileGetResponse,
  parsePhotos,
} from "../../../utils/parsers"
import Box from "@mui/material/Box"
import { getWindowDimensions } from "../../../utils/windowDimensions"
import { IMessageHeaderProps } from "../../Atoms/Chat/MessageHeader"
import { ChatMedia } from "../../Atoms/Chat/ChatMedia"
import { IOrderPromoPhoto } from "../../Atoms/Promo/AddMedia"
import useMediaUploader from "../../../hooks/useMediaUploader"

interface IProfileResponse {
  uuid: string
}

export interface IChatProps {
  username?: string
}

export const Chat = (props: IChatProps): JSX.Element => {
  const { context } = useContext(UserContext)
  const [chatMedia, setChatMedia] = useState<number[]>([])
  const [openAddMedia, setOpenAddMedia] = useState(false)
  const [openAddMediaModal, setOpenAddMediaModal] = useState(false)
  const [requestID, setRequestID] = useState(0)
  const [toProfileUUID, setToProfileUUID] = useState<string | undefined>(
    undefined
  )
  const { messageContext, messageDispatch } = useContext(MessageContext)
  const theme = useTheme()
  const [chatHeaderProps, setChatHeaderProps] = useState<IChatHeaderProps>({
    username: "",
    otherUserUuid: "",
    verified: false,
    avatar: { online: false, profileImage: "/" },
    isCustomerSupport: false,
  })
  const [messageHeaderProps, setMessageHeaderProps] =
    useState<IMessageHeaderProps>({
      username: "",
      displayName: "",
      verified: false,
      avatar: { online: false, profileImage: "/" },
      isCustomerSupport: false,
    })
  const [selectedMedia, setSelectedMedia] = useState<IOrderPromoPhoto[]>([])
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [errorAlertOpen, setErrorAlertOpen] = useState(false)
  const router = useRouter()

  const username = useMemo(() => {
    if (props.username) {
      if (props.username?.startsWith("@")) {
        return props.username?.replace("@", "")
      } else {
        return props.username
      }
    }

    const { name } = router.query

    return Array.isArray(name) ? name[0] : name
  }, [props.username])

  const currentUuid = useMemo(() => context.uuid, [context.uuid])

  const currentProfileName = useMemo(
    () => context.profileName,
    [context.profileName]
  )

  const websocket = useMemo(
    () => messageContext.websocket,
    [messageContext.websocket]
  )

  const isReconnecting = useMemo(
    () => messageContext.reconnecting,
    [messageContext.reconnecting]
  )
  const messages = useMemo(
    () => messageContext.messages,
    [messageContext.messages]
  )

  const uploadFile = (index: number, file: File, media: File[]): void => {
    if (!file) {
      return
    }
    const { setUpload, done } = useMediaUploader()
    const isVideo = file.type.match("video.*")
    const url = new URL(
      `/photo_video/${isVideo ? "photoset/create/video/" : "photo/upload/"}`,
      baseUrl ?? window.location.origin
    )
    const formData = new FormData()

    formData.append(isVideo ? "upload_file" : "image_file", file)
    formData.append("profile", currentProfileName)
    formData.append("is_public", "false")
    formData.append("uploaded_from", "chat")

    setUpload(url, formData)
    done(async (resp) => {
      if (!resp.ok) {
        console.error(resp.json())
      }
      uploadFile(index - 1, media[index - 1], media)
    })
  }
  const uploadMedia = async (media: File[]): Promise<void> => {
    setIsUploading(true)
    uploadFile(media.length - 1, media[media.length - 1], media)

    setTimeout(() => {
      get(
        `/photo_video/photo/list/${currentProfileName}/?include_recent_uploads=1`
      ).then((response) => {
        const newList = parsePhotos(response.data).splice(0, media.length)

        newList.forEach((media) => {
          setSelectedMedia((prevState) => {
            return [{ ...media, selected: true }, ...prevState]
          })
        })

        setIsUploading(false)
      })
    }, 1500 * media.length)
  }

  useEffect(() => {
    if (messages.length === 0 || chatHeaderProps.username !== username) {
      get(`/auth/profile/?name=${username}`)
        .then((response) => {
          const parsedProfiles: IProfileResponse[] = response.data.map(() => {
            const profile = parseAuthProfileGetResponse(
              ArgJSONMap.fromParsedJson(
                response.data.length > 0 ? response.data[0] : {}
              )
            )
            setChatHeaderProps({
              username: profile.name,
              otherUserUuid: profile.uuid,
              verified: profile.verified,
              isCustomerSupport: profile.isCustomerSupport,
              avatar: {
                online: profile.online,
                profileImage: profile.avatarThumbnail,
              },
            })
            setMessageHeaderProps({
              username: profile.name,
              displayName: profile.displayName,
              verified: profile.verified,
              isCustomerSupport: profile.isCustomerSupport,
              avatar: {
                online: profile.online,
                profileImage: profile.avatarCropped,
              },
            })
            return {
              uuid: profile.uuid,
            }
          })
          // Profile Names are unique so there should only be one returned in this list if any exist.
          if (parsedProfiles.length === 1 && currentUuid !== undefined) {
            const otherProfileUUID = parsedProfiles[0].uuid
            setToProfileUUID(otherProfileUUID)
            get(
              `/get_user_pms/?other_profile_id=${otherProfileUUID}&profile_id=${currentUuid}`
            )
              .then((response) => {
                const chatHistoryData = ArgJSONMap.fromParsedJson(response.data)
                handleChatHistory(currentUuid, chatHistoryData, messageDispatch)
              })
              .catch((error) => {
                console.error("Error fetching chat history", error)
              })
          }
        })
        .catch((error) => {
          console.error("Error fetching profile data", error)
        })
    }
  }, [username, currentProfileName, currentUuid, websocket, messages])

  useEffect(() => {
    if (toProfileUUID !== undefined && websocket !== undefined) {
      websocket.joinPMChat(toProfileUUID, currentUuid)
    }
  }, [toProfileUUID, websocket])

  function handleAddMediaIconClicked(): void {
    setOpenAddMedia(true)
    setOpenAddMediaModal(true)
  }

  function handleSetMedia(mediaIds: number[]): void {
    setChatMedia(mediaIds)
    if (mediaIds.length === 0) {
      setOpenAddMedia(false)
    }
    setOpenAddMediaModal(false)
  }

  return (
    <StyledChat theme={theme}>
      <div className={"header"}>
        <ChatHeader {...chatHeaderProps} />
      </div>
      {!isReconnecting && (
        <MessageBox
          messageHeaderProps={messageHeaderProps}
          messages={messages}
          mediaOpen={openAddMedia}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={errorAlertOpen}
        autoHideDuration={6000}
        onClose={() => setErrorAlertOpen(false)}
      >
        <Alert variant="filled" severity={"error"}>
          Your chat is restricted. Please contact Blossm support.
        </Alert>
      </Snackbar>
      <div className={"footer"}>
        <ChatMedia
          open={openAddMedia}
          setMedia={handleSetMedia}
          openModal={openAddMediaModal}
          setModalOpen={setOpenAddMediaModal}
          setOpenAddMedia={setOpenAddMedia}
          onAddMediaIconClicked={handleAddMediaIconClicked}
          media={chatMedia}
          selectedMedia={selectedMedia}
          setSelectedMedia={setSelectedMedia}
          isUploading={isUploading}
          setIsUploading={setIsUploading}
          uploadMedia={uploadMedia}
        />
        <ChatBar
          uploadMedia={uploadMedia}
          onAddMediaIconClicked={handleAddMediaIconClicked}
          toProfileUUID={toProfileUUID}
          onSubmit={(chatInput: string) => {
            if (!chatInput && chatMedia.length < 1) {
              return
            }
            setRequestID(requestID + 1)
            if (toProfileUUID !== undefined && currentUuid !== undefined) {
              const data = {
                text:
                  chatMedia.length > 0
                    ? `${chatInput} [%%${chatMedia}%%]`
                    : chatInput,
                from_user: currentUuid,
                to_user: toProfileUUID,
              }
              post("/save_private_message/", data)
                .then(() => {
                  handleSetMedia([])
                  websocket?.updateUserTyping(false, toProfileUUID, currentUuid)
                })
                .catch((error) => {
                  if (
                    "from_user" in error.response.data &&
                    error.response.data["from_user"][0].includes("banned")
                  ) {
                    setErrorAlertOpen(true)
                  }
                })
            }
          }}
        />
      </div>
      {isReconnecting && (
        <ChatLoadingContainer
          sx={{
            top: 112,
            bottom:
              getWindowDimensions().width < theme.breakpoints.values.sm
                ? 56
                : 0,
          }}
        >
          <CircularProgress />
        </ChatLoadingContainer>
      )}
    </StyledChat>
  )
}
const StyledChat = styled.div<{ theme: Theme }>`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    width: 100%;
    z-index: 1;
  }

  .footer {
    margin-top: auto;
    width: 100%;
  }
`

const ChatLoadingContainer = styled(Box)({
  position: "absolute",
  right: 0,
  left: 0,
  overflowY: "scroll",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
})
