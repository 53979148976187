import { Avatar } from "../Atoms/Avatar"
import styled from "@emotion/styled"
import { Radio, Theme, Typography, useTheme } from "@mui/material"
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import { Verified } from "../Atoms/Verified"
import { AddOutlined } from "@mui/icons-material"
import { get, patch } from "../../utils/requests"
import { useRouter } from "next/router"
import { ArgJSONMap } from "../../utils/argjsonmap"
import { UserContext } from "../../contexts/user"
import { updateUserContext } from "../../utils/account"
import { MessageContext } from "../../contexts/websocket"
import { SiteNotificationsContext } from "../../contexts/siteNotificationContext"
import { PrimaryColorAccountCircle } from "../Atoms/Icons/PrimaryColorIcons"
import { ChatActionTypes, ChatContext } from "../../contexts/chat"
import { InverseButton } from "../Atoms/Buttons"

interface ISelectProfileDrawerProps {
  setDrawerOpen: Dispatch<SetStateAction<boolean>>
}

export interface IProfileOptionProps {
  online: boolean
  imageUrl: string
  displayName: string
  username: string
  profileID: string
  verified: boolean
}

const parseProfilesResponse = (profiles: object[]): IProfileOptionProps[] => {
  return profiles.map((promo) => {
    const data = ArgJSONMap.fromParsedJson(promo)
    return {
      online: data.getBoolean("online"),
      imageUrl: data.getString("avatar_thumbnail"),
      displayName: data.getString("display_name"),
      username: data.getString("name").toLowerCase(),
      profileID: data.getString("uuid"),
      verified: data.getBoolean("verified"),
    }
  })
}

export const SelectProfileRadioButtons = ({
  setDrawerOpen,
}: ISelectProfileDrawerProps): JSX.Element => {
  const { context, dispatch } = useContext(UserContext)
  const { dispatch: chatDispatch } = useContext(ChatContext)
  const [profiles, setProfiles] = useState<IProfileOptionProps[]>([])
  const [selectedProfile, setSelectedProfile] = useState<string>(context.uuid)
  const { siteNotificationsDispatch } = useContext(SiteNotificationsContext)
  const { messageContext } = useContext(MessageContext)
  const router = useRouter()

  useEffect(() => {
    get("/auth/profile/")
      .then((response) => {
        setProfiles(parseProfilesResponse(response.data))
      })
      .catch((error) => {
        console.warn(error)
      })
  }, [context.id])

  const handleChange = (profileUUID: string, username: string): void => {
    setSelectedProfile(profileUUID)
    handleSwitchProfile(username)
    patch("/profile/select/", { profile_uuid: profileUUID })
      .then(() => {
        updateUserContext(dispatch, siteNotificationsDispatch, profileUUID)
          .then(() => {
            chatDispatch({
              type: ChatActionTypes.ChatWithUser,
              payload: "",
            })
            setDrawerOpen(false)
            messageContext.websocket?.changeProfile(profileUUID)
          })
          .catch((error: Error) => {
            console.warn("Failed to switch profiles", error)
          })
      })
      .catch((error) => {
        console.warn(error)
      })
  }

  const handleSwitchProfile = (username: string): void => {
    if (router.query.name === context.profileName) {
      router.push("/" + username)
    }

    if (router.pathname === "/add-accounts") {
      router.push(`/add-accounts?profileName=${username}`)
    }
  }

  const theme = useTheme()
  return (
    <StyledProfileRadioButtons theme={theme}>
      <Typography color="text.secondary" variant="body1">
        My Profiles
      </Typography>
      {profiles.map((profile, index) => {
        return (
          <div
            className="profile-select-row"
            onClick={() => handleChange(profile.profileID, profile.username)}
            key={index}
          >
            <span className="profile-info-container">
              <div className="avatar-wrapper">
                {profile.imageUrl ? (
                  <Avatar
                    online={false}
                    profileImage={profile.imageUrl}
                    size="Medium"
                  />
                ) : (
                  <PrimaryColorAccountCircle
                    sx={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                )}
              </div>
              <span className="names-container">
                <span className="display-name-line">
                  <Typography
                    variant="subtitle2"
                    className="display-name-text"
                    noWrap
                  >
                    {profile.displayName}
                  </Typography>
                  {profile.verified && <Verified />}
                </span>
                <Typography variant="caption" noWrap color="primary">
                  @{profile.username}
                </Typography>
              </span>
            </span>
            <span>
              <Radio
                checked={selectedProfile === profile.profileID}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(event.target.value, profile.username)
                }}
                value={profile.profileID}
                name="radio-buttons"
                inputProps={{ "aria-label": profile.username }}
              />
            </span>
          </div>
        )
      })}
      <InverseButton
        variant={"outlined"}
        sx={{ justifyContent: "space-between", mt: 1 }}
        startIcon={<AddOutlined sx={{ color: "inherit" }} />}
        onClick={() => {
          setDrawerOpen(false)
          router.push("/add-profile")
        }}
      >
        Add a Profile
      </InverseButton>
    </StyledProfileRadioButtons>
  )
}

const StyledProfileRadioButtons = styled.div<{ theme: Theme }>`
  align-self: center;
  display: flex;
  flex-direction: column;
  //max-width: 500px;
  ${(props) => props.theme.breakpoints.up("sm")} {
    min-width: 500px;
  }

  overflow-y: auto;
  padding: 16px;
  width: 100%;

  .add-profile-button {
    padding-left: 16px;
    justify-content: flex-start;
  }

  .add-profile-button-icon {
    fill: ${(props) => props.theme.palette.text.secondary};
    margin-right: 24px;
  }

  .display-name-line {
    display: flex;
    flex-direction: row;
  }

  .display-name-text {
    margin-right: 5px;
  }

  .profile-info-container {
    display: flex;
    flex-direction: row;
    flex: 1;
  }

  .names-container {
    display: flex;
    flex-direction: column;
    padding-left: 8px;
  }

  .profile-select-row {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;
    margin-top: 12px;
    width: inherit;
  }

  .avatar-wrapper {
    width: 36px;
    min-width: 36px;
  }
`
