import {Box, CircularProgress} from "@mui/material"

export interface ILoadingSpinnerProps {
    size: number
}

export const LoadingSpinner = (props: ILoadingSpinnerProps): JSX.Element => {
    return (
        <Box
            sx={{
                position: "relative",
                width: `${props.size}px`,
                height: `${props.size}px`,
            }}
        >
            <CircularProgress
                sx={{
                    animation: "unset",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            />
        </Box>
    )
}
