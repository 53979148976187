import React, { useEffect, useState } from "react"
import {
  alpha,
  Box,
  CircularProgress,
  darken,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { IPromo, IScheduledPromos, ScheduleRole } from "../../pages/orders"
import styled from "@emotion/styled"
import { Colors } from "../../styles/Colors"
import { getNextAvailableDateLabel } from "../../utils/dates"
import Link from "next/link"
import { InverseButton } from "../Atoms/Buttons"
import { getLogo } from "../Atoms/Profile/Header/Logo"
import { PrimaryColorAccountCircle } from "../Atoms/Icons/PrimaryColorIcons"
import { BlossmImage } from "../Atoms/BlossmImage"

type ScheduleTabsProps = {
  promos: IScheduledPromos
  openMessages?: (profileName: string) => void
  openOrderStatus?: (promoRequestUuid: string) => void
  openPromoOrderPage?: (
    promoUuid: string,
    promoDate: Date,
    promoTierUuid: string,
    platform: string
  ) => void
  showLoadingMore: boolean
}

const enum PROMO_TYPE {
  past,
  upcoming,
  request,
  inProgress,
  cancelled,
  disputed,
}

export const initialLoadCount = 5

export const ScheduleTabs = ({
  promos,
  openOrderStatus,
  openMessages,
  openPromoOrderPage,
  showLoadingMore,
}: ScheduleTabsProps): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState<"Selling" | "Buying">(
    "Selling"
  )
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const [hoveredOrderNumber, setHoveredOrderNumber] = useState("")

  useEffect(() => {
    if (hasNoSellingData && !hasNoBuyingData) {
      setSelectedTab("Buying")
    }
    if (hasNoSellingData && hasNoBuyingData) {
      setSelectedTab("Selling")
    }
  }, [promos])

  function createHeaderClasses(promo: IPromo, promoType: PROMO_TYPE): string {
    let headerClassNames = "promo-header"
    if (promoType === PROMO_TYPE.past) {
      if (promo.reviewedByMe) {
        headerClassNames += " promo-header-inactive"
      } else {
        headerClassNames += " promo-header-active"
      }
    } else if (promoType === PROMO_TYPE.cancelled) {
      headerClassNames += " promo-header-inactive"
    } else {
      headerClassNames += " promo-header-active"
    }
    return headerClassNames
  }

  function createPastHeaderText(
    promo: IPromo,
    promoRequestScheduledTime: string
  ): string {
    let formattedHeaderText = ""
    if (promo.status === "pending") {
      formattedHeaderText = "Promo Request"
    } else if (
      promo.userRole === ScheduleRole.Selling &&
      promo.status === "seller completed"
    ) {
      formattedHeaderText = "Marked Complete - Awaiting Buyer Confirmation"
    } else if (
      promo.userRole === ScheduleRole.Buying &&
      promo.status === "seller completed"
    ) {
      formattedHeaderText = "Completed - Pending Buyer Confirmation"
    } else if (
      promo.userRole === ScheduleRole.Selling &&
      promo.status === "accepted"
    ) {
      formattedHeaderText = `Confirm Completion - Scheduled for ${promoRequestScheduledTime}`
    } else if (
      promo.userRole === ScheduleRole.Buying &&
      promo.status === "resubmit_payment"
    ) {
      formattedHeaderText = "Payment Failed - Resubmit"
    } else {
      formattedHeaderText = `Scheduled for ${promoRequestScheduledTime}`
    }
    return formattedHeaderText
  }

  function createInProgressHeaderText(
    promo: IPromo,
    promoRequestScheduledTime: string
  ): string {
    let formattedHeaderText = ""
    if (
      promo.userRole === ScheduleRole.Selling &&
      promo.status === "seller completed"
    ) {
      formattedHeaderText = "Marked Complete - Awaiting Buyer Confirmation"
    } else if (
      promo.userRole === ScheduleRole.Selling &&
      promo.status === "resubmit_payment"
    ) {
      formattedHeaderText = "Payment Failed - Waiting for Buyer"
    } else if (
      promo.userRole === ScheduleRole.Buying &&
      promo.status === "accepted"
    ) {
      formattedHeaderText = `Scheduled for ${promoRequestScheduledTime}`
    } else {
      formattedHeaderText = `Purchase Request - ${promoRequestScheduledTime}`
    }
    return formattedHeaderText
  }

  function createDisputedHeaderText(): string {
    return "Disputed - Under Review"
  }

  function createCancelledHeaderText(
    promo: IPromo,
    promoRequestLastUpdateTime: string
  ): string {
    let formattedHeaderText = ""
    if (promo.status === "canceled") {
      formattedHeaderText = `Buyer Cancelled Order ${promoRequestLastUpdateTime}`
    } else if (promo.status === "rejected") {
      formattedHeaderText = `Seller Declined Purchase ${promoRequestLastUpdateTime}`
    } else if (promo.status === "auto rejected") {
      formattedHeaderText = `Seller Declined Purchase ${promoRequestLastUpdateTime}`
    } else {
      formattedHeaderText = `Cancelled - ${promoRequestLastUpdateTime}`
    }
    return formattedHeaderText
  }

  function createPastPromoHeaderText(
    promo: IPromo,
    dateString: string
  ): string {
    let formattedHeaderText = ""
    if (promo.reviewedByMe) {
      if (["calc_partial", "manual_partial"].includes(promo.disputeOutcome)) {
        formattedHeaderText = `Confirmed Complete w/Partial Refund ${dateString}`
      } else {
        formattedHeaderText = `Confirmed Complete ${dateString}`
      }
    } else if (promo.status === "seller refund") {
      formattedHeaderText = "Promo Refunded"
    } else if (
      ["calc_partial", "manual_partial"].includes(promo.disputeOutcome)
    ) {
      formattedHeaderText = "Promo Complete w/Partial Refund - Leave a Review"
    } else {
      formattedHeaderText = "Promo Complete - Leave a Review"
    }
    return formattedHeaderText
  }

  function getProfileImage(
    useDefaultBuyerPhoto: boolean,
    buyerPhoto: string,
    useDefaultSellerPhoto: boolean,
    sellerPhoto: string
  ): JSX.Element {
    if (
      (selectedTab === "Selling" && useDefaultBuyerPhoto) ||
      (selectedTab === "Buying" && useDefaultSellerPhoto)
    ) {
      return (
        <PrimaryColorAccountCircle
          sx={{
            opacity: "1",
            height: 40,
            width: 40,
          }}
        />
      )
    } else {
      return (
        <ImageWrapper>
          <BlossmImage
            alt=""
            src={selectedTab === "Selling" ? buyerPhoto : sellerPhoto}
            width={40}
            height={40}
          />
        </ImageWrapper>
      )
    }
  }

  function getOrderNumber(orderNumber: string): JSX.Element {
    return (
      <span
        onMouseOver={() => setHoveredOrderNumber(orderNumber)}
        onMouseOut={() => setHoveredOrderNumber("")}
        onClick={() => setHoveredOrderNumber(orderNumber)}
      >
        <Tooltip title={orderNumber} open={hoveredOrderNumber === orderNumber}>
          <Typography textOverflow={"ellipsis"} noWrap variant={"subtitle1"}>
            #{orderNumber.slice(0, 8) + "..."}
          </Typography>
        </Tooltip>
      </span>
    )
  }

  function createSection(
    headerText: string,
    data: IPromo[],
    promoType: PROMO_TYPE
  ): JSX.Element | null {
    if (!data.length) {
      return null
    }

    return (
      <Box sx={{ marginBottom: "32px" }}>
        <StyledHeader>{headerText}</StyledHeader>
        {/*eslint-disable-next-line complexity*/}
        {data.map((promo) => {
          const isDefaultBuyerAvatar = !!promo.buyerPhoto.match(
            /\/images\/avatar_[0-9]\.png/g
          )
          const isDefaultSellerAvatar = !!promo.sellerPhoto.match(
            /\/images\/avatar_[0-9]\.png/g
          )
          let formattedHeaderText: string
          const promoRequestScheduledTime = getNextAvailableDateLabel(
            promo.actionTime.toUTCString(),
            { month: "numeric", day: "numeric" }
          )
          const promoRequestLastUpdateTime =
            promo.lastUpdateDate !== undefined
              ? getNextAvailableDateLabel(promo.lastUpdateDate.toUTCString(), {
                  month: "numeric",
                  day: "numeric",
                })
              : undefined
          switch (promoType) {
            case PROMO_TYPE.past:
              formattedHeaderText = createPastPromoHeaderText(
                promo,
                promoRequestLastUpdateTime ?? promoRequestScheduledTime
              )
              break
            case PROMO_TYPE.upcoming:
              formattedHeaderText = createPastHeaderText(
                promo,
                promoRequestScheduledTime
              )
              break
            case PROMO_TYPE.request:
              formattedHeaderText = "Purchase Request"
              break
            case PROMO_TYPE.inProgress:
              formattedHeaderText = createInProgressHeaderText(
                promo,
                promoRequestScheduledTime
              )
              break
            case PROMO_TYPE.cancelled:
              formattedHeaderText = createCancelledHeaderText(
                promo,
                promoRequestLastUpdateTime ?? ""
              )
              break
            case PROMO_TYPE.disputed:
              formattedHeaderText = createDisputedHeaderText()
              break
            default:
              formattedHeaderText = ""
          }

          return (
            <StyledPromoSummary
              key={promo.id}
              theme={theme}
              isMobile={isMobile}
            >
              {isMobile && (
                <div className={createHeaderClasses(promo, promoType)}>
                  <Typography
                    variant={"subtitle2"}
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {formattedHeaderText}
                  </Typography>
                </div>
              )}
              <StyledContentRow isMobile={isMobile}>
                {!isMobile && (
                  <div className={createHeaderClasses(promo, promoType)}>
                    {formattedHeaderText}
                  </div>
                )}
                <div className={"tablet-header"}>
                  <div className={"promo-description"}>
                    <img
                      width={24}
                      height={24}
                      src={getLogo(promo.brand, false)}
                    />
                    <Typography
                      textOverflow={"ellipsis"}
                      noWrap
                      variant={"body1"}
                    >
                      {promo.title}
                    </Typography>
                  </div>
                  {getOrderNumber(promo.id)}
                </div>
              </StyledContentRow>
              {isMobile && (
                <StyledMobileBuyerInfo>
                  <Link href={"/" + promo.userName}>
                    {getProfileImage(
                      isDefaultBuyerAvatar,
                      promo.buyerPhoto,
                      isDefaultSellerAvatar,
                      promo.sellerPhoto
                    )}
                  </Link>
                  <div className={"mobile-content-stack"}>
                    <Link href={"/" + promo.userName}>
                      <Typography
                        textOverflow={"ellipsis"}
                        noWrap
                        sx={{ color: theme.palette.primary.main }}
                        variant={"body1"}
                      >
                        @{promo.userName}
                      </Typography>
                    </Link>
                  </div>
                </StyledMobileBuyerInfo>
              )}
              <StyledContentRow isMobile={isMobile}>
                {!isMobile && (
                  <SpacedContainer>
                    <Link href={"/" + promo.userName}>
                      <ImageWrapper>
                        {getProfileImage(
                          isDefaultBuyerAvatar,
                          promo.buyerPhoto,
                          isDefaultSellerAvatar,
                          promo.sellerPhoto
                        )}
                      </ImageWrapper>
                    </Link>
                    <div className={"content-stack"}>
                      <Link href={"/" + promo.userName}>
                        <Typography
                          textOverflow={"ellipsis"}
                          noWrap
                          sx={{
                            color: theme.palette.primary.main,
                            cursor: "pointer",
                          }}
                          variant={"body1"}
                        >
                          @{promo.userName}
                        </Typography>
                      </Link>
                    </div>
                  </SpacedContainer>
                )}
                <div className={"content-stack"}>
                  <Typography
                    textOverflow={"ellipsis"}
                    noWrap
                    variant={"body1"}
                  >
                    {promo.price.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                  <Typography
                    textOverflow={"ellipsis"}
                    noWrap
                    variant={"body2"}
                    sx={{ color: theme.palette.text.secondary }}
                  >
                    Price
                  </Typography>
                </div>
                <div className={"content-stack"}>
                  <Typography
                    textOverflow={"ellipsis"}
                    noWrap
                    variant={"body1"}
                  >
                    {promo.actionTime.toLocaleDateString(
                      window.navigator.language,
                      { month: "long", day: "numeric", year: "numeric" }
                    )}
                  </Typography>
                  <Typography
                    textOverflow={"ellipsis"}
                    noWrap
                    variant={"body2"}
                    sx={{ color: theme.palette.text.secondary }}
                  >
                    Requested Date
                  </Typography>
                </div>
                {!isMobile && (
                  <SpacedContainer>
                    <InverseButton
                      variant={"outlined"}
                      size={"small"}
                      sx={{ minWidth: "fit-content" }}
                      onClick={() => {
                        if (openOrderStatus !== undefined) {
                          openOrderStatus(promo.id)
                        }
                      }}
                    >
                      {promoType === PROMO_TYPE.past
                        ? "Order Summary"
                        : "Order Status"}
                    </InverseButton>
                    <InverseButton
                      variant={"outlined"}
                      size={"small"}
                      onClick={() => {
                        if (
                          openPromoOrderPage !== undefined &&
                          promoType === PROMO_TYPE.past &&
                          selectedTab === "Buying"
                        ) {
                          openPromoOrderPage(
                            promo.promoUuid,
                            promo.nextAvailableDay,
                            promo.promoTierUuid,
                            promo.brand
                          )
                        } else if (openMessages !== undefined) {
                          openMessages(promo.userName)
                        }
                      }}
                    >
                      {promoType === PROMO_TYPE.past &&
                      selectedTab !== "Selling"
                        ? "Book Again"
                        : "Message"}
                    </InverseButton>
                  </SpacedContainer>
                )}
              </StyledContentRow>
              {isMobile && (
                <StyledContentRow isMobile={isMobile}>
                  <InverseButton
                    fullWidth
                    variant={"outlined"}
                    onClick={() => {
                      if (openOrderStatus !== undefined) {
                        openOrderStatus(promo.id)
                      }
                    }}
                  >
                    {promoType === PROMO_TYPE.past
                      ? "Order Summary"
                      : "Order Status"}
                  </InverseButton>
                  <InverseButton
                    fullWidth
                    variant={"outlined"}
                    onClick={() => {
                      if (
                        openPromoOrderPage !== undefined &&
                        promoType === PROMO_TYPE.past &&
                        selectedTab === "Buying"
                      ) {
                        openPromoOrderPage(
                          promo.promoUuid,
                          promo.nextAvailableDay,
                          promo.promoTierUuid,
                          promo.brand
                        )
                      } else if (openMessages !== undefined) {
                        openMessages(promo.userName)
                      }
                    }}
                  >
                    {promoType === PROMO_TYPE.past && selectedTab !== "Selling"
                      ? "Book Again"
                      : "Message"}
                  </InverseButton>
                </StyledContentRow>
              )}
            </StyledPromoSummary>
          )
        })}
        {showLoadingMore && data.length === initialLoadCount && (
          <Box sx={{ float: "left" }}>
            <CircularProgress
              size={16}
              sx={{
                position: "relative",
                top: "2px",
              }}
            />
            <Typography
              variant="body1"
              sx={{ display: "inline", marginLeft: "8px" }}
            >
              Loading more {headerText} orders...
            </Typography>
          </Box>
        )}
      </Box>
    )
  }

  const hasNoSellingData =
    promos.selling.complete.length === 0 &&
    promos.selling.actionRequired.length === 0 &&
    promos.selling.waitingForBuyer.length === 0 &&
    promos.selling.openIssues.length === 0 &&
    promos.selling.cancelled.length === 0 &&
    promos.selling.upcoming.length === 0
  const sellingContent = hasNoSellingData ? (
    <StyledEmptyTab>You have not sold any promos</StyledEmptyTab>
  ) : (
    <>
      {createSection(
        "Action Needed (" + promos.selling.actionRequired.length + ")",
        promos.selling.actionRequired,
        PROMO_TYPE.upcoming
      )}
      {createSection(
        "In Progress Sales",
        promos.selling.waitingForBuyer,
        PROMO_TYPE.inProgress
      )}
      {createSection("Upcoming", promos.selling.upcoming, PROMO_TYPE.upcoming)}
      {createSection(
        "Disputed Orders",
        promos.selling.openIssues,
        PROMO_TYPE.disputed
      )}
      {createSection("Past Sales", promos.selling.complete, PROMO_TYPE.past)}
      {createSection(
        "Cancelled And Declined",
        promos.selling.cancelled,
        PROMO_TYPE.cancelled
      )}
    </>
  )

  const hasNoBuyingData =
    promos.buying.complete.length === 0 &&
    promos.buying.actionRequired.length === 0 &&
    promos.buying.waitingForSeller.length === 0 &&
    promos.buying.openIssues.length === 0 &&
    promos.buying.cancelled.length === 0 &&
    promos.buying.upcoming.length === 0
  const buyingContent = hasNoBuyingData ? (
    <StyledEmptyTab>You have not bought any promos</StyledEmptyTab>
  ) : (
    <>
      {createSection(
        "Action Needed (" + promos.buying.actionRequired.length + ")",
        promos.buying.actionRequired,
        PROMO_TYPE.upcoming
      )}
      {createSection(
        "In Progress Purchases",
        promos.buying.waitingForSeller,
        PROMO_TYPE.inProgress
      )}
      {createSection("Upcoming", promos.buying.upcoming, PROMO_TYPE.upcoming)}
      {createSection(
        "Disputed Orders",
        promos.buying.openIssues,
        PROMO_TYPE.disputed
      )}
      {createSection("Past Purchases", promos.buying.complete, PROMO_TYPE.past)}
      {createSection(
        "Cancelled And Declined",
        promos.buying.cancelled,
        PROMO_TYPE.cancelled
      )}
    </>
  )

  return (
    <>
      <StyledTabRow>
        <StyledTab
          theme={theme}
          selected={selectedTab === "Selling"}
          onClick={() => setSelectedTab("Selling")}
        >
          <Typography
            variant={"button"}
            sx={{ textAlign: "center", color: "inherit" }}
          >
            Selling
          </Typography>
        </StyledTab>
        <StyledTab
          theme={theme}
          selected={selectedTab === "Buying"}
          onClick={() => setSelectedTab("Buying")}
        >
          <Typography variant={"button"}>Buying</Typography>
        </StyledTab>
      </StyledTabRow>
      <StyledTabContainer>
        {selectedTab === "Selling" && sellingContent}
        {selectedTab === "Buying" && buyingContent}
      </StyledTabContainer>
    </>
  )
}

const SpacedContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`

const StyledMobileBuyerInfo = styled.div`
  display: flex;
  gap: 10px;
  padding-left: 8px;
  padding-right: 8px;
  align-items: center;

  .mobile-content-stack {
    display: flex;
    flex-direction: column;
  }
`

const ImageWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
`

const StyledContentRow = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding-left: ${(props) => (props.isMobile ? "8px" : "0")};
  padding-right: ${(props) => (props.isMobile ? "8px" : "0")};
  flex-wrap: wrap;

  .promo-description {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .content-stack {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
`

const StyledTabRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 4px;
`

const StyledTab = styled.div<{ selected: boolean; theme: Theme }>`
  border: 1px solid;
  border-color: ${(props) =>
    props.selected
      ? darken(props.theme.palette.primary.main, 0.04)
      : alpha(Colors.black, 0.2)};
  border-radius: 32px;
  width: 150px;
  height: 40px;
  color: ${(props) =>
    props.selected
      ? darken(props.theme.palette.primary.main, 0.04)
      : alpha(Colors.black, 0.5)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const StyledHeader = styled.h2`
  font-size: 20px;
  font-weight: 500;
  padding: 10px 0 20px;
  margin: 0;
`

const StyledTabContainer = styled.div`
  padding: 14px;
`

const StyledPromoSummary = styled.div<{ theme: Theme; isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: ${(props) => (props.isMobile ? "0" : "14px")};
  padding-bottom: ${(props) => (props.isMobile ? "10px" : "14px")};;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: rgba(50, 50, 93, 0.25) 0 4px 4px -2px;
  gap: 14px;

  .promo-header-inactive {
    background-color: ${Colors.shade.light};
  }

  .promo-header-active {
    background-color: ${(props) => props.theme.palette.primary.main};
  }

  .promo-header {
    color: white;
    text-align: center;
    border-radius: ${(props) => (props.isMobile ? "4px 4px 0 0" : "8px")};
    width: ${(props) => (props.isMobile ? "100%" : "fit-content")};
    display: flex;
    height: 30px;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
  }

  .tablet-header {
    display: flex;
    gap: 10px;
    justify-content: ${(props) =>
      props.isMobile ? "space-between" : "initial"};
    width: ${(props) => (props.isMobile ? "100%" : "fit-content")};
`

const StyledEmptyTab = styled.div`
  text-align: center;
  font-size: 18px;
  margin-top: 15px;
`
