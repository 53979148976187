import { Box, SxProps, Theme } from "@mui/material"
import { IImageViewerProps } from "../ImageViewer"
import { IOrderPromoPhoto } from "./AddMedia"
import { Download } from "../Download"
import { IPhoto } from "../../../utils/photos"
import React from "react"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import { useTheme } from "@material-ui/core/styles"
import { BlossmImage } from "../BlossmImage"

export interface IMediaThumbnailsProps {
  media: IOrderPromoPhoto[]
  onClick: (index: number, imageViewerProps?: IImageViewerProps) => void
  imageViewerProps?: IImageViewerProps
  sx?: SxProps<Theme> | undefined
  displayOnly: boolean
}

export const MediaThumbnails = (props: IMediaThumbnailsProps): JSX.Element => {
  const theme = useTheme()
  if (props.imageViewerProps) {
    props.imageViewerProps.images = props.media
  }

  return (
    <Box
      sx={{
        display: "flex",
        gap: "4px",
        alignItems: "center",
        flexWrap: "wrap",
        ...props.sx,
      }}
    >
      {props.media.map((img, index) => {
        return (
          <Box
            key={index}
            onClick={() => {
              props.onClick(index, props.imageViewerProps)
            }}
            sx={{
              width: "50px",
              minWidth: "50px",
              height: "50px",
              position: "relative",
              cursor: "pointer",
            }}
          >
            <BlossmImage alt="" src={img.thumbnailUrl} borderRadius={6} />

            {img.isVideo && (
              <PlayArrowIcon
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "#fff",
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: "50%",
                  padding: "4px",
                  fontSize: "30px",
                  cursor: "pointer",
                }}
              />
            )}
          </Box>
        )
      })}
      {props.displayOnly && Boolean(props.media) && (
        <Box
          sx={{
            display: "flex",
            height: "50px",
            alignItems: "end",
          }}
        >
          <Download files={props.media as unknown as IPhoto[]} />
        </Box>
      )}
    </Box>
  )
}
