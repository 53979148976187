import React, { ReactElement, useContext, useEffect, useState } from "react"
import { Layout } from "../components/Organisms/Pages/Layout/Layout"
import { Typography, useMediaQuery } from "@mui/material"
import styled from "@emotion/styled"
import {
  ScheduleTabs,
  initialLoadCount,
} from "../components/Organisms/ScheduleTabs"
import { get } from "../utils/requests"
import { ArgJSONMap } from "../utils/argjsonmap"
import { parsePromoRequestList } from "../utils/parsers"
import { useRouter } from "next/router"
import { NextPageWithLayout } from "./_app"
import { UserContext } from "../contexts/user"
import { ChatActionTypes, ChatContext } from "../contexts/chat"
import { useTheme } from "@mui/material"
import { LoadingSpinner } from "../components/Atoms/LoadingSpinner"
import { Brands } from "../components/Atoms/Profile/Header/Logo"
import {
  SiteActionRequiredContext,
  ActionTypes as ActionRequiredActionTypes,
} from "../contexts/siteActionRequiredContext"

export interface IPromo {
  id: string
  logo: string
  title: string
  userName: string
  description: string
  orderId: string
  actionTime: Date
  nextAvailableDay: Date
  hasNewChanges: boolean
  promoUuid: string
  promoTierUuid: string
  userRole: ScheduleRole
  status: string
  lastUpdateDate: Date | undefined
  reviewedByMe: boolean
  price: number
  orderNumber: string
  buyerPhoto: string
  sellerPhoto: string
  brand: Brands
  disputeStatus: string
  disputeOutcome: string
}

export enum ScheduleRole {
  Buying,
  Selling,
}

export interface IScheduledPromos {
  // The IPromo[] may want to be put in normalized form. It depends on if we are going to be updating the data a lot.
  selling: {
    actionRequired: IPromo[]
    complete: IPromo[]
    openIssues: IPromo[]
    waitingForBuyer: IPromo[]
    upcoming: IPromo[]
    cancelled: IPromo[]
  }
  buying: {
    actionRequired: IPromo[]
    complete: IPromo[]
    openIssues: IPromo[]
    waitingForSeller: IPromo[]
    upcoming: IPromo[]
    cancelled: IPromo[]
  }
}

const initialData: IScheduledPromos = {
  selling: {
    actionRequired: [],
    complete: [],
    openIssues: [],
    waitingForBuyer: [],
    upcoming: [],
    cancelled: [],
  },
  buying: {
    actionRequired: [],
    complete: [],
    openIssues: [],
    waitingForSeller: [],
    upcoming: [],
    cancelled: [],
  },
}

const SchedulePage: NextPageWithLayout = () => {
  const [scheduledPromos, setScheduledPromos] =
    useState<IScheduledPromos>(initialData)
  const router = useRouter()
  const { context } = useContext(UserContext)
  const { dispatch: chatDispatch } = useContext(ChatContext)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const [loadData, setLoadData] = useState(false)
  const [hasInitialData, setHasInitialData] = useState(false)
  const [showLoadingMore, setShowLoadingMore] = useState(true)
  const { siteActionRequiredDispatch } = useContext(SiteActionRequiredContext)

  useEffect(() => {
    const pageSize = hasInitialData ? 500 : initialLoadCount
    Promise.all([
      get(`/schedule/buyer/action_required/?profile_uuid=${context.uuid}`),
      get(`/schedule/buyer/open_issues/?profile_uuid=${context.uuid}`),
      get(`/schedule/buyer/waiting_for_seller/?profile_uuid=${context.uuid}`),
      get(`/schedule/buyer/upcoming/?profile_uuid=${context.uuid}`),
      get(
        `/schedule/buyer/complete/?profile_uuid=${context.uuid}&page_size=${pageSize}`
      ),
      get(
        `/schedule/buyer/cancelled/?profile_uuid=${context.uuid}&page_size=${pageSize}`
      ),
      get(`/schedule/seller/action_required/?profile_uuid=${context.uuid}`),
      get(`/schedule/seller/open_issues/?profile_uuid=${context.uuid}`),
      get(`/schedule/seller/waiting_for_buyer/?profile_uuid=${context.uuid}`),
      get(`/schedule/seller/upcoming/?profile_uuid=${context.uuid}`),
      get(
        `/schedule/seller/complete/?profile_uuid=${context.uuid}&page_size=${pageSize}`
      ),
      get(
        `/schedule/seller/cancelled/?profile_uuid=${context.uuid}&page_size=${pageSize}`
      ),
    ])
      .then(
        ([
          buyerActionRequiredResponse,
          buyerOpenIssuesResponse,
          buyerWaitingForSellerResposne,
          buyerUpcomingResponse,
          buyerCompleteResponse,
          buyerCancelledResponse,
          sellerActionRequiredResponse,
          sellerOpenIssuesResponse,
          sellerWaitingForBuyerResponse,
          sellerUpcomingResponse,
          sellerCompletedResponse,
          sellerCancelledResponse,
        ]) => {
          setLoadData(true)
          setScheduledPromos({
            selling: {
              openIssues: parsePromoRequestList(
                ArgJSONMap.fromParsedJson(
                  sellerOpenIssuesResponse.data
                ).getList("results"),
                ScheduleRole.Selling,
                context.profileName
              ),
              actionRequired: parsePromoRequestList(
                ArgJSONMap.fromParsedJson(
                  sellerActionRequiredResponse.data
                ).getList("results"),
                ScheduleRole.Selling,
                context.profileName
              ),
              upcoming: parsePromoRequestList(
                ArgJSONMap.fromParsedJson(sellerUpcomingResponse.data).getList(
                  "results"
                ),
                ScheduleRole.Selling,
                context.profileName
              ),
              complete: parsePromoRequestList(
                ArgJSONMap.fromParsedJson(sellerCompletedResponse.data).getList(
                  "results"
                ),
                ScheduleRole.Selling,
                context.profileName
              ),
              waitingForBuyer: parsePromoRequestList(
                ArgJSONMap.fromParsedJson(
                  sellerWaitingForBuyerResponse.data
                ).getList("results"),
                ScheduleRole.Selling,
                context.profileName
              ),
              cancelled: parsePromoRequestList(
                ArgJSONMap.fromParsedJson(sellerCancelledResponse.data).getList(
                  "results"
                ),
                ScheduleRole.Selling,
                context.profileName
              ),
            },
            buying: {
              openIssues: parsePromoRequestList(
                ArgJSONMap.fromParsedJson(buyerOpenIssuesResponse.data).getList(
                  "results"
                ),
                ScheduleRole.Buying,
                context.profileName
              ),
              actionRequired: parsePromoRequestList(
                ArgJSONMap.fromParsedJson(
                  buyerActionRequiredResponse.data
                ).getList("results"),
                ScheduleRole.Buying,
                context.profileName
              ),
              upcoming: parsePromoRequestList(
                ArgJSONMap.fromParsedJson(buyerUpcomingResponse.data).getList(
                  "results"
                ),
                ScheduleRole.Buying,
                context.profileName
              ),
              complete: parsePromoRequestList(
                ArgJSONMap.fromParsedJson(buyerCompleteResponse.data).getList(
                  "results"
                ),
                ScheduleRole.Buying,
                context.profileName
              ),
              waitingForSeller: parsePromoRequestList(
                ArgJSONMap.fromParsedJson(
                  buyerWaitingForSellerResposne.data
                ).getList("results"),
                ScheduleRole.Buying,
                context.profileName
              ),
              cancelled: parsePromoRequestList(
                ArgJSONMap.fromParsedJson(buyerCancelledResponse.data).getList(
                  "results"
                ),
                ScheduleRole.Buying,
                context.profileName
              ),
            },
          })
          if (!hasInitialData) {
            setHasInitialData(true)
          } else {
            setShowLoadingMore(false)
          }
        }
      )
      .catch((error) => {
        setLoadData(false)
        console.error(error)
      })
  }, [context.uuid, hasInitialData])

  useEffect(() => {
    if (scheduledPromos.selling && scheduledPromos.buying) {
      const buyerActions = scheduledPromos.buying.actionRequired
      const sellerActions = scheduledPromos.selling.actionRequired

      if (buyerActions || sellerActions) {
        siteActionRequiredDispatch({
          type: ActionRequiredActionTypes.LoadNotificationActionRequiredCount,
          payload: {
            actionRequiredNotifications:
              (buyerActions ? buyerActions.length : 0) +
              (sellerActions ? sellerActions.length : 0),
          },
        })
      }
    }
  }, [scheduledPromos])

  return (
    <StyledScrollHeader>
      <div className="header">
        <Typography variant="h6">My Orders</Typography>
        {/* post-MVP <CalendarTodayOutlinedIcon className={"icon"}/>*/}
      </div>
      {loadData ? (
        <ScheduleTabs
          promos={scheduledPromos}
          openMessages={(profileName: string) => {
            if (isMobile) {
              router.push(`/messages/${profileName}`)
            } else {
              chatDispatch({
                type: ChatActionTypes.ChatWithUser,
                payload: profileName,
              })
            }
          }}
          openOrderStatus={(promoRequestUuid: string) => {
            router.push(
              `/order-status/${promoRequestUuid}?profile=${context.uuid}`
            )
          }}
          openPromoOrderPage={(
            promoUuid: string,
            promoDate: Date,
            promoTierUuid: string,
            platform: string
          ) => {
            router.push(
              `/promo/${promoUuid}/?requestedDate=${promoDate.toISOString()}&tier=${promoTierUuid}&platform=${platform}&profileName=${
                context.profileName
              }&profileUuid=${context.uuid}`
            )
          }}
          showLoadingMore={showLoadingMore}
        />
      ) : (
        <StyledScheduleSpinner>
          <LoadingSpinner size={224} />
        </StyledScheduleSpinner>
      )}
    </StyledScrollHeader>
  )
}

SchedulePage.getLayout = (page: ReactElement) => <Layout>{page}</Layout>
export default SchedulePage

const StyledScrollHeader = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;

  .header {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;

    .icon {
      padding: 0;
    }
  }
`

const StyledScheduleSpinner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
