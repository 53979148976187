import styled from "@emotion/styled"
import { Colors } from "../../styles/Colors"
import { Badge } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import { PrimaryColorAccountCircle } from "./Icons/PrimaryColorIcons"
import { BlossmImage } from "./BlossmImage"

export interface IAvatarProps {
  online: boolean
  profileImage?: string
  size?: "Tiny" | "Small" | "Medium" | "Large" | "Extra Large" | "Fill" | string
  defaultAvatarScale?: number
  greyedOut?: boolean
  onClick?: () => void
  borderOnline?: boolean
}

export const AvatarAnimations = {
  grow: "grow-animation",
}

function sizeToPx(size: string): string {
  switch (size) {
    case "Tiny":
      return "16px"
    case "Small":
      return "24px"
    case "Medium":
      return "35px"
    case "Large":
      return "50px"
    case "Extra Large":
      return "120px"
    case "Extra Extra Large":
      return "224px"
    case "Fill":
      return "100%"
    default:
      return size + "px"
  }
}

function sizeToPxImage(size: string): number {
  switch (size) {
    case "Tiny":
      return 16
    case "Small":
      return 24
    case "Medium":
      return 35
    case "Large":
      return 50
    case "Extra Large":
      return 120
    case "Extra Extra Large":
      return 224
    default:
      return size as unknown as number
  }
}

// eslint-disable-next-line complexity
export const Avatar = ({
  online,
  profileImage,
  size = "Large",
  defaultAvatarScale = 1,
  greyedOut = false,
  onClick,
  borderOnline = false,
}: IAvatarProps): JSX.Element => {
  const useStyles = makeStyles(() => ({
    badge: {
      fontSize: 30,
      height: 10,
      width: 10,
    },
  }))

  const classes = useStyles()

  const badgeBorder =
    size === "Extra Large"
      ? "8px"
      : size === "Large"
      ? "6px"
      : size === "Medium"
      ? "4px"
      : size === "Small"
      ? "2px"
      : "0px"

  const isDefaultAvatar = profileImage?.match(/\/images\/avatar_[0-9]\.png/g)
  const ImageComponent =
    !profileImage || isDefaultAvatar ? (
      <PrimaryColorAccountCircle
        sx={{
          opacity: greyedOut ? "0.4" : "1",
          height: sizeToPx(size),
          width: sizeToPx(size),
          // Don't include transform property if scale is 1, because scaling 1 can cause blurriness
          ...(defaultAvatarScale !== 1 && {
            transform: `scale(${defaultAvatarScale}, ${defaultAvatarScale})`,
          }),
        }}
      />
    ) : (
      <BlossmImage
        alt={"profile image"}
        greyedOut={greyedOut}
        src={profileImage}
        width={sizeToPxImage(size)}
        height={sizeToPxImage(size)}
        borderRadius={size === "Extra Large" || size === "large" ? 16 : 8}
      />
    )

  return (
    <StyledAvatar
      size={size}
      onClick={onClick}
      borderRadius={size === "Extra Large" || size === "large" ? 16 : 8}
      borderOnline={borderOnline}
      online={online}
    >
      <div className={"avatar-body-container"}>
        {online && !borderOnline ? (
          <Badge
            sx={{
              "& .MuiBadge-badge": {
                color: Colors.greens.online,
                backgroundColor: Colors.greens.online,
                borderRadius: "50%",
                border: `${badgeBorder} solid ${Colors.greens.online}`,
              },
            }}
            classes={{ badge: classes.badge }}
            style={{ opacity: greyedOut ? "0.4" : "1" }}
            variant={"dot"}
            overlap={"circular"}
            badgeContent=" "
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            {ImageComponent}
          </Badge>
        ) : (
          <>{ImageComponent}</>
        )}
      </div>
    </StyledAvatar>
  )
}

const StyledAvatar = styled.div<{
  size: string
  borderRadius: number
  borderOnline: boolean
  online: boolean
}>`
  position: relative;
  display: flex;
  height: fit-content;
  width: fit-content;
  // background: ${Colors.white};
  min-width: ${(props) => sizeToPx(props.size)};
  .avatar-body-container {
    position: relative;
    display: flex;
    height: fit-content;
    width: fit-content;
    // background: ${Colors.white};
    border-radius: ${(props) =>
      props.size === "Extra Large" || props.size === "Large" ? 16 : 8};
    min-width: ${(props) => sizeToPx(props.size)};

    .avatar-body-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: min-width 1.5s, max-width 1.5s, min-height 1.5s,
            max-height 1.5s, margin 1.5s;

        .MuiBadge-root {
            vertical-align: baseline;
        }
    }

    .grow-animation {
        min-width: 220px;
        max-width: 220px;
        min-height: 220px;
        max-height: 220px;
    }

    .avatar-disabled-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: ${Colors.grays.light};
        z-index: 99;
        border-radius: ${(props) =>
          props.size === "Extra Large" || props.size === "Large" ? 16 : 8};
    }

  .profile {
    border-radius: ${(props) =>
      props.size === "Extra Large" || props.size === "Large" ? 16 : 8};
    border: ${(props) =>
      props.borderOnline &&
      props.online &&
      `1px solid ${Colors.greens.online} !important`};
  }
`
