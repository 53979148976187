import { Box, Typography } from "@mui/material"
import { useTheme } from "@mui/material"
import React, { ReactNode, useCallback, useMemo, useState } from "react"
import { Accept, FileError, FileRejection, useDropzone } from "react-dropzone"

type IDropZone = {
  setMedia: (media: File[]) => void
  accept: Accept
  children: ReactNode
  useDefaultBehavior?: boolean
  maxFiles?: number
}

export const DropZone: React.FC<IDropZone> = ({
  setMedia,
  accept,
  children,
  maxFiles = 1,
  useDefaultBehavior = true,
}): React.ReactElement => {
  const theme = useTheme()
  const [errors, setErrors] = useState<React.ReactElement[]>([])

  const fileSizeValidator = (file: File): FileError | null => {
    if (file.size > 2e8) {
      return {
        code: "size-too-larger",
        message: "File size is larger than 200MB.",
      }
    }

    return null
  }

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length) {
        setErrors(
          fileRejections[0].errors.map((e: FileError) => {
            return (
              <Typography
                key={e.code}
                variant={"body2"}
                sx={{
                  color: theme.palette.error.main,
                }}
              >
                {e.message}
              </Typography>
            )
          })
        )
      }

      if (fileRejections.length === 0 && acceptedFiles.length) {
        setMedia(acceptedFiles)
      }
    },
    []
  )
  const baseStyle = {
    padding: "20px",
    borderWidth: 1,
    borderRadius: 1,
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    outline: "none",
    transition: "border .24s ease-in-out",
    width: "100%",
  }

  const acceptStyle = {
    borderColor: theme.palette.action.active,
    backgroundColor: theme.palette.background.paper,
  }

  const rejectStyle = {
    borderColor: theme.palette.error.main,
  }

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept: accept,
      maxFiles: maxFiles,
      validator: fileSizeValidator,
      noClick: !useDefaultBehavior,
    })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragAccept, isDragReject]
  )

  return (
    <Box
      {...getRootProps({ style })}
      sx={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box>
        {useDefaultBehavior && <input {...getInputProps()} />}
        {children}
      </Box>

      {errors.length > 0 && (
        <aside style={{ textAlign: "center" }}>{errors}</aside>
      )}
    </Box>
  )
}
