import Head from "next/head"

export interface ISEOProps {
  title?: string
  description?: string
  ogImageUrl?: string
  ogImageType?: string
  ogImageSize?: string
}

export const SEO = ({
  title = "Blossm - Promote yourself.",
  description = "The promo marketplace for creators.",
  ogImageUrl = "https://blossm.com/assets/BlossmIcon.png",
  ogImageType = "image/png",
  ogImageSize = "720",
}: ISEOProps): JSX.Element => {
  return (
    <>
      <Head>
        <title key="title">{title}</title>
        <meta key="description" name="description" content={description} />
        <meta key="og-title" property="og:title" content={title} />
        <meta
          key="og-description"
          property="og:description"
          content={description}
        />
        <meta key="og-type" property="og:type" content="website" />
        <meta key="og-image" property="og:image" content={ogImageUrl} />
        <meta
          key="og-image-type"
          property="og:image:type"
          content={ogImageType}
        />
        <meta
          key="og-image-width"
          property="og:image:width"
          content={ogImageSize}
        />
        <meta
          key="og-image-height"
          property="og:image:height"
          content={ogImageSize}
        />
        <meta key="twitter-card" name="twitter:card" content="summary" />
        <meta key="twitter-site" name="twitter:site" content="@blossmsocial" />
        <meta key="keywords" name="keywords" content={keywords.join(", ")} />
        <meta name="rating" content="adult"></meta>
      </Head>
    </>
  )
}

const keywords: string[] = [
  "blossm promos",
  "blossm promoter",
  "blossm promoters",
  "blossm adult",
  "blossm adult promos",
  "blossm peer to peer marketing",
  "blossm adult creator",
  "blossm content creator",
  "blossm adult influencer",
  "blossm influencer",
  "blossm model",
  "blossm onlyfans",
  "blossm fansly",
  "blossm manyvids",
  "blossm cam model",
  "content creator promotion",
  "blossm reddit",
  "blossm telegram",
  "blossm instagram",
  "blossm youtube",
  "content creator",
  "adult content creator",
  "what is an adult content creator",
  "how to become an adult content creator",
  "freelance content creator",
  "influencer",
  "nude influencer",
  "influencer nude",
  "influencer viral",
  "onlyfans",
  "onlyfans marketing agency",
  "onlyfans marketing",
  "onlyfans marketing strategy",
  "best onlyfans marketing agency",
  "adult modeling",
  "nude adult models",
  "adult model",
  "adult nude models",
  "amateur nude modeling",
  "nude amateur models",
]
